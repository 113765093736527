import React, { useState, useEffect } from 'react';
import useAxios from 'axios-hooks';
import Modal from '../../Modal/Modal';
import { css } from '@emotion/core';
import { useTranslation } from 'react-i18next';
import Spinner from '../../Spinner/index';
import CardHeader from '../../CardHeader';
import { AlertService } from '../../../services/AlertService';
import Checkbox from '../../Checkbox/index';

import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  ListItemText
} from '@mui/material';
import { Checkbox as CheckedBox } from '@mui/material';

const EditGroupModal = ({ display, onClose, roleId, role = null, reFetch }) => {
  const { t } = useTranslation();
  const [name, setName] = useState('');
  const [allSchoolsEnabled, setAllSchoolsEnabled] = useState(true);
  const [options, setOptions] = useState({
    STUDENTS: false,
    'STUDENTS:PROFILE': false,
    'STUDENTS:LOCK_FIELDS': false,
    STUDENTS_SUBMISSIONS: false,
    'STUDENTS_SUBMISSIONS:MANUAL_APPROVAL': false,
    'STUDENTS_SUBMISSIONS:MANAGE_WAIVER_SUPPLEMENTS': false,
    'STUDENTS_SUBMISSIONS:ADD_SHIP_ENROLLMENT': false,
    'STUDENTS_SUBMISSIONS:MANUAL_BILLING': false,
    'STUDENTS_SUBMISSIONS:COVERAGE_DATES': false,
    'STUDENTS_SUBMISSIONS:ADD_NOTES': false,
    SCHOOLS: false,
    'SCHOOLS:PROFILE': false,
    'SCHOOLS:PERIODS': false,
    'SCHOOLS:SETTINGS': false,
    REPORTS: false
  });

  const [permissionsActive, setPermissionsActive] = useState(true);
  const [schoolName, setSchoolName] = useState([]);
  const [selectOptions, setSelectOptions] = useState([]);
  const [selectedSchool, setSelectedSchool] = useState([]);
  const [count, setCount] = useState(0);

  const [{ data: createData, error: errorData }, createGroup] = useAxios(
    {
      url: `${process.env.REACT_APP_BASE_URL}api/v1/admin/roles`,
      method: 'POST',
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('jwt')}`
      }
    },
    {
      manual: true
    }
  );
  const [{ data: updateData, error: updateError }, updateRole] = useAxios(
    {
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('jwt')}`
      }
    },
    {
      manual: true
    }
  );

  const [{ data: schoolData, loading: schoolLoading }] = useAxios({
    url: `${process.env.REACT_APP_BASE_URL}api/v1/schools/basic`,
    method: 'GET',
    headers: {
      Authorization: `Bearer ${window.localStorage.getItem('jwt')}`
    }
  });

  const [{ data: groupData, loading: groupLoading }] = useAxios(
    {
      url: `${process.env.REACT_APP_BASE_URL}api/v1/admin/roles/${roleId}`,
      method: 'GET',
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('jwt')}`
      }
    },
    { useCache: false }
  );

  const [, deleteRole] = useAxios(
    {
      url: `${process.env.REACT_APP_BASE_URL}api/v1/admin/roles/${roleId}`,
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('jwt')}`
      }
    },
    {
      manual: true
    }
  );

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250
      }
    }
  };

  const handleChange = (event) => {
    const {
      target: { value }
    } = event;
    if (value != 'on') {
      setSchoolName(
        // On autofill we get a stringified value.
        value
      );
      setSelectedSchool(value);
    }
  };

  const handleSelectedChange = (event) => {
    const {
      target: { value }
    } = event;
    if (value != 'on') {
      if (!event.target.checked) {
        setSchoolName(schoolName.filter((x) => x.value !== value));
        setSelectedSchool(selectedSchool.filter((x) => x.value !== value));
      } else {
        setSchoolName(
          schoolName,
          ...schoolName.filter((x) => x.value === value)
        );
        setSelectedSchool(
          selectedSchool,
          ...selectOptions.filter((x) => x.value === value)
        );
      }
    }
  };

  const handleChangeOption = (option) => {
    setOptions((opt) => ({
      ...opt,
      [option]: !opt[option]
    }));
    console.log(options);
  };

  const handleUpdate = (e) => {
    e.preventDefault();
    updateRole({
      url: `${process.env.REACT_APP_BASE_URL}api/v1/admin/roles/${role.id}`,
      data: {
        name: name,
        permissions: options,
        all_schools: allSchoolsEnabled,
        schools: allSchoolsEnabled ? [] : selectedSchool.map((s) => s.value)
      }
    });
  };

  const handleAdd = (e) => {
    e.preventDefault();
    createGroup({
      data: {
        name: name,
        permissions: options,
        all_schools: allSchoolsEnabled,
        schools: allSchoolsEnabled ? [] : selectedSchool.map((s) => s.value)
      }
    });
  };

  const handleDelete = (e) => {
    e.preventDefault();
    deleteRole().then(() => {
      onClose();
    });
  };

  useEffect(() => {
    if ((createData && !errorData) || (updateData && !updateError)) {
      reFetch();
      onClose();
      AlertService.showSuccess(t('SAVE_SUCCESS'));
    } else if (
      errorData &&
      errorData.response &&
      errorData.response.data &&
      errorData.response.data.message
    ) {
      AlertService.showError(errorData.response.data.message);
    }
    if (
      updateError &&
      updateError.response &&
      updateError.response.data &&
      updateError.response.data.message
    ) {
      AlertService.showError(updateError.response.data.message);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createData, errorData, updateData, updateError]);

  useEffect(() => {
    if (schoolData) {
      let schoolOptions = [];
      schoolData.forEach((school) =>
        schoolOptions.push({ value: school.id, label: school.school_name })
      );
      setSelectOptions(schoolOptions);
      setCount((c) => c + 1);
    }
  }, [schoolData]);

  useEffect(() => {
    if (groupData) {
      setName(groupData.name);
      setOptions({
        ...groupData.permissions
      });
      setAllSchoolsEnabled(groupData.all_schools);
      setSelectedSchool(
        selectOptions.filter((s) => groupData.schools.indexOf(s.value) !== -1)
      );
      setSchoolName(
        selectOptions.filter((s) => groupData.schools.indexOf(s.value) !== -1)
      );
    }
  }, [groupData, selectOptions]);

  const renderPermissionsCheckRow = () => (
    <>
      <form className="form-type-material">
        <div style={{ height: 400 }}>
          <div className="h-100 overflow-auto">
            <div className="col bg-light py-2">
              {/* STUDENTS */}
              {renderCheckRow(
                t('MANAGE_STUDENTS'),
                t('MANAGE_STUDENTS_DESCRIPTION'),
                'STUDENTS'
              )}
              <div className="pl-25">
                {renderCheckRow(
                  t('MANAGE_PROFILES'),
                  t('MANAGE_STUDENTS_PROFILES_DESCRIPTION'),
                  'STUDENTS:PROFILE'
                )}
                {renderCheckRow(
                  t('MANAGE_LOCK_FIELDS'),
                  t('MANAGE_LOCK_FIELDS_DESCRIPTION'),
                  'STUDENTS:LOCK_FIELDS'
                )}
              </div>

              {/*STUDENTSSUBMISSION  */}
              {renderCheckRow(
                t('MANAGE_STUDENTS_SUBMISSION'),
                t('MANAGE_STUDENTS_SUBMISSION_DESCRIPTION'),
                'STUDENTS_SUBMISSIONS'
              )}
              <div className="pl-25">
                {renderCheckRow(
                  t('MANAGE_MANUAL_APPROVAL'),
                  t('MANAGE_MANUAL_APPROVAL_DESCRIPTION'),
                  'STUDENTS_SUBMISSIONS:MANUAL_APPROVAL'
                )}
                {renderCheckRow(
                  t('MANAGE_ADD_REMOVE_SUPPLEMTENT'),
                  t('MANAGE_ADD_REMOVE_SUPPLEMTENT_DESCRIPTION'),
                  'STUDENTS_SUBMISSIONS:MANAGE_WAIVER_SUPPLEMENTS'
                )}
                {renderCheckRow(
                  t('MANAGE_ADD_SHIP'),
                  t('MANAGE_ADD_SHIP_DESCRIPTION'),
                  'STUDENTS_SUBMISSIONS:ADD_SHIP_ENROLLMENT'
                )}
                {renderCheckRow(
                  t('MANAGE_COVERAGE_DATES'),
                  t('MANAGE_COVERAGE_DATES_DESCRIPTION'),
                  'STUDENTS_SUBMISSIONS:COVERAGE_DATES'
                )}
                {renderCheckRow(
                  t('MANAGE_NOTES'),
                  t('MANAGE_NOTES_DESCRIPTION'),
                  'STUDENTS_SUBMISSIONS:ADD_NOTES'
                )}
                {renderCheckRow(
                  t('MANAGE_MANUAL_BILLING'),
                  t('MANAGE_MANUAL_BILLING_DESCRIPTION'),
                  'STUDENTS_SUBMISSIONS:MANUAL_BILLING'
                )}
              </div>

              {/* SCHOOLS */}
              {renderCheckRow(
                t('MANAGE_SCHOOLS'),
                t('MANAGE_SCHOOLS_DESCRIPTION'),
                'SCHOOLS'
              )}
              <div className="pl-25">
                {renderCheckRow(
                  t('MANAGE_PROFILE'),
                  t('MANAGE_PROFILE_DESCRIPTION'),
                  'SCHOOLS:PROFILE'
                )}
                {renderCheckRow(
                  t('MANAGE_ENROLLMENTS_PERIODS'),
                  t('MANAGE_ENROLLMENTS_PERIODS_DESCRIPTION'),
                  'SCHOOLS:PERIODS'
                )}
                {renderCheckRow(
                  t('MANAGE_SETTINGS'),
                  t('MANAGE_SETTINGS_DESCRIPTION'),
                  'SCHOOLS:SETTINGS'
                )}
              </div>
              {/* REPORTS */}
              {renderCheckRow(
                t('ACCESS_REPORT'),
                t('ACCESS_REPORT_DESCRIPTION'),
                'REPORTS'
              )}
            </div>
          </div>
        </div>
        <div className="d-flex mt-4">
          {role && (
            <button
              onClick={handleDelete}
              className="btn border-0 text-fadest fs-22 p-0"
            >
              <i className="fa fa-trash"></i>
            </button>
          )}
          <button
            css={css`
              grid-column: 2/3;
            `}
            className="btn btn-bold d-block ml-auto btn-primary btn-label"
            type="submit"
            onClick={role ? handleUpdate : handleAdd}
          >
            <span>
              <i className="ti-check"></i>
            </span>
            {role ? t('UPDATE') : t('ADD')}
          </button>
        </div>
      </form>
    </>
  );

  const renderSchoolTab = () => (
    <>
      <div className="row m-2" style={{ height: 400 }}>
        <div className="col bg-light py-2">
          {/* Display two columns, one with a text, the other one with radio button */}
          <div className="row mb-1">
            <div className="w-20 px-3">
              <span className="font-weight-bold fs-13 m-0">
                {t('SELECT_SCHOOLS_PERMISSION_GROUP')}
              </span>
            </div>
            <div className="col pl-0">
              <div className="form-group">
                <label className="switch">
                  <input
                    type="checkbox"
                    checked={allSchoolsEnabled}
                    name="allSchoolsEnabled"
                    id="allSchoolsEnabled"
                    onChange={() => {
                      setAllSchoolsEnabled((c) => !c);
                    }}
                  />
                  <span className="switch-indicator"></span>
                  <span className="switch-description">{t('ALL_SCHOOLS')}</span>
                </label>
              </div>
            </div>
          </div>
          {schoolLoading ? (
            <Spinner />
          ) : (
            <div className="row mb-2" key={count}>
              {allSchoolsEnabled == false && (
                <div className="column">
                  <FormControl variant="standard" sx={{ m: 1, width: 300 }}>
                    <InputLabel id="schools-label">
                      {t('SCHOOL_NAME')}
                    </InputLabel>
                    <Select
                      labelId="schoolNamesLabel"
                      id="schoolNames"
                      multiple
                      value={schoolName}
                      onChange={handleChange}
                      renderValue={(selected) => {
                        selected.join(', ');
                      }}
                      MenuProps={MenuProps}
                    >
                      {selectOptions.map((s) => (
                        <MenuItem key={s.key} value={s}>
                          <CheckedBox
                            value={s.value}
                            checked={
                              schoolName
                                .map((sc) => sc.value)
                                .indexOf(s.value) > -1
                            }
                          />
                          <ListItemText
                            primary={s.label}
                            style={{ marginLeft: 5 }}
                          />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <div>
                    {selectedSchool && (
                      <>
                        {selectedSchool.map((s) => (
                          <div>
                            <FormControl
                              variant="standard"
                              sx={{ m: 1, width: 300 }}
                            >
                              <MenuItem
                                key={s.value}
                                value={s.value}
                                onChange={handleSelectedChange}
                              >
                                <CheckedBox
                                  value={s.value}
                                  checked={
                                    schoolName
                                      .map((x) => x.value)
                                      .indexOf(s.value) > -1
                                  }
                                />
                                <ListItemText
                                  primary={s.label}
                                  style={{ marginLeft: 5 }}
                                />
                              </MenuItem>
                            </FormControl>
                          </div>
                        ))}
                      </>
                    )}
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      <div className="d-flex mt-4">
        {role && (
          <button
            onClick={handleDelete}
            className="btn border-0 text-fadest fs-22 p-0"
          >
            <i className="fa fa-trash"></i>
          </button>
        )}
        <button
          css={css`
            grid-column: 2/3;
          `}
          className="btn btn-bold d-block ml-auto btn-primary btn-label"
          type="submit"
          onClick={role ? handleUpdate : handleAdd}
        >
          <span>
            <i className="ti-check"></i>
          </span>
          {role ? t('UPDATE') : t('ADD')}
        </button>
      </div>
    </>
  );

  const renderCheckRow = (title, description, id) => (
    <div className="row mb-1">
      <div className="w-20 px-3">
        <Checkbox
          checked={options[id]}
          onCheck={() => handleChangeOption(id)}
          labelStyle="fw-300"
        />
      </div>
      <div className="col pl-0">
        <span className="font-weight-bold fs-13 m-0">{title}</span>
        <br />
        <span className="fs-12 m-0">{description}</span>
      </div>
    </div>
  );

  return (
    <Modal
      inputCSS={css`
        .w-850px {
          width: 850px;
        }
        .h-80 {
          height: 80%;
        }
      `}
      width="w-850px"
      display={display}
      setDisplay={onClose}
      height="h-80"
    >
      <CardHeader
        title={
          role
            ? `${role.name} ${t('PERMISSIONS')}`
            : t('CREATE_NEW_PERMISSION_GROUP')
        }
        rightComponent={
          <button onClick={() => onClose()} className="btn border-0 p-0 fs-18">
            <i className="fa fa-close"></i>
          </button>
        }
      />
      <div className="p-20">
        <div className="container">
          <div className="row">
            <div className={`form-group ${name ? 'do-float' : ''} col`}>
              <label htmlFor="groupName" className="require">
                {t('GROUP_NAME')}
              </label>
              <input
                type="text"
                className="form-control"
                name="groupName"
                id="groupName"
                onChange={(val) => setName(val.target.value)}
                value={name}
              />
            </div>
            <div className="col">
              <ul className="nav nav-tabs">
                <li className="nav-item">
                  <span
                    onClick={() => setPermissionsActive(true)}
                    className="nav-link cursor-pointer active"
                    data-toggle="tab"
                    tole="button"
                    href="#permissions-tab"
                  >
                    {t('PERMISSIONS')}
                  </span>
                </li>
                <li className="nav-item">
                  <span
                    onClick={() => setPermissionsActive(false)}
                    className="nav-link cursor-pointer"
                    data-toggle="tab"
                    href="#schools-tab"
                    role="button"
                  >
                    {t('SCHOOLS')}
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="tab-content">
          {false ? (
            <Spinner />
          ) : (
            <>
              <div className="tab-pane fade active show" id="permissions-tab">
                {renderPermissionsCheckRow()}
              </div>
              <div className="tab-pane fade" id="schools-tab">
                {renderSchoolTab()}
              </div>
            </>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default EditGroupModal;
