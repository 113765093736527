import React, { useMemo } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { GuardProvider, GuardedRoute } from 'react-router-guards';
import NotFound from '../views/NotFound';
import requireLogin from './guards/requireLogin';
import requireUserType from './guards/requireUserType';
import getRoutes from './routes';
import Preloader from '../components/Preloader';
// import { UserService } from '../services/UserService';

const GLOBAL_GUARDS = [requireLogin];

const Router = () => {
  const routes = useMemo(() => getRoutes(), []);

  return (
    <BrowserRouter>
      <GuardProvider
        guards={GLOBAL_GUARDS}
        loading={Preloader}
        error={NotFound}
      >
        <Route
          render={() => (
            <Switch>
              {routes.map(
                (
                  {
                    component,
                    error,
                    exact,
                    ignoreGlobal,
                    loading,
                    meta,
                    path
                  },
                  i
                ) => (
                  <GuardedRoute
                    key={i}
                    component={component}
                    exact={exact}
                    error={error}
                    ignoreGlobal={ignoreGlobal}
                    loading={loading}
                    meta={meta}
                    path={path}
                    guards={
                      meta?.requiredUserType ? [requireUserType] : undefined
                    }
                  />
                )
              )}
            </Switch>
          )}
        />
      </GuardProvider>
    </BrowserRouter>
  );
};

export default Router;
