import React, { useState, useMemo, useEffect, useCallback } from 'react';
import Modal from '../Modal/Modal';
import useAxios from 'axios-hooks';
import { css } from '@emotion/core';
import { useTranslation } from 'react-i18next';
import CardHeader from '../CardHeader/index';
import { AlertService } from '../../services/AlertService';
import Preloader from '../Preloader/index';
import General from './General';
import Rules from './Rules';

const EditProviderModal = ({ display, setDisplay, provider, onCloseModal }) => {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState('#general');
  const [formData, setFormData] = useState({
    name: '',
    overridePayerId: '',
    overrideNpi: '',
    isActive: true,
    defaultPr: false,
    noValidation: false,
    patternChangeRule: {
      rule: '',
      type: '',
      pattern: ''
    }
  });
  const [{ loading, error, response }, executeAdd] = useAxios(
    {
      url: `${process.env.REACT_APP_BASE_URL}api/v1/admin/providers`,
      method: 'POST',
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('jwt')}`
      }
    },
    { manual: true }
  );
  const [
    { loading: updateLoading, error: updateError, response: updateResponse },
    executeUpdate
  ] = useAxios(
    {
      url: `${process.env.REACT_APP_BASE_URL}api/v1/admin/providers/${
        provider && provider.id
      }`,
      method: 'POST',
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('jwt')}`
      }
    },
    { manual: true }
  );

  useEffect(() => {
    if (display && provider) {
      setFormData({
        id: provider.id,
        name: provider.name,
        overridePayerId: provider.override_payer_id,
        overrideNpi: provider.override_npi,
        isActive: provider.is_active,
        defaultPr: provider.default_pr,
        noValidation: provider.do_not_allow_validation,
        patternChangeRule: provider.pattern_change_rule || {
          rule: '',
          type: '',
          pattern: ''
        }
      });
    }
    if (!display) {
      setFormData({
        name: '',
        overridePayerId: '',
        overrideNpi: '',
        isActive: true,
        defaultPr: false,
        noValidation: false,
        patternChangeRule: {
          rule: '',
          type: '',
          pattern: ''
        }
      });
    }
  }, [display, provider]);

  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault();
      const params = {
        ...formData,
        isActive: formData.isActive ? 1 : 0,
        defaultPr: formData.defaultPr ? 1 : 0,
        noValidation: formData.noValidation ? 1 : 0,
        patternChangeRule:
          formData.patternChangeRule.rule !== ''
            ? formData.patternChangeRule
            : null
      };

      if (provider) {
        executeUpdate({ params });
      } else {
        executeAdd({ params });
      }
    },
    [provider, formData, executeUpdate, executeAdd]
  );

  const handleInputChange = useCallback((key, value) => {
    setFormData((f) => ({
      ...f,
      [key]: value
    }));
  }, []);

  const handleActiveChange = useCallback(() => {
    setFormData((f) => ({
      ...f,
      isActive: !f.isActive
    }));
  }, []);

  const handleDefaultChange = useCallback(() => {
    setFormData((f) => ({
      ...f,
      defaultPr: !f.defaultPr
    }));
  }, []);

  const handleNoValidationChange = useCallback(() => {
    setFormData((f) => ({
      ...f,
      noValidation: !f.noValidation
    }));
  }, []);

  const handleChangeRule = useCallback((key, value) => {
    if (key === 'rule' && value === 'none') {
      setFormData((f) => ({
        ...f,
        patternChangeRule: {
          rule: '',
          type: '',
          pattern: ''
        }
      }));
    } else {
      setFormData((f) => ({
        ...f,
        patternChangeRule: {
          ...f.patternChangeRule,
          [key]: value
        }
      }));
    }
  }, []);

  useEffect(() => {
    if (response || updateResponse) {
      AlertService.showSuccess(t('SAVE_SUCCESS'));
      onCloseModal(formData);
    }
  }, [response, updateResponse, error, updateError, formData, onCloseModal, t]);

  const isFormValid = useMemo(
    () =>
      formData.name !== '' &&
      ((formData.overridePayerId !== '' && !provider) ||
        (formData.noValidation &&
          formData.overridePayerId === '' &&
          !provider) ||
        (provider &&
          provider.payer_id === 'EWAIVE' &&
          formData.overridePayerId !== '') ||
        (provider && provider.payer_id !== 'EWAIVE')) &&
      (formData.patternChangeRule.rule === '' ||
        (formData.patternChangeRule.rule !== '' &&
          formData.patternChangeRule.type !== '' &&
          formData.patternChangeRule.pattern !== '')),
    [formData, provider]
  );

  return (
    <Modal
      targetId="add-edit-provider"
      width="w-350px"
      display={display}
      setDisplay={setDisplay}
    >
      {(loading || updateLoading) && <Preloader />}
      <CardHeader
        title={
          provider ? t('EDIT_INSURANCE_PROVIDER') : t('ADD_INSURANCE_PROVIDER')
        }
        rightComponent={
          <button
            onClick={() => setDisplay(false)}
            className="btn border-0 p-0"
          >
            <i className="fa fa-close"></i>
          </button>
        }
      />
      <div className="card-body">
        {provider && (
          <ul className="nav nav-tabs mb-0 border-0">
            <li className="nav-item">
              <span
                onClick={(e) => setActiveTab(e.target.getAttribute('href'))}
                className="nav-link cursor-pointer active"
                data-toggle="tab"
                tole="button"
                href="#general"
              >
                {t('GENERAL')}
              </span>
            </li>
            <li className="nav-item">
              <span
                onClick={(e) => setActiveTab(e.target.getAttribute('href'))}
                className="nav-link cursor-pointer"
                data-toggle="tab"
                href="#rules"
                role="button"
              >
                {t('RULES')}
              </span>
            </li>
          </ul>
        )}
        <div className="tab-content">
          <div
            css={css`
              height: 320px;
              border: solid 1px rgba(151, 151, 151, 0.25);
              padding-top: 32px;
            `}
          >
            <div className="tab-pane fade active show" id="general">
              {activeTab === '#general' && (
                <General
                  formData={formData}
                  provider={provider}
                  handleInputChange={handleInputChange}
                  handleActiveChange={handleActiveChange}
                  handleDefaultChange={handleDefaultChange}
                  handleNoValidationChange={handleNoValidationChange}
                />
              )}
            </div>
            <div className="tab-pane fade active show" id="rules">
              {activeTab === '#rules' && (
                <Rules formData={formData} changeRule={handleChangeRule} />
              )}
            </div>
          </div>
        </div>
        <br />
        <button
          onClick={handleSubmit}
          disabled={!isFormValid}
          className={`btn btn-bold w-auto ml-auto btn-block btn-primary btn-label ${
            isFormValid ? '' : 'disabled'
          }`}
        >
          <span>
            <i className="ti-check"></i>
          </span>
          {provider ? t('UPDATE') : t('ADD')}
        </button>
      </div>
    </Modal>
  );
};

export default EditProviderModal;
