/* eslint-disable no-console */
import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { css } from '@emotion/core';
import 'react-datepicker/dist/react-datepicker.css';
import { AlertService } from '../../services/AlertService';
import Preloader from '../Preloader/index';
import { useAuthorizedAxios } from '../../hooks/use-authorizedaxios';

const ExportsTab = ({
  schoolId,
  selectedPeriod,
  selectedExportData,
  setSelectedExportData
}) => {
  const { t } = useTranslation();
  const [selectedDate, setSelectedDate] = useState();
  const [highlightedDates, setHighlightedDates] = useState([]);

  const [{ data: exportsDataByPeriod, loading }, getDataByPeriod] =
    useAuthorizedAxios({
      manual: true,
      url: `${process.env.REACT_APP_BASE_URL}api/v2/schools/exports/${schoolId}/${selectedPeriod.value}/files`,
      method: 'GET'
    });

  const [{ loading: downloadLoading }, download] = useAuthorizedAxios({
    manual: true,
    url: `${process.env.REACT_APP_BASE_URL}api/v1/schools/${schoolId}/exports`,
    method: 'GET'
  });

  //POST PREVIEW
  const [
    { data: updateData, error: updatError, loading: updateLoading },
    updatePreview
  ] = useAuthorizedAxios({
    manual: true,
    url: `${process.env.REACT_APP_BASE_URL}api/v1/schools/${schoolId}/exports/${selectedExportData?.id}/preview`,
    method: 'POST'
  });

  const handlePreview = () => {
    updatePreview({
      data: {
        idSchool: schoolId,
        idFile: selectedExportData?.id
      }
    });
  };

  useEffect(() => {
    if (updateData) {
      AlertService.showSuccess(t('SAVE_SUCCESS'));
      setSelectedExportData((selectedExport) => ({
        ...selectedExport,
        submitted: true
      }));
    }

    if (updatError) {
      AlertService.showError(t('ERROR_SOMETHING_WENT_WRONG'));
    }
  }, [updateData, updatError]);

  const ColorCard = useCallback(
    ({ className, amout, name }) => (
      <div
        css={css`
          height: 90px;
          width: 90px;
        `}
        className={`text-center pt-4 ${className}`}
      >
        <p className="text-white fw-700 fs-18 lh-12">{amout}</p>
        <p className="text-white fw-700 fs-11 lh-12">{name}</p>
      </div>
    ),
    []
  );

  const handleSelectDate = (date) => {
    const formattedDate = moment(date).format('YYYY-MM-DD');

    const selectedSegment = exportsDataByPeriod.find((item) =>
      item.created_at.includes(formattedDate)
    );

    if (!selectedSegment) {
      console.warn(
        'No matching segment found for selectedPeriod:',
        selectedPeriod.value
      );
      setSelectedDate(date);
      setHighlightedDates([]);
      return;
    } else {
      setSelectedDate(date);
      setSelectedExportData(selectedSegment);
    }
  };

  useEffect(() => {
    if (exportsDataByPeriod && selectedPeriod?.value) {
      const selectedPeriodId = selectedPeriod.value;

      if (exportsDataByPeriod.length > 0) {
        setSelectedDate(new Date(exportsDataByPeriod[0].created_at));

        setHighlightedDates(
          exportsDataByPeriod.map(
            (exportItem) => new Date(exportItem.created_at)
          )
        );

        setSelectedExportData(exportsDataByPeriod[0]);
      } else {
        console.warn(
          'No matching segment found for selectedPeriod:',
          selectedPeriodId
        );
        setSelectedExportData(undefined);
      }
    }
  }, [exportsDataByPeriod, selectedPeriod]);

  useEffect(() => {
    setSelectedDate(undefined);
    setHighlightedDates([]);
  }, [selectedPeriod]);

  useEffect(() => {
    if (selectedPeriod.value) {
      getDataByPeriod();
    }
  }, [selectedPeriod.value]);

  return (
    <div
      css={css`
        border: solid 1px rgba(151, 151, 151, 0.25);
      `}
      className="card-body"
    >
      {(downloadLoading || loading || updateLoading) && (
        <Preloader
          customStyle={{
            backgroundColor: 'rgba(128, 128, 128, 0.5)'
          }}
        />
      )}

      <div className="d-flex justify-content-between form-type-material">
        <div
          className="col-3 text-left"
          css={css`
            .react-datepicker-popper {
              z-index: 995;
            }
          `}
        >
          <label htmlFor="datepicker-exp" className="m-0 d-block l-datepicker">
            {t('DATE')}
          </label>

          <DatePicker
            selected={selectedDate}
            onChange={handleSelectDate}
            includeDates={highlightedDates}
            className="form-control"
            placeholderText="Pick a Date"
            id="datepicker-exp"
          />
        </div>

        <div className="col-9 text-right">
          <button
            type="button"
            onClick={() =>
              download({
                url: `${process.env.REACT_APP_BASE_URL}api/v1/schools/${schoolId}/exports/${selectedExportData?.id}`
              }).then((_data) => window.open(`${_data.data}`))
            }
            className="ml-10 btn btn-bold d-inline-block btn-primary btn-label"
          >
            <span>
              <i className="fa fa-eye" />
            </span>
            {t('VIEW')}
          </button>
          <button
            type="button"
            onClick={() =>
              download({
                url: `${process.env.REACT_APP_BASE_URL}api/v1/schools/${schoolId}/exports/${selectedExportData?.id}`
              }).then((__data) => window.open(`${__data.data}&download=1`))
            }
            className="ml-10 btn btn-bold d-inline-block btn-primary btn-label"
          >
            <span>
              <i className="fa fa-download" />
            </span>
            {t('DOWNLOAD')}
          </button>
          {selectedExportData?.preview && !selectedExportData?.submitted && (
            <button
              type="button"
              className="ml-10 btn btn-bold d-inline-block btn-primary btn-label"
              onClick={handlePreview}
            >
              <span>
                <i className="fa fa-paper-plane-o" />
              </span>
              {t('SEND_FILE')}
            </button>
          )}
        </div>
      </div>

      <div>
        <div className="row my-3">
          <div className="col">
            <label className="fs-13 fw-700">{t('WAIVER')}</label>
            <div
              className="d-flex"
              css={css`
                .bg-dark-blue {
                  background-color: #2650b2;
                }
                .bg-dark-purple {
                  background-color: #926bde;
                }
                .bg-pink {
                  background-color: #f96197 !important;
                }
              `}
            >
              <ColorCard
                className="mr-1 bg-warning"
                name={t('SUMMARY_CODE_PR')}
                amout={selectedExportData?.process_report?.PR || 0}
              />
              <ColorCard
                className="mr-1 bg-warning"
                name={t('SUMMARY_CODE_PA')}
                amout={selectedExportData?.process_report?.PA || 0}
              />
              <ColorCard
                className="mr-1 bg-warning"
                name={t('SUMMARY_CODE_PE')}
                amout={selectedExportData?.process_report?.PE || 0}
              />
              <ColorCard
                className="mr-1 bg-success"
                name={t('SUMMARY_CODE_AP')}
                amout={selectedExportData?.process_report?.AP || 0}
              />
              <ColorCard
                className="mr-1 bg-danger"
                name={t('SUMMARY_CODE_CW')}
                amout={selectedExportData?.process_report?.CW || 0}
              />
              <ColorCard
                className="mr-1 bg-dark-purple"
                name={t('SUMMARY_CODE_DE')}
                amout={selectedExportData?.process_report?.DE || 0}
              />
              <ColorCard
                className="mr-1 bg-dark-blue"
                name={t('SUMMARY_CODE_SP')}
                amout={selectedExportData?.process_report?.SP || 0}
              />
            </div>
          </div>
        </div>
        <div className="row my-3">
          <div className="col-8">
            <label className="fs-13 fw-700">{t('SHIP_ENROLLMENT')}</label>
            <div className="d-flex">
              <ColorCard
                className="mr-1 bg-success"
                name={t('SUMMARY_CODE_OI')}
                amout={selectedExportData?.process_report?.OI || 0}
              />
              <ColorCard
                className="mr-1 bg-info"
                name={t('SUMMARY_CODE_DF')}
                amout={selectedExportData?.process_report?.DF || 0}
              />
              <ColorCard
                className="mr-1 bg-danger"
                name={t('SUMMARY_CODE_CE')}
                amout={selectedExportData?.process_report?.CE || 0}
              />
              <ColorCard
                className="mr-1 bg-pink"
                name={t('SUMMARY_CODE_MB')}
                amout={selectedExportData?.process_report?.MB || 0}
              />
            </div>
          </div>
          <div className="col">
            <label className="fs-13 fw-700">{t('GENERAL')}</label>
            <div
              className="d-flex"
              css={css`
                .bg-dark-grey {
                  background-color: #979797;
                }
                .bg-strong-grey {
                  background-color: #626a79;
                }
              `}
            >
              <ColorCard
                className="mr-1 bg-strong-grey"
                name={t('SUMMARY_CODE_DP')}
                amout={selectedExportData?.process_report?.DP || 0}
              />
              <ColorCard
                className="mr-1 bg-dark-grey"
                name={t('SUMMARY_CODE_NR')}
                amout={selectedExportData?.process_report?.NR || 0}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExportsTab;
