import React from 'react';
import { ToastContainer } from 'react-toastify';
import { ApolloProvider } from '@apollo/client';
import './App.css';
import Router from './router/Router';
import { AlertService } from './services/AlertService';
import { DashboardStateProvider } from './context/DashboardContext';
import { client } from './client';

function refAlert(alertRef) {
  AlertService.setDropdownAlertInstance(alertRef);
}

function App() {
  return (
    <div className="App">
      <ApolloProvider client={client}>
        <DashboardStateProvider>
          <Router />
          <ToastContainer ref={refAlert} />
        </DashboardStateProvider>
      </ApolloProvider>
    </div>
  );
}

export default App;
