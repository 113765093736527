import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StyledMain } from '../../styles/common/main';
import { useForm } from 'react-hook-form';
import useAxios from 'axios-hooks';
import { css } from '@emotion/core';
import { AlertService } from '../../services/AlertService';
import Preloader from '../Preloader/index';
import { SchoolService } from '../../services/SchoolService';

const ProfileTab = ({ school, refetch }) => {
  const { t } = useTranslation();
  const [editProfile, setEditProfile] = useState(false);

  const { register, handleSubmit, formState } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      schoolName: school ? school.school_name : '',
      schoolAbbr: school ? school.school_abbr : '',
      academicTermFormat: school ? school.academic_term_format : '',
      timeZone: school ? school.timezone : 'Eastern',
      healthOfficePhone: school ? school.health_office_phone : '',
      healthOfficeUrl: school ? school.health_office_url : '',
      heathOfficeContactEmail: school ? school.health_office_email_address : '',
      healthOfficeServicesName: school
        ? school.health_office_services_name
        : '',
      healthOfficeContactName: school ? school.health_office_contact_name : '',
      shipRequirementsUrl: school ? school.ship_requirement_url : '',
      schoolInsuranceName: school ? school.school_insurance_name : '',
      shipInformationUrl: school ? school.ship_information_url : ''
    }
  });

  const [{ data, error, loading }, executePost] = useAxios(
    {
      url: `${process.env.REACT_APP_BASE_URL}api/v1/schools/${school.id}`,
      method: 'POST',
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('jwt')}`
      }
    },
    {
      manual: true
    }
  );

  useEffect(() => {
    SchoolService.canSchool('SCHOOLS:PROFILE', school.id).then((response) => {
      setEditProfile(response);
    });
  }, []);

  useEffect(() => {
    SchoolService.canSchool('SCHOOLS:PROFILE', school.id).then((response) => {
      setEditProfile(response);
    });
  }, [formState.isValid]);

  useEffect(() => {
    if (data && !error) {
      AlertService.showSuccess(t('SAVE_SUCCESS'));
      refetch();
    } else if (error) {
      Object.keys(error.response.data.message).map((key) =>
        AlertService.showError(error.response.data.message[key])
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, error]);

  const handleSchoolUpdate = (data) => {
    executePost({
      params: data
    });
  };

  const renderUpdate = () => {
    if (editProfile) {
      return (
        <div className="col-12">
          <button
            disabled={!formState.isValid}
            className="btn text-uppercase btn-block w-auto ml-auto btn-bold btn-label btn-primary"
          >
            <span>
              <i className="ti-check"></i>
            </span>
            {t('UPDATE')}
          </button>
        </div>
      );
    }
  };

  const renderTitle = (title) => {
    const strongTitle = title.split(' ')[0];
    const rest =
      title.split(' ').length > 0 ? title.substr(title.indexOf(' ') + 1) : '';
    return (
      <div className="col-md-12">
        <label className="fs-16 mb-4">
          <strong>{strongTitle}</strong> {rest}
        </label>
      </div>
    );
  };

  return (
    <StyledMain className="container">
      <div className="card card-shadowed">
        <div className="card-body">
          {loading && <Preloader />}
          <form
            onSubmit={handleSubmit(handleSchoolUpdate)}
            className="row form-type-material"
          >
            {/* LEFT COLUMN */}
            <div className="col-md-6">
              <div className="row">
                {renderTitle(t('GENERAL_SETTINGS'))}
                <div className="col-md-8">
                  <div className="form-group do-float">
                    <input
                      ref={register({ required: true })}
                      type="text"
                      name="schoolName"
                      id="schoolName"
                      className="form-control"
                    />
                    <label
                      htmlFor="schoolName"
                      className="label-floated require"
                    >
                      {t('SCHOOL_NAME')}
                    </label>
                  </div>
                  <div className="form-group do-float">
                    <input
                      ref={register()}
                      type="text"
                      name="schoolAbbr"
                      id="schoolAbbr"
                      className="form-control"
                    />
                    <label htmlFor="schoolAbbr" className="label-floated">
                      {t('SCHOOL_ABBREVIATION')}
                    </label>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group do-float">
                    <select
                      ref={register({ required: true })}
                      name="timeZone"
                      id="timeZone"
                      className="form-control"
                      data-provide="selectpicker"
                    >
                      <option disabled value="">
                        {t('SELECT')}
                      </option>
                      <option value="Eastern">Eastern</option>
                      <option value="Central">Central</option>
                      <option value="Mountain">Mountain</option>
                      <option value="Pacific">Pacific</option>
                    </select>
                    <label htmlFor="timeZone" className="label-floated require">
                      {t('TIME_ZONE')}
                    </label>
                  </div>
                  <div className="form-group do-float">
                    <select
                      ref={register({ required: true })}
                      name="academicTermFormat"
                      id="academicTermFormat"
                      className="form-control"
                      data-provide="selectpicker"
                    >
                      <option disabled value="">
                        {t('SELECT')}
                      </option>
                      <option value="Semesters">{t('SEMESTERS')}</option>
                      <option value="Trimesters">{t('TRIMESTERS')}</option>
                      <option value="Quarters">{t('QUARTERS')}</option>
                    </select>
                    <label
                      htmlFor="academicTermFormat"
                      className="label-floated require"
                    >
                      {t('ACADEMIC_TERMS')}
                    </label>
                  </div>
                </div>
              </div>
              <div className="row mt-3">
                {renderTitle(t('STUDENT_INSURANCE_OFFICE'))}
                <div className="col-md-8">
                  <div className="form-group do-float">
                    <input
                      ref={register()}
                      type="text"
                      name="healthOfficeContactName"
                      id="healthOfficeContactName"
                      className="form-control"
                    />
                    <label
                      htmlFor="healthOfficeContactName"
                      className="label-floated"
                    >
                      {t('SCHOOL_INSURANCE_CONTACT_NAME')}
                    </label>
                  </div>

                  <div className="form-group do-float">
                    <input
                      ref={register()}
                      type="email"
                      name="heathOfficeContactEmail"
                      id="heathOfficeContactEmail"
                      className="form-control"
                    />
                    <label
                      htmlFor="heathOfficeContactEmail"
                      className="label-floated"
                    >
                      {t('SCHOOL_INSURANCE_CONTACT_EMAIL')}
                    </label>
                  </div>

                  <div className="form-group do-float">
                    <input
                      ref={register()}
                      type="text"
                      name="healthOfficePhone"
                      id="healthOfficePhone"
                      className="form-control"
                    />
                    <label
                      htmlFor="healthOfficePhone"
                      className="label-floated"
                    >
                      {t('SCHOOL_INSURANCE_CONTACT_PHONE')}
                    </label>
                  </div>
                </div>
              </div>
            </div>

            {/* RIGHT COLUMN */}
            <div className="col-md-6">
              <div className="row">
                {renderTitle(t('STUDENT_HEALTH_CENTER'))}
                <div className="col-md-8">
                  <div className="form-group do-float">
                    <input
                      ref={register()}
                      type="text"
                      name="healthOfficeServicesName"
                      id="healthOfficeServicesName"
                      className="form-control"
                    />
                    <label
                      htmlFor="healthOfficeServicesName"
                      className="label-floated"
                    >
                      {t('STUDENT_HEALTH_SERVICE_NAME')}
                    </label>
                  </div>
                </div>
                <div className="col-md-4">
                  <label
                    css={css`
                      color: #4d5259;
                      font-weight: 500;
                      font-family: Roboto;
                      font-size: 14px;
                    `}
                    className="mb-0 mt-16"
                  >{`NPI - ${school ? school.npi_number : ''}`}</label>
                </div>
              </div>
              <div className="row">
                <div className="col-md-8">
                  <div className="form-group do-float">
                    <input
                      ref={register()}
                      type="text"
                      name="healthOfficeUrl"
                      id="healthOfficeUrl"
                      className="form-control"
                    />
                    <label htmlFor="healthOfficeUrl" className="label-floated">
                      {t('LINK_STUDENT_HEALTH_SERVICES_PAGE')}
                    </label>
                  </div>
                </div>
                <div className="col-md-4">
                  <label
                    css={css`
                      color: #4d5259;
                      font-weight: 500;
                      font-family: Roboto;
                      font-size: 14px;
                    `}
                    className="mb-0 mt-16"
                  >{`TIN - ${
                    school ? school.tax_identification_number : ''
                  }`}</label>
                </div>
              </div>
              <div className="row mt-3">
                {renderTitle(t('INSURANCE_INFORMATION'))}
                <div className="col-md-8">
                  <div className="form-group do-float">
                    <input
                      ref={register()}
                      type="text"
                      name="schoolInsuranceName"
                      id="schoolInsuranceName"
                      className="form-control"
                    />
                    <label
                      htmlFor="schoolInsuranceName"
                      className="label-floated"
                    >
                      {t('SCHOOL_INSURANCE_NAME')}
                    </label>
                  </div>

                  <div className="form-group do-float">
                    <input
                      ref={register()}
                      type="text"
                      name="shipRequirementsUrl"
                      id="shipRequirementsUrl"
                      className="form-control"
                    />
                    <label
                      htmlFor="shipRequirementsUrl"
                      className="label-floated"
                    >
                      {t('LINK_SCHOOL_INSURANCE_REQUIREMENTS_PAGE')}
                    </label>
                  </div>

                  <div className="form-group do-float">
                    <input
                      ref={register()}
                      type="text"
                      name="shipInformationUrl"
                      id="shipInformationUrl"
                      className="form-control"
                    />
                    <label
                      htmlFor="shipInformationUrl"
                      className="label-floated"
                    >
                      {t('LINK_SCHOOL_SHIP_PAGE')}
                    </label>
                  </div>
                </div>
              </div>
            </div>
            {renderUpdate()}
          </form>
        </div>
      </div>
    </StyledMain>
  );
};

export default ProfileTab;
