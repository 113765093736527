import React, { useState, useEffect, useContext } from 'react';
import { css } from '@emotion/core';
import { useTranslation } from 'react-i18next';
import { SearchStateContext } from '../../context/SearchContext';
import { CSVLink } from 'react-csv';

// Components
import Pagination from '../Pagination/index';
import Preloader from '../Preloader/index';
import SchoolFilter from './SchoolFilter';
import EnrollmentPeriodFilter from './Filters/EnrollmentPeriodFilter';

// Services
import { UserService } from '../../services/UserService';
import { useAuthorizedAxios } from '../../hooks/use-authorizedaxios';
import { getStudentsQueryParams } from './Utils/getStudenstQueryParams';

const BillingReportContent = () => {
  const { debouncedSearchKeyword } = useContext(SearchStateContext);
  const [schoolName, setSchoolName] = useState();
  const [selectedSchoolId, setSelectedSchoolId] = useState(
    UserService.getUser()?.school_id || ''
  );
  const [shouldDownload, setShouldDownload] = useState(false);
  const [csvName, setCsvName] = useState('');
  const [parsedUsersData, setParsedUsersData] = useState([]);
  const [selectEnrollmentId, setSelectEnrollmentId] = useState('');
  const [studentId, setStudentId] = React.useState('');
  const refCsvLink = React.useRef(null);

  const { t } = useTranslation();

  const [{ data: studensBillingData, loading }, getStudentsBilling] =
    useAuthorizedAxios({
      manual: true,
      url: `${process.env.REACT_APP_BASE_URL}api/v2/reports/students-manual-billing`,
      method: 'GET',
      params: {
        'filter[query]': debouncedSearchKeyword
      }
    });

  const [{ data: exportData }, postExport] = useAuthorizedAxios({
    manual: true,
    url: `${process.env.REACT_APP_BASE_URL}api/v2/reports/students-manual-billing/exports`,
    method: 'POST',
    params: {
      'filter[query]': debouncedSearchKeyword
    }
  });

  const [{ data: schoolData }, getBasics] = useAuthorizedAxios({
    manual: true,
    url: `${process.env.REACT_APP_BASE_URL}api/v1/schools/basic`,
    method: 'GET'
  });

  const handleExport = async (data) => {
    setShouldDownload(true);
    await postExport({
      params: {
        schoolId: selectedSchoolId,
        segmentId: selectEnrollmentId,
        studentId: studentId
      }
    });
  };

  const onPageChange = (selectedPage) => {
    // Build base query parameters using your helper function.
    const queryParams = getStudentsQueryParams(
      selectedSchoolId,
      selectEnrollmentId,
      studentId
    );

    // If the queryParams aren't valid (i.e., missing required fields), exit early.
    if (!queryParams) return;

    // Add the page parameter.
    queryParams.params.page = selectedPage;

    // Call getStudentsDental with the complete set of parameters.
    getStudentsBilling(queryParams);
  };

  useEffect(() => {
    const params = getStudentsQueryParams(
      selectedSchoolId,
      selectEnrollmentId,
      studentId
    );

    if (params) {
      getStudentsBilling(params);
    }
  }, [selectEnrollmentId, selectedSchoolId, studentId]);

  useEffect(() => {
    getBasics();
  }, []);

  useEffect(() => {
    if (schoolData) {
      schoolData.forEach((school) => {
        if (school.id === selectedSchoolId) setSchoolName(school.school_name);
      });
    }
  }, [selectedSchoolId, schoolData]);

  useEffect(() => {
    if (shouldDownload) {
      if (
        exportData &&
        exportData.length > 0 &&
        refCsvLink.current &&
        refCsvLink.current.link
      ) {
        setParsedUsersData(exportData);
      }
    }
  }, [exportData]);

  useEffect(() => {
    if (shouldDownload && parsedUsersData.length > 0 && schoolName) {
      let dt = new Date();
      let formatDate =
        dt.getFullYear().toString().padStart(4, '0') +
        (dt.getMonth() + 1).toString().padStart(2, '0') +
        dt.getDate().toString().padStart(2, '0') +
        dt.getHours().toString().padStart(2, '0') +
        dt.getMinutes().toString().padStart(2, '0') +
        dt.getSeconds().toString().padStart(2, '0');
      setCsvName(`Manual Billing Report ${schoolName} -${formatDate}.csv`);
    }
  }, [parsedUsersData]);

  useEffect(() => {
    if (shouldDownload) {
      if (parsedUsersData?.length > 0 && refCsvLink?.current?.link) {
        refCsvLink.current.link.click();
        setShouldDownload(false);
        setParsedUsersData([]);
      }
      setShouldDownload(false);
    }
  }, [csvName]);

  return (
    <div className="container">
      <div className="row form-type-material">
        {loading && <Preloader />}

        {!UserService.getUser().school_id && (
          <div className="col-3">
            <SchoolFilter
              selectedSchoolId={selectedSchoolId}
              setSelectedSchoolId={setSelectedSchoolId}
            />
          </div>
        )}

        <div className="col-3">
          <EnrollmentPeriodFilter
            selectedSchoolId={selectedSchoolId}
            selectEnrollment={selectEnrollmentId}
            setSelectEnrollment={setSelectEnrollmentId}
            enableEmptyState
          />
        </div>

        <div className="col-3">
          <div className="form-group do-float">
            <label htmlFor="altId" className="label-floated">
              {t('STUDENT_ID')}
            </label>
            <input
              type="number"
              name="altId"
              id="altId"
              className="form-control bg-transparent"
              placeholder={t('STUDENT_ID')}
              value={studentId}
              onChange={(e) => setStudentId(e.target.value)}
            />
          </div>
        </div>

        <div className="col mb-3">
          <button
            disabled={
              studensBillingData?.data?.length == 0 || !studensBillingData?.data
            }
            onClick={handleExport}
            className="mt-10 btn btn-bold d-block ml-auto btn-primary btn-label"
            type="submit"
          >
            <span>
              <i className="ti-download"></i>
            </span>
            {t('EXPORT_CSV')}
          </button>

          {studensBillingData?.data && (
            <CSVLink
              data={parsedUsersData}
              filename={csvName}
              className="hidden"
              target="_blank"
              ref={refCsvLink}
            />
          )}
        </div>
      </div>
      <div className="card card-shadowed">
        <div className="card-body">
          <table className="table table-striped table-lg">
            <thead>
              <tr>
                <th>{t('FIRST_NAME')}</th>
                <th>{t('LAST_NAME')}</th>
                <th>{t('STUDENT_ID')}</th>
                <th>{t('EMAIL_ADDRESS')}</th>
              </tr>
            </thead>
            <tbody
              css={css`
                .left-border {
                  border-left: 2px solid #f96868;
                }
              `}
            >
              {studensBillingData?.data?.map((user) => {
                if (schoolName !== null && schoolName !== undefined) {
                  return (
                    <tr key={user.id}>
                      <td>{user.first_name}</td>
                      <td>{user.last_name}</td>
                      <td>{user.student_id}</td>
                      <td>{user.email_address}</td>
                    </tr>
                  );
                } else {
                  return <></>;
                }
              })}
            </tbody>
          </table>
        </div>
      </div>

      <div className="mb-100">
        <Pagination
          pages={studensBillingData?.data?.last_page || 0}
          onPageChange={onPageChange}
          from={studensBillingData?.data?.from || 0}
          to={studensBillingData?.data?.to || 1}
          total={studensBillingData?.data?.total || 1}
        />
      </div>
    </div>
  );
};

export default BillingReportContent;
