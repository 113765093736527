import React, { useContext, useState, useEffect, useMemo } from 'react';
import useAxios from 'axios-hooks';
import { useTranslation } from 'react-i18next';
import { SchoolStateContext } from '../../context/SchoolContext';
import CardHeader from '../CardHeader';
import Spinner from '../Spinner/index';
import { AlertService } from '../../services/AlertService';
import SupplementalMessage from './SupplementMessage';

// const SupplementsTab = ({ selected }) => {
const SupplementsTab = () => {
  const { t } = useTranslation();

  const [schoolId] = useContext(SchoolStateContext);
  const [htmlContent, setHtmlContent] = useState('');
  const [formData, setFormData] = useState({
    title: '',
    url: '',
    enabled: false,
    cost: 0,
    enrollmentsEnabled: false
  });

  const [{ loading, data: supplementalData }, getSupplements] = useAxios(
    {
      url: `${process.env.REACT_APP_BASE_URL}api/v1/schools/${schoolId}/settings/supplements`,
      method: 'GET',
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('jwt')}`
      }
    },
    {
      manual: true
    }
  );

  const [{ error, response }, executePost] = useAxios(
    {
      url: `${process.env.REACT_APP_BASE_URL}api/v1/schools/${schoolId}/settings/supplements`,
      method: 'POST',
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('jwt')}`
      }
    },
    {
      manual: true
    }
  );

  useEffect(() => {
    getSupplements();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (response && !error) {
      AlertService.showSuccess(t('SAVE_SUCCESS'));
    } else if (
      error &&
      error.response &&
      error.response.data &&
      error.response.data.message
    ) {
      Object.keys(error.response.data.message).map((key) =>
        AlertService.showError(error.response.data.message[key][0])
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response, error]);

  useEffect(() => {
    if (supplementalData) {
      const { settings_supplement_plan, supplement_plan_enabled } =
        supplementalData;

      setFormData({
        title: settings_supplement_plan?.plan_title || '',
        url: settings_supplement_plan?.plan_url || '',
        enabled: supplement_plan_enabled || false,
        cost: settings_supplement_plan?.cost || 0,
        enrollmentsEnabled:
          settings_supplement_plan?.enrollments_enabled || false
      });

      setHtmlContent(
        settings_supplement_plan?.plan_description
          ? atob(settings_supplement_plan?.plan_description)
          : ''
      );
    }
  }, [supplementalData]);

  const handleCheck = (type) => {
    setFormData({ ...formData, [type]: !formData[type] });
  };

  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.currentTarget.name]: e.currentTarget.value });
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    if (
      !formData.title ||
      formData.title.length > 75 ||
      formData.title.length < 3
    ) {
      return AlertService.showError(t('TITLE_FORMAT_ERROR'));
    }

    const params = {
      isEnabled: formData.enabled ? 1 : 0,
      planTitle: formData.title,
      planUrl: formData.url,
      planDescription: btoa(htmlContent),
      cost: formData.cost,
      enrollmentsEnabled: formData.enrollmentsEnabled ? 1 : 0
    };

    executePost({ params });
  };

  const isFormValid = useMemo(() => {
    if (
      formData.url &&
      formData.title &&
      formData.title.length < 75 &&
      formData.title.length > 3 &&
      formData.cost
    ) {
      return true;
    }
    return false;
  }, [formData]);

  return (
    <div className="card">
      <CardHeader title={t('SUPPLEMENT_SEETINGS')} />
      <div className="card-body">
        {loading ? (
          <Spinner />
        ) : (
          <form onSubmit={handleFormSubmit} className="form-type-material">
            <div className="row">
              <div className="col-md-4">
                <div
                  className={`form-group mt-10 ${
                    formData.title ? 'do-float' : ''
                  }`}
                >
                  <input
                    type="text"
                    className="form-control"
                    name="title"
                    id="title"
                    onChange={handleInputChange}
                    value={formData.title || ''}
                  />
                  <label htmlFor="title">{t('SUPPLEMENTAL_PLAN_TITLE')}</label>
                </div>
                <div className={`form-group ${formData.url ? 'do-float' : ''}`}>
                  <input
                    type="text"
                    className="form-control"
                    name="url"
                    id="url"
                    onChange={handleInputChange}
                    value={formData.url || ''}
                  />
                  <label htmlFor="url">{t('SUPPLEMENTAL_WEBSITE_URL')}</label>
                </div>
              </div>
              <div className="col-md-5 d-flex align-items-end">
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span className="input-group-text">$</span>
                  </div>
                  <div
                    className={`form-group input-group-input mt-10 do-float`}
                  >
                    <input
                      type="number"
                      className="form-control w-50"
                      name="cost"
                      id="cost"
                      onChange={handleInputChange}
                      value={formData.cost}
                    />
                    <label htmlFor="cost">{t('COST')}</label>
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group mt-10 pt-20">
                  <label className="switch">
                    <input
                      type="checkbox"
                      checked={!!formData.enabled}
                      name="enabled"
                      id="enabled"
                      onChange={() => handleCheck('enabled')}
                    />
                    <span className="switch-indicator"></span>
                    <span className="switch-description">{t('ENABLE')}</span>
                  </label>
                </div>
                <div className="form-group">
                  <label className="switch">
                    <input
                      type="checkbox"
                      checked={!!formData.enrollmentsEnabled}
                      name="enrollmentsEnabled"
                      id="enrollmentsEnabled"
                      onChange={() => handleCheck('enrollmentsEnabled')}
                    />
                    <span className="switch-indicator"></span>
                    <span className="switch-description">
                      {t('ENROLLMENTS_ENABLED')}
                    </span>
                  </label>
                </div>
              </div>
            </div>
            <SupplementalMessage
              defaultValue={
                supplementalData?.settings_supplement_plan?.plan_description
                  ? atob(
                      supplementalData?.settings_supplement_plan
                        ?.plan_description
                    )
                  : undefined
              }
              convertedContent={htmlContent}
              setConvertedContent={setHtmlContent}
            />
            <button
              disabled={!isFormValid}
              className={`mt-20 btn btn-bold d-block ml-auto btn-primary btn-label ${
                isFormValid ? '' : 'disabled'
              }`}
              type="submit"
            >
              <span>
                <i className="ti-check"></i>
              </span>
              {t('SUBMIT')}
            </button>
          </form>
        )}
      </div>
    </div>
  );
};

export default SupplementsTab;
