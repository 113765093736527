/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';
import { css } from '@emotion/core';
import { useTranslation } from 'react-i18next';

const PeriodsSelect = ({ selectedPeriod, setSelectedPeriod, periodsData }) => {
  const [selectOptions, setSelectOptions] = useState([]);
  const [count, setCount] = useState(0);
  const { t } = useTranslation();

  useEffect(() => {
    if (periodsData?.segments) {
      const periodOptions = [];

      periodsData.segments.forEach((period) => {
        periodOptions.push({ value: period.segment_id, label: period.label });
      });

      setSelectOptions(periodOptions);
      setCount((c) => c + 1);
    }
  }, [periodsData]);

  const handlePeriodChange = (e) => {
    const selectedValue = e.target.value;

    // Find the selected object in periodOptions based on value
    const selectedOption = periodsData?.segments?.find(
      (item) => item.segment_id === selectedValue
    );

    if (selectedOption) {
      setSelectedPeriod({
        label: selectedOption.label,
        value: selectedOption.segment_id
      }); // Update state with the selected object
    }
  };

  return (
    <div
      className="form-group do-float"
      key={count}
      css={css`
        .form-type-material .bootstrap-select .dropdown-toggle:focus {
          background-color: transparent !important;
        }
      `}
    >
      <select
        type="text"
        name="periods"
        id="periods"
        className="form-control bg-transparent"
        data-provide="selectpicker"
        onChange={handlePeriodChange}
        value={selectedPeriod?.value || ''}
      >
        <option value="" disabled>
          Nothing selected
        </option>

        {selectOptions.map((o) => (
          <option
            key={o.value}
            value={o.value}
            className={o.value ? '' : 'd-none'}
          >
            {t(o.label)}
          </option>
        ))}
      </select>
    </div>
  );
};

export default PeriodsSelect;
