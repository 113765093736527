/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';
import useAxios from 'axios-hooks';
import ActivitySummary from '../ActivitySummary/index';
import SchoolActivitySummary from '../SchoolActivitySummary/index';
import Spinner from '../Spinner/index';
// eslint-disable-next-line import/named
import { UserService } from '../../services/UserService';
// eslint-disable-next-line no-unused-vars
// import LineChart from '../LineChart';

const SummaryDashboard = () => {
  const [defaultLoading, setDefaultLoading] = useState(true);
  const [firstLoad, setFirstLoad] = useState(false);
  const [me, setMe] = useState(null);

  useEffect(() => {
    const parsedMe = JSON.parse(window.localStorage.getItem('me'));
    setMe(parsedMe);
  }, []);

  const [{ data: summaryData, loading: summaryLoading }, getSummary] = useAxios(
    {
      url: `${process.env.REACT_APP_BASE_URL}api/v1/dashboard/summary`,
      method: 'GET',
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('jwt')}`
      }
    },
    {
      manual: true
    }
  );

  const [
    { data: schoolSummaryData, loading: schoolSummaryLoading },
    getSchoolSummary
  ] = useAxios(
    {
      url: `${process.env.REACT_APP_BASE_URL}api/v1/schools/${me?.school_id}/periods`,
      method: 'GET',
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('jwt')}`
      }
    },
    {
      manual: true
    }
  );

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    //const me = JSON.parse(window.localStorage.getItem('me'));
    if (me) {
      if (me.type === 'user') {
        getSchoolSummary({
          params: {
            schoolId: me.school_id
          }
        });
        /*getSummary({
          params: {
            schoolId: me.school_id
          }
        });*/
        /*getGraphs({
          params: {
            schoolId: me.school_id
          }
        });*/
      } else if (me.type === 'admin' || me.type === 'super_admin') {
        getSummary();
      }
      const interval = setInterval(() => {
        if (me.type === 'user') {
          getSchoolSummary({
            params: {
              schoolId: me.school_id
            }
          });
          /*getSummary({
            params: {
              schoolId: me.school_id
            }
          });*/
          /*getGraphs({
            params: {
              schoolId: me.school_id
            }
          });*/
        } else if (me.type === 'admin' || me.type === 'super_admin') {
          getSummary();
        }
      }, 60000);
      return () => {
        clearInterval(interval);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [me]);

  useEffect(() => {
    if (
      (summaryLoading /*|| graphsLoading*/ || schoolSummaryLoading) &&
      !firstLoad
    ) {
      setFirstLoad(true);
    } else if (
      !summaryLoading &&
      //!graphsLoading &&
      !schoolSummaryLoading &&
      defaultLoading &&
      firstLoad
    ) {
      setDefaultLoading(false);
    }
  }, [
    summaryLoading,
    //graphsLoading,
    defaultLoading,
    firstLoad,
    schoolSummaryLoading
  ]);

  if (defaultLoading) {
    return <Spinner />;
  }

  return (
    <>
      {UserService.isAdmin() &&
        summaryData &&
        Array.isArray(summaryData) &&
        summaryData.map((summaryGroup) => {
          if (Array.isArray(summaryGroup)) {
            return summaryGroup.map((summary) => (
              <div key={summary.ID} className="mb-4">
                <ActivitySummary summary={summary} />{' '}
              </div>
            ));
          }
          return (
            <div key={summaryGroup.ID} className="mb-4">
              <ActivitySummary summary={summaryGroup} />{' '}
            </div>
          );
        })}
      {!UserService.isAdmin() &&
        schoolSummaryData &&
        schoolSummaryData.data && (
          <div
            key={schoolSummaryData.data[0].school.school_name}
            className="mb-4"
          >
            <SchoolActivitySummary
              summary={schoolSummaryData.data}
              //summaryData={schoolSummaryData.data}
              //graphsData={graphsData}
              //summaryData={summaryData}
            />
          </div>
        )}
      {/* {!UserService.isAdmin() && graphsData && (
        <div className="row">
          <div className="col-md-6">
            <LineChart
              year={graphsData.ACADEMIC_YEAR}
              segment={graphsData.SEGMENT_TITLE}
              data={[graphsData.WAIVERS, graphsData.SUPPLEMENTS]}
              labels={['WAIVERS_SUBMITTED', 'SUPPLEMENT_PLANS']}
              colors={[
                {
                  border: 'rgba(51,202,185,1)',
                  background: 'rgba(51,202,185,0.5)'
                },
                {
                  border: 'rgba(151, 151, 151,1)',
                  background: 'rgba(151, 151, 151,0.5)'
                }
              ]}
            />
          </div>
          <div className="col-md-6">
            <LineChart
              year={graphsData.ACADEMIC_YEAR}
              segment={graphsData.SEGMENT_TITLE}
              data={[graphsData.SHIP]}
              labels={['ENROLLMENTS_SUBMITTED']}
              colors={[
                {
                  border: 'rgba(146, 107, 222,1)',
                  background: 'rgba(146, 107, 222,0.5)'
                }
              ]}
            />
          </div>
        </div>
      )} */}
    </>
  );
};

export default SummaryDashboard;
