import React, { useContext, useState, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { css } from '@emotion/core';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';

import { SchoolStateContext } from '../../context/SchoolContext';
import Preloader from '../Preloader/index';
import { useAuthorizedAxios } from '../../hooks/use-authorizedaxios';

const TAPTab = () => {
  const { t } = useTranslation();
  const [schoolId] = useContext(SchoolStateContext);
  const [selectedSegment, setSelectedSegment] = useState('');
  const [selectedDate, setSelectedDate] = useState();
  const [highlightedDates, setHighlightedDates] = useState([]);
  const [selectedFile, setSelectedFile] = useState();

  const [{ data: segmentsData, loading: loadingSegments }, getSegments] =
    useAuthorizedAxios({
      manual: true,
      url: `${process.env.REACT_APP_BASE_URL}api/v2/schools/edi/${schoolId}`,
      method: 'GET'
    });

  const [{ data: filesData, loading: loadingFiles }, getFiles] =
    useAuthorizedAxios({
      manual: true,
      url: `${process.env.REACT_APP_BASE_URL}api/v2/schools/edi/${schoolId}/${selectedSegment}/files`,
      method: 'GET'
    });

  const [{ loading: downloadLoading }, download] = useAuthorizedAxios({
    manual: true,
    url: `${process.env.REACT_APP_BASE_URL}api/v1/schools/${schoolId}/tpa/exports/${selectedFile?.id}`,
    method: 'GET'
  });

  useEffect(() => {
    getSegments();
  }, []);

  useEffect(() => {
    if (selectedSegment) {
      getFiles();
    }
  }, [selectedSegment]);

  useEffect(() => {
    if (filesData) {
      if (filesData.length > 0) {
        setSelectedDate(new Date(filesData[0].created_at));

        setHighlightedDates(
          filesData.map((fileItem) => new Date(fileItem.created_at))
        );

        setSelectedFile(filesData[0]);
      } else {
        console.warn('No matching filesData found');
        setSelectedFile(undefined);
      }
    }
  }, [filesData]);

  const handleSelectDate = (date) => {
    const formattedDate = moment(date).format('YYYY-MM-DD');

    const dateBasedFile = filesData.find((item) =>
      item.created_at.includes(formattedDate)
    );

    if (!dateBasedFile) {
      console.warn(
        'No matching segment found for dateBasedFile:',
        dateBasedFile
      );
      setSelectedDate(date);
      setHighlightedDates([]);
      return;
    } else {
      setSelectedDate(date);
      setSelectedFile(dateBasedFile);
    }
  };

  const handleDownload = async () => {
    await download().then((response) => window.open(response.data));
  };

  const handleView = async () => {
    await download().then((response) =>
      window.open(`${response.data}&download=1`)
    );
  };

  const ColorCard = useCallback(
    ({ className, amout, name }) => (
      <div
        css={css`
          height: 127px;
          width: 140px;
        `}
        className={`text-center pt-4 ${className}`}
      >
        <p className="text-white fw-700 fs-35">{amout}</p>
        <p className="text-white fw-700 fs-13">{name}</p>
      </div>
    ),
    []
  );

  return (
    <div className="card card-shadowed">
      <div className="col-md-3 card-title">
        {segmentsData?.segments && (
          <select
            name="period"
            id="period"
            className="form-control"
            data-provide="selectpicker"
            onChange={(e) => setSelectedSegment(e.target.value)}
            value={selectedSegment}
          >
            <option value="" disabled>
              Nothing selected
            </option>

            {segmentsData?.segments?.map((segment, index) => {
              return (
                <option key={index} value={segment.segment_id}>
                  {segment.label}
                </option>
              );
            })}
          </select>
        )}
      </div>
      <div className="card-body">
        <div
          css={css`
            border: solid 1px rgba(151, 151, 151, 0.25);
          `}
          className="card-body"
        >
          {(downloadLoading || loadingSegments || loadingFiles) && (
            <Preloader />
          )}

          <div className="d-flex justify-content-between form-type-material">
            <div className="col p-0">
              <label
                css={css`
                  color: #626a79;
                  font-family: Roboto;
                  font-size: 1rem;
                `}
              >
                {selectedFile
                  ? moment(selectedFile.created_at).format('MM/DD/YYYY hh:mm A')
                  : ''}
              </label>
            </div>
            <div
              className="col-3 text-right"
              css={css`
                .react-datepicker-popper {
                  z-index: 995;
                }
              `}
            >
              <DatePicker
                selected={selectedDate}
                onChange={handleSelectDate}
                includeDates={highlightedDates}
                className="form-control"
                placeholderText="Pick a Date"
              />
            </div>
          </div>

          <div
            css={css`
              .bg-dark-purple {
                background-color: #926bde;
              }
            `}
            className="d-flex my-3"
          >
            <ColorCard
              className="mr-1 bg-success"
              name={t('ENROLLMENTS')}
              amout={selectedFile?.process_report?.SHIP || 0}
            />
            <ColorCard
              className="mr-1 bg-dark-purple"
              name={t('CANCELLED')}
              amout={selectedFile?.process_report?.CE || 0}
            />
            {selectedFile?.process_report?.DENTAL && (
              <ColorCard
                className="mr-1 bg-info"
                name={t('DENTAL')}
                amout={selectedFile?.process_report?.DENTAL || 0}
              />
            )}
            <ColorCard
              className="mr-1 bg-warning"
              name={t('SPECIAL_ENROLLMENT')}
              amout={selectedFile?.process_report?.SP || 0}
            />
          </div>
          {selectedFile && (
            <div className="d-flex justify-content-end">
              <button
                type="button"
                onClick={handleView}
                className="ml-10 btn btn-bold d-inline-block btn-primary btn-label"
              >
                <span>
                  <i className="fa fa-eye" />
                </span>
                {t('VIEW')}
              </button>
              <button
                type="button"
                onClick={handleDownload}
                className="ml-10 btn btn-bold d-inline-block btn-primary btn-label"
              >
                <span>
                  <i className="fa fa-download" />
                </span>
                {t('DOWNLOAD')}
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default TAPTab;
