import React, { useState, useContext, useEffect } from 'react';
import { css } from '@emotion/core';
import { useTranslation } from 'react-i18next';
import SupplementsTab from '../SupplementsTab/SupplementsTab';
import StatusCodesTab from '../StatusCodesTab';
import GeneralTab from '../GeneralTab/GeneralTab';
import EmailTab from '../EmailsTab';
import { TabsContainer } from '../../styles/common/tabs';
import { SearchStateContext } from '../../context/SearchContext';
import TAPTab from '../TAPTab/index';
import MessagingTab from '../MessagingTab/index';

// Service
import { UserService } from '../../services/UserService';

const SchoolSettingsContent = () => {
  const { t } = useTranslation();
  const [tabSelected, setTabSelected] = useState('#general');
  const { setWithSearch } = useContext(SearchStateContext);

  useEffect(() => {
    const activeTab = document.querySelector('.nav-link.btn.active');
    setWithSearch(activeTab.dataset && activeTab.dataset.search === '1');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleActiveTab = (e) => {
    setWithSearch(e.target.dataset && e.target.dataset.search === '1');

    Array.from(e.currentTarget.children).forEach((li) => {
      const navLink = li.firstChild;
      if (navLink !== e.target && navLink.classList.contains('active')) {
        navLink.classList.remove('active');
      }
    });
  };

  return (
    <TabsContainer>
      <div
        css={css`
          box-shadow: none;
          z-index: 600;
        `}
        className="topbar header-inverse mt-150"
      >
        <div className="container">
          <div className="header-action">
            <nav className="nav" onClick={handleActiveTab}>
              <li className="nav-item">
                <button
                  className="nav-link btn active"
                  data-toggle="tab"
                  href="#general"
                  data-search="0"
                  onClick={(e) => setTabSelected(e.target.getAttribute('href'))}
                >
                  {t('GENERAL')}
                </button>
              </li>
              <li className="nav-item">
                <button
                  className="nav-link btn"
                  data-toggle="tab"
                  href="#status-codes"
                  data-search="0"
                  onClick={(e) => setTabSelected(e.target.getAttribute('href'))}
                >
                  {t('STATUS_CODES')}
                </button>
              </li>
              {/* <li className='nav-item'>
                <button
                  className='nav-link btn'
                  data-toggle='tab'
                  href='#providers'
                  data-search='1'
                >
                  {t('PROVIDERS')}
                </button>
              </li> */}
              <li className="nav-item">
                <button
                  className="nav-link btn"
                  data-toggle="tab"
                  href="#supplement-plan"
                  onClick={(e) => setTabSelected(e.target.getAttribute('href'))}
                  data-search="0"
                >
                  {t('SUPPLEMENT_PLAN')}
                </button>
              </li>
              <li className="nav-item">
                <button
                  className="nav-link btn"
                  data-toggle="tab"
                  href="#emails"
                  onClick={(e) => setTabSelected(e.target.getAttribute('href'))}
                  data-search="0"
                >
                  {t('EMAILS')}
                </button>
              </li>
              <li className="nav-item">
                <button
                  className="nav-link btn"
                  data-toggle="tab"
                  href="#tpa"
                  data-search="0"
                  onClick={(e) => setTabSelected(e.target.getAttribute('href'))}
                >
                  TPA
                </button>
              </li>
              {UserService.isAdmin() && (
                <li className="nav-item">
                  <button
                    className="nav-link btn"
                    data-toggle="tab"
                    href="#messaging"
                    data-search="0"
                    onClick={(e) =>
                      setTabSelected(e.target.getAttribute('href'))
                    }
                  >
                    {t('MESSAGING')}
                  </button>
                </li>
              )}
            </nav>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="tab-content">
          <div className="tab-pane fade active show" id="general">
            {tabSelected === '#general' && <GeneralTab />}
          </div>
          <div className="tab-pane fade" id="status-codes">
            <div className="container">
              {tabSelected === '#status-codes' && <StatusCodesTab />}
            </div>
          </div>
          {/* <div className='tab-pane fade' id='providers'>
            Providers
          </div> */}
          <div className="tab-pane fade" id="supplement-plan">
            {/* {tabSelected === '#supplement-plan' && <SupplementsTab selected={tabSelected === 'supplement-plan'} />} */}
            {tabSelected === '#supplement-plan' && <SupplementsTab />}
          </div>
          <div className="tab-pane fade" id="emails">
            {tabSelected === '#emails' && <EmailTab />}
          </div>

          <div className="tab-pane fade" id="tpa">
            {tabSelected === '#tpa' && <TAPTab />}
          </div>
          <div className="tab-pane fade" id="messaging">
            {tabSelected === '#messaging' && <MessagingTab />}
          </div>
        </div>
      </div>
    </TabsContainer>
  );
};

export default SchoolSettingsContent;
