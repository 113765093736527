import React, { useContext, useEffect, useState } from 'react';
import useAxios from 'axios-hooks';
import { css } from '@emotion/core';
import { useTranslation } from 'react-i18next';
import { StyledMain } from '../../styles/common/main';
import Pagination from '../Pagination';
import { SearchStateContext } from '../../context/SearchContext';
import Spinner from '../Spinner';
import { useHistory } from 'react-router-dom';
import { SchoolService } from '../../services/SchoolService';

const EnrollmentPeriodsTab = ({ schoolId: id }) => {
  const { t } = useTranslation();
  const { debouncedSearchKeyword } = useContext(SearchStateContext);
  const history = useHistory();

  const [{ data, loading }, executeGet] = useAxios(
    {
      url: `${process.env.REACT_APP_BASE_URL}api/v1/schools/${id}/periods`,
      method: 'GET',
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('jwt')}`
      },
      params: {
        'filter[query]': debouncedSearchKeyword
      }
    },
    {
      useCache: false
    }
  );

  const [managePeriods, setManagePeriods] = useState(false);

  const onPageChange = (selectedPage) => {
    executeGet({
      params: {
        page: selectedPage,
        'filter[query]': debouncedSearchKeyword
      }
    });
  };

  const navigateToNewPeriod = (periodId) => {
    history.push(`/schools/${id}/periods/${periodId}`);
  };

  useEffect(() => {
    SchoolService.canSchool('SCHOOLS:PERIODS', id).then((response) => {
      setManagePeriods(response);
    });
  }, []);

  return (
    <>
      <StyledMain className="container">
        <div className="card">
          <div className="card-body">
            {managePeriods && (
              <button
                css={css`
                  margin-top: -12px;
                `}
                onClick={() => history.push(`/schools/${id}/new-period`)}
                className="btn btn-primary btn-float"
              >
                <i className="ti-plus"></i>
              </button>
            )}
            {loading ? (
              <Spinner />
            ) : (
              <table className="table table-striped table-lg">
                <thead>
                  <tr>
                    <th>{t('YEAR')}</th>
                    <th>{t('TERM')}</th>
                    <th>{t('VALIDATOR')}</th>
                    <th>{t('PROVIDER')}</th>
                    <th>{t('STATUS')}</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {data &&
                    data.data.map((period) => {
                      return (
                        <tr key={period.id}>
                          <td>{period.academic_year}</td>
                          <td>{period.validation_period}</td>
                          <td>{period.validator}</td>
                          <td>{period.provider}</td>
                          <td>{period.status}</td>
                          <td width="13%">
                            <button
                              className="w-auto ml-auto btn btn-bold btn-block btn-primary btn-label"
                              data-open={period.id}
                              onClick={() => navigateToNewPeriod(period.id)}
                            >
                              <span>
                                <i className="ti-check"></i>
                              </span>
                              {t('VIEW')}
                            </button>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            )}
          </div>
        </div>
        {data && (
          <Pagination
            pages={data.last_page}
            onPageChange={onPageChange}
            {...data}
          />
        )}
      </StyledMain>
    </>
  );
};

export default EnrollmentPeriodsTab;
