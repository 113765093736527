import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import useAxios from 'axios-hooks';

import CardHeader from '../../CardHeader/index';

// Services
import DragInputFile from '../../DragInputFile/index';
import Preloader from '../../Preloader/index';

const UploadFileCard = React.memo(({ submission, refetch, setUpdatedData }) => {
  const { t } = useTranslation();
  const [{ response, loading, error }, executePost] = useAxios(
    {
      url: `${process.env.REACT_APP_BASE_URL}api/v1/submissions/${submission.id}/waivers/files`,
      method: 'POST',
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('jwt')}`,
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    },
    {
      manual: true
    }
  );

  useEffect(() => {
    if (response && !error) {
      refetch();
      setUpdatedData(response);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response, error]);

  const uploadFile = useCallback((type, file) => {
    if (!!file) {
      let formFile = new FormData();
      formFile.append('file', file);
      formFile.append('type', type);
      formFile.append('name', file.name);
      executePost({ data: formFile });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="card border border-light h-100">
      {loading && <Preloader />}
      <CardHeader title={t('UPLOAD_FILES')} />
      <div className="card-body">
        <div className="row">
          <div className="col-md-3">
            <DragInputFile
              fileMessage={t('FRONT_OF_CARD')}
              onChangeFile={uploadFile}
              type="CARD_FRONT"
            />
          </div>
          <div className="col-md-3">
            <DragInputFile
              fileMessage={t('BACK_OF_CARD')}
              onChangeFile={uploadFile}
              type="CARD_BACK"
            />
          </div>
          <div className="col-md-3">
            <DragInputFile
              fileMessage={t('LETTER_OF_ELIGIBILITY')}
              onChangeFile={uploadFile}
              type="LETTER"
            />
          </div>
          <div className="col-md-3">
            <DragInputFile
              fileMessage={t('POLICY_DOCUMENTS')}
              onChangeFile={uploadFile}
              type="POLICY"
            />
          </div>
        </div>
      </div>
    </div>
  );
});

export default UploadFileCard;
