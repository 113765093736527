const userHasAccess = (user, to) => {
  to = to.split('/')[1];
  // return true
  switch (user.type) {
    case 'admin':
      return true;
    // break;
    case 'super_admin':
      return true;
    // break;
    // user
    default:
      if (user.security_rights[to]) {
        return true;
      }
      return false;
  }
};

export default userHasAccess;
