/* eslint-disable no-console */
import React from 'react';
import { css } from '@emotion/core';

const values = [
  { label: 'WAIVER', value: 'waiver' },
  { label: 'SHIP', value: 'ship' },
  { label: 'NO ACTION', value: 'no_action' }
];

const TypeFilter = ({ setValue, value }) => {
  return (
    <div
      className="form-group do-float"
      css={css`
        .form-type-material .bootstrap-select .dropdown-toggle:focus {
          background-color: transparent !important;
        }
      `}
    >
      <select
        type="text"
        name="type"
        id="type"
        className="form-control bg-transparent"
        data-provide="selectpicker"
        onChange={(e) => setValue(e.target.value)}
        placeholder="Nothing selected"
        value={value}
      >
        <option value="" disabled>
          Nothing selected
        </option>

        {values.map((item, index) => (
          <option
            key={item.value}
            value={item.value}
            id={`option-${item}--${index}`}
            data-id={item}
            data-name={item}
          >
            {item.label}
          </option>
        ))}
      </select>

      <label htmlFor="type" className="label-floated">
        Types
      </label>
    </div>
  );
};

export default TypeFilter;
