/* eslint-disable no-console */
import React, {
  useContext,
  useState,
  useEffect,
  useCallback,
  useMemo
} from 'react';
import moment from 'moment';
import useAxios from 'axios-hooks';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/core';
import CurrencyFormat from 'react-currency-format';
import { useLazyQuery } from '@apollo/client';
import { StudentStateContext } from '../../../context/StudentContext';
import states from '../../../data/states.json';
import { useAuthorizedAxios } from '../../../hooks/use-authorizedaxios';

// Components
import LockFieldsModal from '../../LockFieldsModal';
import SHIPModal from '../SHIPModal';
import WaiverModal from '../WaiverModal';
import SubmissionCard from '../../SubmissionCard';
import NoActionSubmissionCard from '../../SubmissionCard/NoActionSubmissionCard';
import Spinner from '../../Spinner/index';
import AccordionCard from '../../AccordionCard';
// Services
// eslint-disable-next-line import/named
import { UserService } from '../../../services/UserService';
import Preloader from '../../Preloader/index';
import { formatDateToAPI } from '../../../utils/common';
import NotificationAlert from '../../NotificationAlert';
import { SchoolService } from '../../../services/SchoolService';
// Queries
import { students_eligibility_by_enrollment_period } from '../../../data/students/query';
import { enrollmentPeriodsBySchool } from '../../../data/enrollmentPeriods/query';
import EnrollmentModal from './EnrollmentModal/EnrollmentModal';

// eslint-disable-next-line no-unused-vars
const ProfileTab = ({ school, reloadUser }) => {
  const { t } = useTranslation();
  const [lockedFields, setLockedFields] = useState([]);
  const [student, setStudent] = useContext(StudentStateContext);
  const [lockedModalOpen, setLockedModalOpen] = useState(false);
  const [showSHIPModal, setShowSHIPModal] = useState(false);
  const [displayWaiverModal, setDisplayWaiverModal] = useState(false);
  const [selectedSubmission, setSelectedSubmission] = useState(null);
  const [selectedSubmissionId, setSelectedSubmissionId] = useState();
  const [displayEnrollModal, setDisplayEnrollModal] = useState(false);
  const [profilePermission, setProfilePermission] = useState(false);
  const [lockFieldsPermission, setlockFieldsPermission] = useState(false);
  const [studentSubPermission, setstudentSubPermission] = useState(false);
  const [enrollOptions, setEnrollOptions] = useState([]);
  const [mask, setMask] = useState({
    format: '############',
    mask: ['X', 'X', 'X', 'X', 'X', 'X', 'X', 'X', 'X', 'X', 'X', 'X']
  });

  const [{ data, loading }, getSubmissions] = useAxios(
    {
      url: `${process.env.REACT_APP_BASE_URL}api/v1/students/${student.id}/submissions`,
      method: 'GET',
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('jwt')}`
      }
    },
    {
      manual: true
    }
  );

  const [
    { data: historicalSubmissions, loading: loadingHistoricalSubmissions },
    getHistoricalSubmissions
  ] = useAxios(
    {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('jwt')}`
      }
    },
    {
      manual: true
    }
  );
  // Multi roles service

  SchoolService.rolePermission(
    'STUDENTS:PROFILE',
    student.school_id,
    setProfilePermission
  );
  SchoolService.rolePermission(
    'STUDENTS:LOCK_FIELDS',
    student.school_id,
    setlockFieldsPermission
  );
  SchoolService.rolePermission(
    'STUDENTS_SUBMISSIONS',
    student.school_id,
    setstudentSubPermission
  );
  const [
    getStudentEligibilityByPeriods,
    { data: dataStudentEligibilityByPeriods, refetch: refetchStudentsPeriods }
  ] = useLazyQuery(students_eligibility_by_enrollment_period, {
    variables: { studentId: student.id },
    fetchPolicy: 'network-only'
  });

  const [getPeriodsBySchool, { data: dataPeriodsBySchool }] = useLazyQuery(
    enrollmentPeriodsBySchool,
    {
      variables: { schoolId: student.school_id }
    }
  );

  useEffect(() => {
    getHistoricalSubmissions({
      url: `${process.env.REACT_APP_BASE_URL}api/v1/students/${student.id}/submissions`
    });
    getStudentEligibilityByPeriods();
    getPeriodsBySchool();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [student]);

  const [{ data: updatedUser, error, loading: loadingUser }, executeUpdate] =
    useAuthorizedAxios({
      url: `${process.env.REACT_APP_BASE_URL}api/v1/students/${student.id}`,
      method: 'POST',
      manual: true
    });

  const [date, setDate] = useState('');
  const [formData, setFormData] = useState({
    firstName: student.first_name || '',
    emailAddress: student.email_address || '',
    streetAddress1: student.street_address || '',
    lastName: student.last_name || '',
    streetAddress2: student.street_address_2 || '',
    studentId: student.student_id || '',
    gender: student.gender || '',
    city: student.city || '',
    state: student.state || '',
    phone: student.phone || '',
    postalCode: student.postal_code || '',
    schoolId: student && student.school.id,
    alternateId: student.alternate_id || '',
    classification: student.classification || '',
    studentLevel: student.student_level || '',
    studentType: student.student_type || ''
  });

  const studentPeriodsInfo = React.useMemo(() => {
    let studentPeriods = [];
    if (
      dataPeriodsBySchool &&
      dataPeriodsBySchool.enrollment_periods &&
      historicalSubmissions
    ) {
      dataPeriodsBySchool.enrollment_periods.forEach((period) => {
        let studentsEligibility;
        if (
          dataStudentEligibilityByPeriods &&
          dataStudentEligibilityByPeriods.students_eligibility_by_enrollment_period
        ) {
          //Filter elegiblity records for period
          const studentEligibilityArr =
            dataStudentEligibilityByPeriods.students_eligibility_by_enrollment_period.filter(
              ({ enrollment_period_id }) => enrollment_period_id === period.id
            );

          //If more than one eligibility for same period pick first
          if (studentEligibilityArr.length > 0) {
            // eslint-disable-next-line prefer-destructuring
            studentsEligibility = studentEligibilityArr[0];
          } else {
            return;
          }
        }

        if (!studentsEligibility) {
          studentsEligibility = {
            enrollment_period_id: period.id,
            id: undefined,
            school_id: period.school_id,
            segment_1: undefined,
            segment_1_locked: undefined,
            segment_2: undefined,
            segment_2_locked: undefined,
            segment_3: undefined,
            segment_3_locked: undefined,
            segment_4: undefined,
            segment_4_locked: undefined,
            student_id: student.id,
            segment_1_type: undefined,
            segment_2_type: undefined,
            segment_1_level: undefined,
            segment_2_level: undefined
          };
        }

        const submissions = historicalSubmissions.filter(
          ({ enrollment_period_id }) => enrollment_period_id === period.id
        );

        const studentPeriod = {
          period,
          studentsEligibility,
          submissions
        };

        studentPeriods.push(studentPeriod);
      });
    }
    return studentPeriods;
  }, [
    historicalSubmissions,
    dataStudentEligibilityByPeriods,
    dataPeriodsBySchool,
    student
  ]);

  const handleGetSubmissions = useCallback(() => {
    getSubmissions();
    getHistoricalSubmissions({
      url: `${process.env.REACT_APP_BASE_URL}api/v1/students/${student.id}/submissions`
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [student]);

  useEffect(() => {
    if (student) {
      setFormData({
        firstName: student.first_name || '',
        emailAddress: student.email_address || '',
        streetAddress1: student.street_address || '',
        lastName: student.last_name || '',
        streetAddress2: student.street_address_2 || '',
        studentId: student.student_id || '',
        gender: student.gender || '',
        city: student.city || '',
        state: student.state || '',
        phone: student.phone || '',
        postalCode: student.postal_code || '',
        schoolId: student && student.school.id,
        alternateId: student.alternate_id || '',
        classification: student.classification || '',
        studentLevel: student.student_level || '',
        studentType: student.student_type || ''
      });
      setDate(moment(student.date_of_birth).format('MMDDYYYY'));
      setLockedFields(student.lock_field_config);
    }
    handleGetSubmissions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [student]);

  useEffect(() => {
    if (updatedUser && !error) {
      setStudent((s) => ({ ...s, ...updatedUser }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updatedUser, error]);

  useEffect(() => {
    if (
      selectedSubmissionId &&
      ((data && showSHIPModal) ||
        (historicalSubmissions && showSHIPModal) ||
        (data && displayWaiverModal) ||
        (historicalSubmissions && displayWaiverModal))
    ) {
      const updatedSubmission = data.find(
        (sub) => sub.id === selectedSubmissionId
      );
      if (updatedSubmission) {
        setSelectedSubmission({
          ...updatedSubmission,
          student: {
            first_name: updatedSubmission.first_name,
            last_name: updatedSubmission.last_name,
            student_id: updatedSubmission.campus_id,
            id: updatedSubmission.student_id,
            gender: updatedSubmission.gender,
            phone: updatedSubmission.phone,
            email_address: updatedSubmission.email_address,
            date_of_birth: updatedSubmission.date_of_birth
          }
        });
      } else {
        const updatedHistoricalSubmission = historicalSubmissions.find(
          (sub) => sub.id === selectedSubmissionId
        );
        if (updatedHistoricalSubmission) {
          setSelectedSubmission({
            ...updatedHistoricalSubmission,
            student: {
              first_name: updatedHistoricalSubmission.first_name,
              last_name: updatedHistoricalSubmission.last_name,
              student_id: updatedHistoricalSubmission.campus_id,
              id: updatedHistoricalSubmission.student_id,
              gender: updatedHistoricalSubmission.gender,
              phone: updatedHistoricalSubmission.phone,
              email_address: updatedHistoricalSubmission.email_address,
              date_of_birth: updatedHistoricalSubmission.date_of_birth
            }
          });
        }
      }
    }
  }, [
    data,
    historicalSubmissions,
    displayWaiverModal,
    showSHIPModal,
    selectedSubmissionId
  ]);

  const handleFormDataChange = (e) => {
    if (
      e.currentTarget.name === 'streetAddress1' ||
      e.currentTarget.name === 'streetAddress2'
    ) {
      e.currentTarget.value = e.currentTarget.value.replaceAll(':', '');
    }

    setFormData({
      ...formData,
      [e.currentTarget.name]: e.currentTarget.value
    });
  };
  const handleFormSubmit = async (e) => {
    e.preventDefault();
    await executeUpdate({
      params: {
        firstName: formData.firstName,
        emailAddress: formData.emailAddress,
        streetAddress1: formData.streetAddress1.replaceAll(':', ''),
        lastName: formData.lastName,
        phone: formData.phone,
        streetAddress2: formData.streetAddress2.replaceAll(':', ''),
        studentId: formData.studentId,
        gender: formData.gender,
        city: formData.city,
        state: formData.state,
        postalCode: formData.postalCode,
        dateOfBirth: formatDateToAPI(date),
        schoolId: formData.schoolId,
        alternateId: formData.alternateId,
        classification: formData.classification,
        studentLevel: formData.studentLevel,
        studentType: formData.studentType
      }
    });
  };

  const handleOpenSubmissionModal = useCallback((submission) => {
    setSelectedSubmissionId(submission.id);
    if (submission.submission_type === 'Waiver Application') {
      setSelectedSubmission({
        ...submission,
        student: {
          first_name: submission.first_name,
          last_name: submission.last_name,
          student_id: submission.campus_id,
          id: submission.student_id,
          gender: submission.gender,
          phone: submission.phone,
          email_address: submission.email_address,
          date_of_birth: submission.date_of_birth
        }
      });
      setDisplayWaiverModal(true);
    } else {
      setSelectedSubmission({
        ...submission,
        student: {
          first_name: submission.first_name,
          last_name: submission.last_name,
          student_id: submission.campus_id,
          id: submission.student_id,
          gender: submission.gender,
          phone: submission.phone,
          email_address: submission.email_address,
          date_of_birth: submission.date_of_birth
        }
      });
      setShowSHIPModal(true);
    }
  }, []);

  const handleRefetch = () => {
    handleGetSubmissions();
    if (student.current_period) {
      getHistoricalSubmissions({
        url: `${process.env.REACT_APP_BASE_URL}api/v1/students/${student.id}/submissions?omit=${student.current_period.id}`
      });
    }
  };
  // eslint-disable-next-line consistent-return
  const renderCurrentCards = useCallback(() => {
    if (student) {
      if (historicalSubmissions) {
        return (
          <div className="d-flex flex-wrap">
            {studentPeriodsInfo.map((studentPeriod, index) => (
              <AccordionCard
                key={index}
                title={studentPeriod.period.academic_year}
                studentPeriod={studentPeriod}
                refetchPeriods={refetchStudentsPeriods}
                refetchSubmissions={handleRefetch}
                startOpen={index === 0}
                FT={studentPeriod.studentsEligibility.segment_1_type}
                FL={studentPeriod.studentsEligibility.segment_1_level}
                ST={studentPeriod.studentsEligibility.segment_2_type}
                SL={studentPeriod.studentsEligibility.segment_2_level}
              >
                <div className="d-flex flex-wrap">
                  {studentPeriod.submissions.length === 0 && (
                    <NoActionSubmissionCard
                      segment={
                        student &&
                        student.current_period &&
                        student.current_period.segment_label
                      }
                    />
                  )}
                  {studentPeriod.submissions.length > 0 &&
                    studentPeriod.submissions.map((submission) => {
                      const segmentElegibility = `segment_${
                        submission.segment.sequence + 1
                      }`;
                      return (
                        <div key={submission.id} className="mr-4">
                          <SubmissionCard
                            submission={submission}
                            onClick={handleOpenSubmissionModal}
                            elegibilityStatus={
                              studentPeriod.studentsEligibility[
                                segmentElegibility
                              ]
                            }
                          />
                        </div>
                      );
                    })}
                </div>
              </AccordionCard>
            ))}
          </div>
        );
      }
      if (!student.has_current_submission) {
        return (
          <NoActionSubmissionCard
            segment={
              student &&
              student.current_period &&
              student.current_period.segment_label
            }
          />
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [studentPeriodsInfo, data, historicalSubmissions]);

  const handleCloseSubmissionModal = useCallback(() => {
    setSelectedSubmission(null);
    setSelectedSubmissionId(undefined);
    if (displayWaiverModal) {
      setDisplayWaiverModal(false);
    } else {
      setShowSHIPModal(false);
    }
  }, [displayWaiverModal]);

  const studentIdHasMask = useMemo(
    () =>
      school &&
      school.student_id_format_mask &&
      school.student_id_format_mask.length &&
      school.student_id_format_mask.length > 0,
    [school]
  );

  const isFormDisabled = useMemo(
    () => !profilePermission || formData.studentId.length !== mask.mask.length,
    [mask, formData]
  );

  useEffect(() => {
    if (studentIdHasMask) {
      let format = '';
      const auxMask = [];
      for (
        let index = 0;
        index < school.student_id_format_mask.length;
        index += 1
      ) {
        format += '#';
        auxMask.push('X');
      }
      setMask({
        format,
        mask: auxMask
      });
    }
  }, [school, studentIdHasMask]);

  const handleShowEnrollmentModal = () => {
    setDisplayEnrollModal(true);
  };

  useEffect(() => {
    if (dataPeriodsBySchool && dataPeriodsBySchool.enrollment_periods) {
      let parsedOptions = dataPeriodsBySchool.enrollment_periods.map(
        (period) => {
          return {
            value: period.id,
            label: period.academic_year
          };
        }
      );

      if (
        dataStudentEligibilityByPeriods?.students_eligibility_by_enrollment_period
      ) {
        for (
          let i = 0;
          i < dataPeriodsBySchool.enrollment_periods.length;
          i++
        ) {
          for (
            let z = 0;
            z <
            dataStudentEligibilityByPeriods
              ?.students_eligibility_by_enrollment_period.length;
            z++
          ) {
            if (
              dataStudentEligibilityByPeriods
                .students_eligibility_by_enrollment_period[z]
                .enrollment_period_id ==
              dataPeriodsBySchool.enrollment_periods[i].id
            ) {
              parsedOptions = parsedOptions.filter((option) => {
                return (
                  option.value !== dataPeriodsBySchool.enrollment_periods[i].id
                );
              });
            }
          }
        }
      }
      setEnrollOptions(parsedOptions);
    }
  }, [dataPeriodsBySchool, dataStudentEligibilityByPeriods]);
  return (
    <>
      {enrollOptions.length > 0 && (
        <EnrollmentModal
          display={displayEnrollModal}
          setDisplay={setDisplayEnrollModal}
          options={enrollOptions}
          student={student}
          studentElegibilityByPeriod={dataStudentEligibilityByPeriods}
          getStudentEligibilityByPeriods={() =>
            getStudentEligibilityByPeriods()
          }
        />
      )}
      {lockFieldsPermission && lockedModalOpen && (
        <LockFieldsModal
          setDisplay={setLockedModalOpen}
          display={lockedModalOpen}
          fields={lockedFields}
          studentId={student.id}
          onClose={(locks) => setLockedFields(locks)}
        />
      )}
      {(loadingUser ||
        ((loading || loadingHistoricalSubmissions) &&
          (showSHIPModal || displayWaiverModal))) && <Preloader />}

      {selectedSubmission && displayWaiverModal && (
        <WaiverModal
          display={displayWaiverModal}
          setDisplay={handleCloseSubmissionModal}
          submission={selectedSubmission}
          refetch={handleRefetch}
          school={school}
        />
      )}

      {selectedSubmission && showSHIPModal && (
        <SHIPModal
          display={showSHIPModal}
          setDisplay={handleCloseSubmissionModal}
          submission={selectedSubmission}
          refetch={handleRefetch}
        />
      )}

      <div
        css={css`
          .red-border {
            border-left: 2px solid #f96868;
          }
          .green-border {
            border-left: 2px solid #39c377;
          }
        `}
      >
        <div className={`card card-shadowed`}>
          <div className="card-body">
            <NotificationAlert
              data={updatedUser}
              message={t('NOTIFICATION_STUDENT_UPDATE_SUCCESS')}
            />
            <button
              type="button"
              disabled={!lockFieldsPermission}
              onClick={() => setLockedModalOpen(true)}
              className={`btn btn-bold d-block ml-auto btn-warning btn-label mb-5 ${
                lockFieldsPermission ? '' : 'disabled text-white'
              }`}
            >
              <span>
                <i className="fa fa-lock" />
              </span>
              {t('LOCK_FIELDS')}
            </button>

            <form
              onSubmit={handleFormSubmit}
              className="form-type-material row"
            >
              <div className="col-md-4">
                <div className="input-group flex-nowrap mb-1">
                  <div className="input-group-input do-float">
                    <input
                      type="text"
                      name="firstName"
                      id="firstName"
                      className="form-control"
                      placeholder={t('FIRST_NAME')}
                      value={formData.firstName}
                      onChange={handleFormDataChange}
                      readOnly={!profilePermission}
                      disabled={!profilePermission}
                    />
                    <label htmlFor="firstName" className="label-floated">
                      {t('FIRST_NAME')}
                    </label>
                  </div>
                  {lockedFields && lockedFields.includes('firstName') && (
                    <div className="input-group-append">
                      <i className="fa fa-lock p-10" />
                    </div>
                  )}
                </div>
                <div className="input-group flex-nowrap mb-1">
                  <div className="input-group-input do-float">
                    <input
                      type="text"
                      name="lastName"
                      id="lastName"
                      className="form-control"
                      placeholder={t('LAST_NAME')}
                      value={formData.lastName}
                      onChange={handleFormDataChange}
                      readOnly={!profilePermission}
                      disabled={!profilePermission}
                    />
                    <label htmlFor="lastName" className="label-floated">
                      {t('LAST_NAME')}
                    </label>
                  </div>
                  {lockedFields && lockedFields.includes('lastName') && (
                    <div className="input-group-append">
                      <i className="fa fa-lock p-10" />
                    </div>
                  )}
                </div>
                <div className="input-group flex-nowrap mb-1">
                  <div className="input-group-input do-float">
                    <CurrencyFormat
                      id="studentId"
                      name="studentId"
                      value={formData.studentId}
                      onValueChange={(val) =>
                        handleFormDataChange({
                          currentTarget: {
                            name: 'studentId',
                            value: val.value
                          }
                        })
                      }
                      className="form-control"
                      format={mask.format}
                      mask={mask.mask}
                      readOnly={!profilePermission}
                      disabled={!profilePermission}
                    />
                    <label htmlFor="studentId" className="label-floated">
                      {t('STUDENT_ID')}
                    </label>
                    {lockedFields && lockedFields.includes('studentId') && (
                      <div className="input-group-append">
                        <i className="fa fa-lock p-10" />
                      </div>
                    )}
                  </div>
                </div>
                <div className="input-group flex-nowrap mb-1">
                  <div className="input-group-input do-float">
                    <input
                      type="text"
                      name="alternateId"
                      id="alternateId"
                      className="form-control"
                      placeholder={t('ALTERNATE_ID')}
                      value={formData.alternateId}
                      onChange={handleFormDataChange}
                      readOnly={!profilePermission}
                      disabled={!profilePermission}
                    />
                    <label htmlFor="alternateId" className="label-floated">
                      {t('ALTERNATE_ID')}
                    </label>
                  </div>
                  {lockedFields && lockedFields.includes('alternateId') && (
                    <div className="input-group-append">
                      <i className="fa fa-lock p-10" />
                    </div>
                  )}
                </div>
              </div>
              <div className="col-md-4">
                <div className="input-group flex-nowrap mb-1">
                  <div className="input-group-input do-float">
                    <input
                      type="email"
                      name="emailAddress"
                      id="emailAddress"
                      className="form-control"
                      placeholder={t('ENTER_VALUE')}
                      value={formData.emailAddress}
                      onChange={handleFormDataChange}
                      readOnly={!profilePermission}
                      disabled={!profilePermission}
                    />
                    <label htmlFor="emailAddress" className="label-floated">
                      {t('EMAIL_ADDRESS')}
                    </label>
                  </div>
                  {lockedFields && lockedFields.includes('emailAddress') && (
                    <div className="input-group-append">
                      <i className="fa fa-lock p-10" />
                    </div>
                  )}
                </div>
                <div className="input-group flex-nowrap mb-1">
                  <div className="input-group-input do-float">
                    <input
                      type="phone"
                      name="phone"
                      id="phone"
                      className="form-control"
                      placeholder="888-888-8888"
                      value={formData.phone}
                      onChange={handleFormDataChange}
                      readOnly={!profilePermission}
                      disabled={!profilePermission}
                    />
                    <label htmlFor="phone" className="label-floated">
                      {t('PHONE')}
                    </label>
                  </div>
                  {lockedFields && lockedFields.includes('phone') && (
                    <div className="input-group-append">
                      <i className="fa fa-lock p-10" />
                    </div>
                  )}
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="input-group flex-nowrap mb-1">
                      <div className="input-group-input do-float">
                        <select
                          name="gender"
                          id="gender"
                          data-provide="selectpicker"
                          className="form-control w-100"
                          value={formData.gender}
                          onChange={handleFormDataChange}
                          disabled={!profilePermission}
                        >
                          <option disabled value="">
                            {t('SELECT')}
                          </option>
                          <option value="M">{t('MALE')}</option>
                          <option value="F">{t('FEMALE')}</option>
                          <option value="X">{t('NEUTRAL')}</option>
                        </select>
                        <label htmlFor="gender" className="label-floated">
                          {t('GENDER')}
                        </label>
                      </div>
                      {lockedFields && lockedFields.includes('gender') && (
                        <div className="input-group-append">
                          <i className="fa fa-lock p-10" />
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input-group flex-nowrap mb-1">
                      <div className="input-group-input do-float">
                        <CurrencyFormat
                          id="dateOfBirth"
                          name="dateOfBirth"
                          value={date}
                          onValueChange={(val) => setDate(val.value)}
                          className="form-control"
                          format="##/##/####"
                          placeholder="MM/DD/YYYY"
                          mask={['M', 'M', 'D', 'D', 'Y', 'Y', 'Y', 'Y']}
                          readOnly={!profilePermission}
                        />
                        <label htmlFor="dateOfBirth" className="label-floated">
                          {t('DATE_OF_BIRTH')}
                        </label>
                      </div>
                      {lockedFields && lockedFields.includes('dateOfBirth') && (
                        <div className="input-group-append">
                          <i className="fa fa-lock p-10" />
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="input-group flex-nowrap mb-1 col-md-12">
                    <div className="input-group-input do-float">
                      <input
                        type="text"
                        name="classification"
                        id="classification"
                        className="form-control"
                        placeholder={t('CLASSIFICATION')}
                        value={formData.classification}
                        onChange={handleFormDataChange}
                        readOnly={!profilePermission}
                      />
                      <label htmlFor="classification" className="label-floated">
                        {t('CLASSIFICATION')}
                      </label>
                      {lockedFields &&
                        lockedFields.includes('classification') && (
                          <div className="input-group-append">
                            <i className="fa fa-lock p-10" />
                          </div>
                        )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="input-group flex-nowrap mb-1">
                  <div className="input-group-input do-float">
                    <input
                      type="text"
                      name="streetAddress1"
                      id="streetAddress1"
                      className="form-control"
                      placeholder={t('ENTER_VALUE')}
                      value={formData.streetAddress1}
                      onChange={handleFormDataChange}
                      readOnly={!profilePermission}
                      disabled={!profilePermission}
                    />
                    <label htmlFor="streetAddress1" className="label-floated">
                      {`${t('STREET_ADDRESS')}1`}
                    </label>
                  </div>
                  {lockedFields && lockedFields.includes('streetAddress1') && (
                    <div className="input-group-append">
                      <i className="fa fa-lock p-10" />
                    </div>
                  )}
                </div>
                <div className="input-group flex-nowrap mb-1">
                  <div className="input-group-input do-float">
                    <input
                      type="text"
                      name="streetAddress2"
                      id="streetAddress2"
                      className="form-control"
                      placeholder={t('ENTER_VALUE')}
                      value={formData.streetAddress2}
                      onChange={handleFormDataChange}
                      readOnly={!profilePermission}
                      disabled={!profilePermission}
                    />
                    <label htmlFor="streetAddress2" className="label-floated">
                      {`${t('STREET_ADDRESS')}2`}
                    </label>
                  </div>
                  {lockedFields && lockedFields.includes('streetAddress2') && (
                    <div className="input-group-append">
                      <i className="fa fa-lock p-10" />
                    </div>
                  )}
                </div>
                <div className="input-group flex-nowrap mb-1">
                  <div className="input-group-input do-float">
                    <input
                      type="text"
                      name="city"
                      id="city"
                      className="form-control"
                      placeholder={t('ENTER_VALUE')}
                      value={formData.city}
                      onChange={handleFormDataChange}
                      readOnly={!profilePermission}
                      disabled={!profilePermission}
                    />
                    <label htmlFor="city" className="label-floated">
                      {`${t('CITY')}`}
                    </label>
                  </div>
                  {lockedFields && lockedFields.includes('city') && (
                    <div className="input-group-append">
                      <i className="fa fa-lock p-10" />
                    </div>
                  )}
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="input-group flex-nowrap mb-1">
                      <div
                        css={css`
                          .dropdown-menu.show {
                            min-width: 100% !important;
                          }
                        `}
                        className="input-group-input do-float"
                      >
                        <select
                          name="state"
                          id="state"
                          data-provide="selectpicker"
                          data-live-search="true"
                          className="form-control w-100"
                          value={formData.state}
                          onChange={handleFormDataChange}
                          disabled={!profilePermission}
                        >
                          <option disabled value="">
                            {t('SELECT')}
                          </option>
                          {Object.keys(states).map((state) => (
                            <option key={state} value={state}>
                              {states[state]}
                            </option>
                          ))}
                        </select>
                        <label htmlFor="state" className="label-floated">
                          {`${t('STATE')}`}
                        </label>
                      </div>
                      {lockedFields && lockedFields.includes('state') && (
                        <div className="input-group-append">
                          <i className="fa fa-lock p-10" />
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input-group flex-nowrap mb-1">
                      <div className="input-group-input do-float">
                        <input
                          type="text"
                          name="postalCode"
                          id="postalCode"
                          className="form-control"
                          placeholder={t('ENTER_VALUE')}
                          value={formData.postalCode}
                          onChange={handleFormDataChange}
                          readOnly={!profilePermission}
                        />
                        <label htmlFor="postalCode" className="label-floated">
                          {`${t('ZIP_CODE')}`}
                        </label>
                      </div>
                      {lockedFields && lockedFields.includes('postalCode') && (
                        <div className="input-group-append">
                          <i className="fa fa-lock p-10" />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-12">
                <button
                  disabled={isFormDisabled}
                  className={`btn btn-bold d-block ml-auto btn-primary btn-label my-20 ${
                    isFormDisabled ? 'disabled' : ''
                  }`}
                  type="submit"
                >
                  <span>
                    <i className="ti-check" />
                  </span>
                  {t('UPDATE')}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      {student && studentSubPermission && (
        <>
          {loading ? (
            <Spinner />
          ) : (
            <>
              <button
                className={`btn btn-bold w-auto btn-block btn-primary btn-label`}
                style={{ marginBottom: '30px' }}
                type="submit"
                onClick={handleShowEnrollmentModal}
                disabled={enrollOptions.length < 1 ? true : false}
              >
                <label>
                  <i className="ti-plus"></i>
                </label>
                {t('ADD_ENROLLMENT')}
              </button>
              {renderCurrentCards()}
            </>
          )}
        </>
      )}
    </>
  );
};

export default ProfileTab;
