import React, { useEffect, useCallback, useState, useContext } from 'react';
import useAxios from 'axios-hooks';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import Spinner from '../Spinner/index';
import { useAuthorizedAxios } from '../../hooks/use-authorizedaxios';
import Preloader from '../Preloader/index';
import SortArrows from '../SortArrows/index';
import CardHeader from '../CardHeader';
import WaiverModal from '../StudentContent/WaiverModal/index';
import { DashboardStateContext } from '../../context/DashboardContext';
import { AlertService } from '../../services/AlertService';
import SubmissionRow from './SubmissionRow';
import ValidationConfirmationModal from './ValidationConfirmationModal';

const AuditingTable = ({ provider, status }) => {
  const [, setDashboard] = useContext(DashboardStateContext);
  const [defaultLoading, setDefaultLoading] = useState(true);
  const [selectedSubmission, setSelectedSubmission] = useState();
  const [submissionForm, setSubmissionForm] = useState();
  const [sortedBy, setSortedBy] = useState({
    column: '',
    asc: true
  });
  const [dataToDisplay, setDataToDisplay] = useState([]);
  const [search, setSearch] = useState();
  const [selectedInsurance, setSelectedInsurance] = useState('');
  const [selectedSchool, setSelectedSchool] = useState('');
  const [displayWaiverModal, setDisplayWaiverModal] = useState(false);
  const [defaultData, setDefaultData] = useState([]);
  const [displayValidationModal, setDisplayValidationModal] = useState(false);
  const [validatorMessage, setValidatorMessage] = useState('');
  const [count, setCount] = useState(0);

  const { t } = useTranslation();
  const [{ data, loading }, getAuditors] = useAxios(
    {
      url: `${process.env.REACT_APP_BASE_URL}api/v1/dashboard/auditors/${provider}`,
      method: 'GET',
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('jwt')}`
      }
    },
    {
      manual: true
    }
  );
  const [{ loading: loadingProviders, data: dataProviders }] =
    useAuthorizedAxios({
      method: 'GET',
      url: `${process.env.REACT_APP_BASE_URL}api/v1/providers`
    });
  const [
    { loading: loadingValidate, data: dataValidate, error: errorValidate },
    executeValidate
  ] = useAuthorizedAxios({
    url: `${process.env.REACT_APP_BASE_URL}api/v1/submissions/${
      selectedSubmission ? selectedSubmission.id : ''
    }/waivers/validate`,
    method: 'POST',
    manual: true
  });
  const [{ loading: loadingUpdate, data: dataUpdate }, executeUpdate] =
    useAuthorizedAxios({
      url: `${process.env.REACT_APP_BASE_URL}api/v1/submissions/${
        selectedSubmission ? selectedSubmission.id : ''
      }/waivers`,
      method: 'POST',
      manual: true
    });
  const [{ loading: loadingPostStatus, data: dataPostStatus }, postStatus] =
    useAuthorizedAxios({
      method: 'POST',
      manual: true
    });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    getAuditors({
      params: {
        status
      }
    });
  }, []);

  useEffect(() => {
    if (dataUpdate || dataPostStatus) {
      AlertService.showSuccess(t('SAVE_SUCCESS'));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataUpdate, dataPostStatus]);

  useEffect(() => {
    if (dataUpdate) {
      const index = defaultData.findIndex(
        (s) => s.id === selectedSubmission.id
      );
      const updatedData = defaultData;
      updatedData[index] = {
        ...updatedData[index],
        ...dataUpdate.submission,
        student: {
          first_name: updatedData[index].first_name,
          last_name: updatedData[index].last_name,
          student_id: updatedData[index].campus_id,
          id: updatedData[index].student_id,
          gender: updatedData[index].gender,
          phone: updatedData[index].phone,
          email_address: updatedData[index].email_address,
          date_of_birth: updatedData[index].date_of_birth
        },
        period: { academic_year: data.academic_year },
        segment: { segment_label: data.segment_label },
        school: {
          supplement_plan_enabled: updatedData[index].supplement_plan_enabled
        }
      };
      setSelectedSubmission(updatedData[index]);
      setDefaultData(updatedData);
      setCount((c) => c + 1);
      setSubmissionForm((s) => ({ ...s, dirty: false }));
    }
  }, [dataUpdate]);

  useEffect(() => {
    if (dataPostStatus) {
      setTimeout(() => {
        setDefaultData((d) => [
          ...d.filter((item) => item.id !== selectedSubmission.id)
        ]);
        setCount((c) => c + 1);
      }, 3000);
    }
  }, [dataPostStatus, selectedSubmission]);

  useEffect(() => {
    if (dataValidate && !loadingValidate && !errorValidate) {
      setValidatorMessage(
        dataValidate.status === 'AP'
          ? t('WAIVER_APPROVED')
          : t('WAIVER_NOT_APPROVED')
      );
      setDisplayValidationModal(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataValidate, errorValidate, loadingValidate]);

  useEffect(() => {
    if (data) {
      setDataToDisplay(data.data);
      setDefaultData(data.data);
      setCount((c) => c + 1);
      if (defaultLoading) {
        setDefaultLoading(false);
      }
    }
  }, [data, defaultLoading]);

  const handleFormChange = useCallback((key, value) => {
    setSubmissionForm((f) => ({
      ...f,
      dirty: true,
      [key]: value
    }));
  }, []);

  const handleSelectChanged = useCallback((e) => {
    const option = document.querySelector(`#${e.target.value}`);
    const { id, name, payerid } = option.dataset;
    setSubmissionForm((s) => ({
      ...s,
      dirty: true,
      insuranceId: id,
      insuranceName: name,
      payerId: payerid
    }));
  }, []);

  const handleValidate = useCallback(() => {
    executeValidate({
      url: `${process.env.REACT_APP_BASE_URL}api/v1/submissions/${selectedSubmission.id}/waivers/validate`
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSubmission]);

  const handleView = useCallback(() => {
    setDisplayWaiverModal(true);
  }, []);

  const handleSendToPR = useCallback(() => {
    postStatus({
      url: `${process.env.REACT_APP_BASE_URL}api/v1/submissions/${selectedSubmission.id}/waiver/status`,
      params: {
        status: 'PR',
        note: 'Auditor Changed to PR',
        approvalException: 0,
        sendEmail: 0
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSubmission]);

  const submitForm = useCallback(() => {
    const params = {
      memberId: submissionForm.memberId,
      groupNumber: submissionForm.groupId,
      memberSupportPhone: submissionForm.memberSupport
    };

    if (!!submissionForm.payerId) {
      params.payerId = submissionForm.payerId;
    }
    if (!!submissionForm.insuranceId) {
      params.insuranceId = submissionForm.insuranceId;
    } else if (!!submissionForm.insuranceName) {
      params.payerName = submissionForm.insuranceName;
    }

    executeUpdate({
      params
    });
  }, [submissionForm]);

  const handleSort = useCallback(
    (column) => {
      const sort = {
        column,
        asc: sortedBy.column === column ? !sortedBy.asc : true
      };
      setSortedBy(sort);
      let sorted = [];

      if (column === 'campus_id') {
        sorted = dataToDisplay.sort((a, b) =>
          parseInt(a[column], 10) > parseInt(b[column], 10)
            ? 1
            : parseInt(b[column], 10) > parseInt(a[column], 10)
            ? -1
            : 0
        );
      } else if (column === 'first_name' || column === 'last_name') {
        sorted = dataToDisplay.sort((a, b) =>
          a[column] > b[column] ? 1 : b[column] > a[column] ? -1 : 0
        );
      } else if (column === 'school_name') {
        sorted = dataToDisplay.sort((a, b) =>
          a[column] > b[column] ? 1 : b[column] > a[column] ? -1 : 0
        );
      } else {
        sorted = dataToDisplay.sort((a, b) =>
          a[column] > b[column] ? 1 : b[column] > a[column] ? -1 : 0
        );
      }

      if (!sort.asc) {
        sorted = sorted.reverse();
      }

      setDataToDisplay(sorted);
    },
    [sortedBy, dataToDisplay]
  );

  // Handle filter
  useEffect(() => {
    let filterd = defaultData;
    if (search) {
      const normalicedSearch = search.toLowerCase();
      filterd = filterd.filter(
        (submission) =>
          submission.campus_id.toLowerCase().includes(normalicedSearch) ||
          submission.first_name.toLowerCase().includes(normalicedSearch) ||
          submission.last_name.toLowerCase().includes(normalicedSearch)
      );
    }
    if (selectedSchool) {
      filterd = filterd.filter(
        (submission) => submission.school_name === selectedSchool
      );
    }

    if (selectedInsurance) {
      filterd = filterd.filter(
        (submission) =>
          submission.waiver_form_data.insuranceCompanyName === selectedInsurance
      );
    }

    setDataToDisplay(filterd);
  }, [search, selectedSchool, selectedInsurance, defaultData]);

  const renderSchoolOptions = useCallback(() => {
    const distinctSchools = defaultData
      .map((item) => item.school_name)
      .filter((value, index, self) => self.indexOf(value) === index)
      .sort((a, b) => (a > b ? 1 : b > a ? -1 : 0));
    return distinctSchools.map((schoolName) => (
      <option key={schoolName} value={schoolName}>
        {schoolName}
      </option>
    ));
  }, [defaultData]);

  const renderInsuranceOptions = useCallback(() => {
    const distinctInsurances = defaultData
      .map((item) => item.waiver_form_data.insuranceCompanyName)
      .filter((value, index, self) => self.indexOf(value) === index)
      .sort((a, b) => (a > b ? 1 : b > a ? -1 : 0));
    return distinctInsurances.map((insuranceName) => (
      <option key={insuranceName} value={insuranceName}>
        {insuranceName}
      </option>
    ));
  }, [defaultData]);

  const handleOpenSubmission = useCallback(
    (submission) => {
      if (selectedSubmission && submission.id === selectedSubmission.id) {
        setSubmissionForm(undefined);
        setSelectedSubmission(undefined);
      } else {
        setSubmissionForm({
          dirty: false,
          memberId: submission.waiver_form_data.insuranceMemberNumber,
          memberSupport: submission.waiver_form_data.insurancePhoneNumber,
          groupId: submission.waiver_form_data.insuranceGroupNumber,
          payerId: submission.waiver_form_data.payerId,
          insuranceId: submission.waiver_form_data.insuranceId
        });
        setSelectedSubmission({
          ...submission,
          student: {
            first_name: submission.first_name,
            last_name: submission.last_name,
            student_id: submission.campus_id,
            id: submission.student_id,
            gender: submission.gender,
            phone: submission.phone,
            email_address: submission.email_address,
            date_of_birth: submission.date_of_birth
          },
          period: { academic_year: data.academic_year },
          segment: { segment_label: data.segment_label },
          school: {
            supplement_plan_enabled: submission.supplement_plan_enabled
          }
        });
      }
    },
    [selectedSubmission, data]
  );

  const handleUpdateLocal = useCallback(
    (submission) => {
      const index = defaultData.findIndex(
        (s) => s.id === selectedSubmission.id
      );
      const updatedData = defaultData;
      updatedData[index] = {
        ...updatedData[index],
        ...submission,
        student: {
          first_name: updatedData[index].first_name,
          last_name: updatedData[index].last_name,
          student_id: updatedData[index].campus_id,
          id: updatedData[index].student_id,
          gender: updatedData[index].gender,
          phone: updatedData[index].phone,
          email_address: updatedData[index].email_address,
          date_of_birth: updatedData[index].date_of_birth
        },
        period: { academic_year: data.academic_year },
        segment: { segment_label: data.segment_label },
        school: {
          supplement_plan_enabled: updatedData[index].supplement_plan_enabled
        }
      };

      setSelectedSubmission(updatedData[index]);
      setDefaultData(updatedData);
      setCount((c) => c + 1);
      setSubmissionForm({
        dirty: false,
        memberId: submission.waiver_form_data.insuranceMemberNumber,
        memberSupport: submission.waiver_form_data.insurancePhoneNumber,
        groupId: submission.waiver_form_data.insuranceGroupNumber,
        payerId: submission.waiver_form_data.payerId,
        insuranceId: submission.waiver_form_data.insuranceId
      });
    },
    [defaultData, selectedSubmission]
  );

  const handleCloseValidatorModal = useCallback(() => {
    const index = defaultData.findIndex((s) => s.id === selectedSubmission.id);
    const updatedData = defaultData;
    updatedData[index] = { ...updatedData[index], ...dataValidate };
    setDefaultData(updatedData);
    setCount((c) => c + 1);
    if (dataValidate.status === 'AP') {
      setDefaultData((d) => [
        ...d.filter((item) => item.id !== selectedSubmission.id)
      ]);
      setCount((c) => c + 1);
    }
    setDisplayValidationModal(false);
  }, [dataValidate, selectedSubmission, defaultData]);

  if (defaultLoading || loading || loadingProviders) {
    return <Spinner />;
  }

  return (
    <>
      {selectedSubmission && (
        <WaiverModal
          display={displayWaiverModal}
          setDisplay={setDisplayWaiverModal}
          submission={selectedSubmission}
          refetch={getAuditors}
          school={selectedSubmission.school}
          updateLocal={handleUpdateLocal}
        />
      )}
      <ValidationConfirmationModal
        display={displayValidationModal}
        setDisplay={handleCloseValidatorModal}
        message={validatorMessage}
      />
      <ol className="breadcrumb">
        <li key="crumb-1" className="breadcrumb-item">
          <Link onClick={() => setDashboard('SUMMARY')}>{t('DASHBOARD')}</Link>
        </li>
        <li key="crumb-2" className="breadcrumb-item">
          <Link onClick={() => setDashboard('AUDITING')}>{t('AUDITORS')}</Link>
        </li>
        <li key="crumb-3" className="breadcrumb-item active">
          {provider}
        </li>
      </ol>
      <div className="d-flex bg-white pt-3 form-type-material">
        <div className="col-md-3 offset-3">
          <div className="form-group do-float">
            <input
              type="text"
              name="search"
              id="search"
              className="form-control"
              onChange={(e) => setSearch(e.target.value)}
              value={search}
            />
            <label htmlFor="search" className="label-floated require">
              {t('SEARCH')}
            </label>
          </div>
        </div>
        <div className="col-md-3">
          <div className="form-group do-float mb-0">
            <select
              className="form-control"
              name="school"
              id="school"
              value={selectedSchool}
              onChange={(e) => setSelectedSchool(e.target.value)}
              data-provide="selectpicker"
              data-live-search="true"
            >
              <option value="">{t('ALL')}</option>
              {renderSchoolOptions()}
            </select>
            <label className="label-floated">{t('SCHOOL_NAME')}</label>
          </div>
        </div>
        <div className="col-md-3">
          <div className="form-group do-float mb-0" key={count}>
            <select
              className="form-control"
              name="insurance"
              id="insurance"
              value={selectedInsurance}
              onChange={(e) => setSelectedInsurance(e.target.value)}
              data-provide="selectpicker"
              data-live-search="true"
            >
              <option key="insurance_all" value="">
                {t('ALL')}
              </option>
              {renderInsuranceOptions()}
            </select>
            <label className="label-floated">{t('INSURANCE_COMPANY')}</label>
          </div>
        </div>
      </div>
      <div className="card border border-light">
        {(loadingPostStatus || loadingUpdate) && <Preloader />}
        <CardHeader light={provider} />
        <div className="card-body">
          <table
            className="table table-striped table-lg table-hover dataTable"
            data-provide="datatable"
          >
            <thead className="bg-white">
              <tr>
                <th
                  onClick={() => handleSort('campus_id')}
                  className="cursor-pointer"
                >
                  {' '}
                  <div className="d-flex">
                    {t('STUDENT_ID')}{' '}
                    <SortArrows
                      customStyle="ml-2"
                      column="campus_id"
                      sortedBy={sortedBy.column}
                      asc={sortedBy.asc}
                    />
                  </div>
                </th>
                <th
                  onClick={() => handleSort('first_name')}
                  className="cursor-pointer"
                >
                  {' '}
                  <div className="d-flex">
                    {t('FIRST_NAME')}
                    <SortArrows
                      customStyle="ml-2"
                      column="first_name"
                      sortedBy={sortedBy.column}
                      asc={sortedBy.asc}
                    />
                  </div>
                </th>
                <th
                  onClick={() => handleSort('last_name')}
                  className="cursor-pointer"
                >
                  {' '}
                  <div className="d-flex">
                    {t('LAST_NAME')}
                    <SortArrows
                      customStyle="ml-2"
                      column="last_name"
                      sortedBy={sortedBy.column}
                      asc={sortedBy.asc}
                    />
                  </div>
                </th>
                <th
                  onClick={() => handleSort('school_name')}
                  className="cursor-pointer"
                >
                  {' '}
                  <div className="d-flex">
                    {t('SCHOOL')}
                    <SortArrows
                      customStyle="ml-2"
                      column="school_name"
                      sortedBy={sortedBy.column}
                      asc={sortedBy.asc}
                    />
                  </div>
                </th>
                <th
                  onClick={() => handleSort('INSURANCE_COMPANY')}
                  className="cursor-pointer"
                >
                  {' '}
                  <div className="d-flex">
                    {t('INSURANCE_COMPANY')}
                    <SortArrows
                      customStyle="ml-2"
                      column="INSURANCE_COMPANY"
                      sortedBy={sortedBy.column}
                      asc={sortedBy.asc}
                    />
                  </div>
                </th>
                <th
                  onClick={() => handleSort('validator_current_status')}
                  className="cursor-pointer"
                >
                  {' '}
                  <div className="d-flex">
                    {t('REASON')}
                    <SortArrows
                      customStyle="ml-2"
                      column="validator_current_status"
                      sortedBy={sortedBy.column}
                      asc={sortedBy.asc}
                    />
                  </div>
                </th>
                <th
                  onClick={() => handleSort('FILES')}
                  className="cursor-pointer"
                >
                  {' '}
                  <div className="d-flex">
                    {t('FILES')}
                    <SortArrows
                      customStyle="ml-2"
                      column="FILES"
                      sortedBy={sortedBy.column}
                      asc={sortedBy.asc}
                    />
                  </div>
                </th>
                <th
                  onClick={() => handleSort('created_at')}
                  className="cursor-pointer"
                >
                  {' '}
                  <div className="d-flex">
                    {t('CREATED')}
                    <SortArrows
                      customStyle="ml-2"
                      column="created_at"
                      sortedBy={sortedBy.column}
                      asc={sortedBy.asc}
                    />
                  </div>
                </th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {dataToDisplay &&
                dataToDisplay.map((submission) => (
                  <SubmissionRow
                    key={submission.id}
                    submission={submission}
                    open={
                      selectedSubmission &&
                      selectedSubmission.id === submission.id
                    }
                    submissionForm={submissionForm}
                    dataProviders={dataProviders}
                    selectedSubmission={selectedSubmission}
                    loadingUpdate={loadingUpdate}
                    loadingValidate={loadingValidate}
                    onClick={handleOpenSubmission}
                    handleSelectChanged={handleSelectChanged}
                    handleFormChange={handleFormChange}
                    handleValidate={handleValidate}
                    submitForm={submitForm}
                    handleSendToPR={handleSendToPR}
                    handleView={handleView}
                  />
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default AuditingTable;
