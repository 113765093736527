import React, { useState, useContext, useCallback } from 'react';
import { useHistory, Link, useLocation } from 'react-router-dom';
import { css } from '@emotion/core';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import logo from '../../img/e-waive_2.png';
import ProfileModal from '../ProfileModal/ProfileModal';
import UpdatePasswordModal from '../UpdatePasswordModal/UpdatePasswordModal';
import { UserService } from '../../services/UserService';
import { DashboardStateContext } from '../../context/DashboardContext';

const Topbar = ({ title, settings, crumbs, withHeader }) => {
  const { t } = useTranslation();
  const avatar_url = JSON.parse(window.localStorage.getItem('me')).avatar_url;
  const history = useHistory();
  const location = useLocation();
  const [openPasswordModal, setOpenPasswordModal] = useState(false);
  const [openProfileModal, setOpenProfileModal] = useState(false);
  const [dashboard, setDashboard] = useContext(DashboardStateContext);

  const handleLogout = async () => {
    axios.defaults.headers.Authorization = `Bearer ${window.localStorage.getItem(
      'jwt'
    )}`;
    const res = await axios.post(
      `${process.env.REACT_APP_BASE_URL}api/v1/auth/logout`
    );
    if (res.status && res.status === 200) {
      window.localStorage.removeItem('jwt');
      window.localStorage.removeItem('me');
    }
    logOutAndRedirect('/login');
  };

  const handleLockScreen = () => {
    logOutAndRedirect('/locked');
  };

  const logOutAndRedirect = (to) => {
    window.localStorage.setItem('loggedIn', '0');
    history.push(`${to}?from=${history.location.pathname}`);
  };

  const handleDashboardMenu = useCallback(
    (value) => {
      setDashboard(value);
      if (location.pathname !== '/') {
        history.push('/');
      }
    },
    [location.pathname, history]
  );

  const isAdmin = UserService.isAdmin();
  const isSuperAdmin = UserService.isSuperAdmin();

  return (
    <>
      <ProfileModal
        display={openProfileModal}
        setDisplay={setOpenProfileModal}
      />
      <UpdatePasswordModal
        display={openPasswordModal}
        setDisplay={setOpenPasswordModal}
      />
      <header
        css={css`
          border-bottom: 1px solid rgba(255, 255, 255, 0.1);
          height: 65px;
        `}
        className="topbar topbar-expand-lg topbar-inverse"
      >
        <div className="topbar-left">
          <span className="topbar-btn topbar-menu-toggler">
            <i>☰</i>
          </span>

          <div className="topbar-brand">
            <Link to="/">
              <img width="120" src={logo} alt="logo" />
            </Link>
          </div>
          <nav
            className="topbar-navigation"
            css={css`
              .menu-submenu {
                left: -50px;
              }
            `}
          >
            <ul className="menu">
              <li
                className={`menu-item ${
                  location.pathname === '/' ? 'active' : ''
                }`}
              >
                <a className="menu-link" href={isAdmin ? null : '/'}>
                  <i className="fa fa-bar-chart"></i>
                  <span className="title text-capitalize">
                    {t('DASHBOARD')}
                  </span>
                  {isAdmin && <span className="arrow"></span>}
                </a>
                {isAdmin && (
                  <ul className="menu-submenu">
                    <li
                      className={`menu-item cursor-pointer mx-0 ${
                        location.pathname === '/' && dashboard === 'SUMMARY'
                          ? 'text-fade bg-light'
                          : ''
                      }`}
                    >
                      <a
                        className="menu-link"
                        onClick={() => handleDashboardMenu('SUMMARY')}
                      >
                        {t('SUMMARY_DASHBOARD')}
                      </a>
                    </li>
                    <li
                      className={`menu-item cursor-pointer mx-0 ${
                        location.pathname === '/' && dashboard === 'AUDITING'
                          ? 'text-fade bg-light'
                          : ''
                      }`}
                    >
                      <a
                        className="menu-link"
                        onClick={() => handleDashboardMenu('AUDITING')}
                      >
                        {t('AUDITING_DASHBOARD')}
                      </a>
                    </li>
                  </ul>
                )}
              </li>

              {UserService.hasPermission('students') && (
                <li
                  className={`menu-item ${
                    location.pathname.includes('/students') ? 'active' : ''
                  }`}
                >
                  <a className="menu-link" href="/students">
                    <i className="fa fa-users"></i>
                    <span className="title text-capitalize">
                      {t('STUDENTS')}
                    </span>
                  </a>
                </li>
              )}

              {isAdmin && (
                <li
                  className={`menu-item ${
                    location.pathname.includes('/schools') ? 'active' : ''
                  }`}
                >
                  <a className="menu-link" href="/schools">
                    <i className="fa fa-university"></i>
                    <span className="title text-capitalize">
                      {t('SCHOOLS')}
                    </span>
                  </a>
                </li>
              )}

              {UserService.hasPermission('reports') && (
                <li
                  className={`menu-item ${
                    location.pathname.includes('/reports') ? 'active' : ''
                  }`}
                >
                  <a className="menu-link" href="/reports">
                    <i className="fa fa-newspaper-o"></i>
                    <span className="title text-capitalize">
                      {t('REPORTS')}
                    </span>
                  </a>
                </li>
              )}

              {isSuperAdmin && (
                <li
                  className={`menu-item ${
                    location.pathname.includes('/users') ||
                    location.pathname.includes('/processing') ||
                    location.pathname.includes('/providers') ||
                    location.pathname.includes('/email-templates')
                      ? 'active'
                      : ''
                  }`}
                >
                  <a
                    css={css`
                      cursor: default;
                    `}
                    className="menu-link"
                  >
                    <i className="fa fa-suitcase"></i>
                    <span className="title text-capitalize">{t('ADMIN')}</span>
                    <span className="arrow"></span>
                  </a>
                  <ul className="menu-submenu">
                    {isSuperAdmin && (
                      <li className="menu-item">
                        <a className="menu-link" href="/users">
                          {t('USERS')}
                        </a>
                      </li>
                    )}
                    <li className="menu-item">
                      <a className="menu-link" href="/providers">
                        {t('INSURANCE_PROVIDERS')}
                      </a>
                    </li>
                    <li className="menu-item">
                      <a className="menu-link" href="/email-templates">
                        {t('EMAILS_TEMPLATES')}
                      </a>
                    </li>
                  </ul>
                </li>
              )}
            </ul>
          </nav>
        </div>

        <div className="topbar-right">
          <ul className="topbar-btns">
            <li className="dropdown d-none d-md-block">
              <span tabIndex="0" className="topbar-btn" data-toggle="dropdown">
                <img className="avatar" src={avatar_url} alt="Profile" />
              </span>
              <div
                className="dropdown-menu open-top-right dropdown-menu-right"
                x-placement="bottom-end"
                style={{
                  position: 'absolute',
                  top: '65px',
                  left: '-120px',
                  willChange: 'top, left'
                }}
              >
                <span
                  tabIndex="0"
                  className="dropdown-item"
                  onClick={() => setOpenProfileModal(true)}
                >
                  <i className="ti-user"></i> {t('PROFILE')}
                </span>
                <span
                  tabIndex="0"
                  className="dropdown-item"
                  onClick={() => setOpenPasswordModal(true)}
                >
                  <i className="ti-loop"></i>
                  {t('CHANGE_PASSWORD')}
                </span>
                <div className="dropdown-divider"></div>
                <span
                  tabIndex="0"
                  className="dropdown-item"
                  onClick={handleLockScreen}
                >
                  <i className="ti-lock"></i>
                  {t('LOCK_SCREEN')}
                </span>
                <span
                  tabIndex="0"
                  className="dropdown-item"
                  onClick={handleLogout}
                >
                  <i className="ti-power-off"></i> {t('LOGOUT')}
                </span>
              </div>
            </li>

            {/* <li>
              <span
                className='topbar-btn has-new'
                data-toggle='quickview'
                data-target='#qv-notifications'
              >
                <i className='ti-bell'></i>
              </span>
            </li> */}
          </ul>
        </div>
      </header>
    </>
  );
};

export default Topbar;
