import React from 'react';

const Preloader = ({ message = '', overlay = false }) => {
  return (
    <div
      className="preloader"
      style={{
        backgroundColor: overlay ? 'rgba(245, 246, 247,0.9)' : 'transparent'
      }}
    >
      <div>
        {message && <p className="fw-700 text-center">{message}</p>}
        <div className="spinner-dots w-100">
          <span className="dot1"></span>
          <span className="dot2"></span>
          <span className="dot3"></span>
        </div>
      </div>
    </div>
  );
};

export default Preloader;
