import axios from 'axios';

const requireLogin = (to, from, next) => {
  axios
    .get(`${process.env.REACT_APP_BASE_URL}api/v1/auth/me`, {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('jwt')}`
      }
    })
    .then((res) => {
      window.localStorage.setItem('me', JSON.stringify(res.data));

      if (res.data.type == 'super_admin') {
        next();
      } else {
        next.redirect('/unauthorized'); // Redirect to an unauthorized page or show an error
      }
    })
    .catch((err) => {
      next.redirect(`/login?from=${to.match.path}`);
    });
  return null;
};

export default requireLogin;
