import React, { useState, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import useAxios from 'axios-hooks';
import CurrencyFormat from 'react-currency-format';
import { css } from '@emotion/core';

import Modal from '../Modal/Modal';
import { useHistory } from 'react-router-dom';
import states from '../../data/states.json';
import CardHeader from '../CardHeader/index';
import { UserService } from '../../services/UserService';
import Preloader from '../Preloader/index';
import { formatDateToAPI } from '../../utils/common';

const AddStudentModal = ({ display, setDisplay }) => {
  const history = useHistory();
  const { register, handleSubmit, formState } = useForm({
    mode: 'onChange'
  });
  const [selectOptions, setSelectOptions] = useState([]);
  const [formEnabled, setFormEnabled] = useState(false);
  const [validGender, setValidGender] = useState(false);
  //const [validStudentLevel, setValidStudentLevel] = useState(false);
  //const [validStudentType, setValidStudentType] = useState(false);
  const [studentIdError, setStudentIdError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [studentId, setStudentId] = useState('');
  const [mask, setMask] = useState({
    format: '############',
    mask: ['X', 'X', 'X', 'X', 'X', 'X', 'X', 'X', 'X', 'X', 'X', 'X']
  });

  const [date, setDate] = useState('');
  const [phone, setPhone] = useState('');
  const [selectData, setSelectData] = useState({
    gender: '',
    //studentLevel: '',
    //studentType: '',
    schoolId: ''
  });

  const { t } = useTranslation();
  const [{ data }] = useAxios({
    url: `${process.env.REACT_APP_BASE_URL}api/v1/schools/basic`,
    method: 'GET',
    headers: {
      Authorization: `Bearer ${window.localStorage.getItem('jwt')}`
    }
  });
  const [{ error, loading, data: createdUser }, executePost] = useAxios(
    {
      url: `${process.env.REACT_APP_BASE_URL}api/v1/students`,
      method: 'POST',
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('jwt')}`
      }
    },
    {
      manual: true
    }
  );

  useEffect(() => {
    if (data) {
      let schoolOptions = [];
      data.forEach((school) =>
        schoolOptions.push({ value: school.id, label: school.school_name })
      );
      setSelectOptions(schoolOptions);
    }
  }, [data]);

  useEffect(() => {
    if (createdUser && !error && !loading) {
      setDisplay(false);
      setTimeout(() => history.push(`/students/${createdUser.id}`), 0);
    }
    if (error && !loading) {
      if (
        error.response.data.message ===
        'Student Record Already Exists with that Student ID'
      ) {
        setStudentIdError(true);
      } else if (
        error.response.data.message ===
        'Student Record Already Exists with that Email Address'
      ) {
        setEmailError(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createdUser, error, loading]);

  useEffect(() => {
    if (!UserService.isAdmin()) {
      setSelectData((v) => ({
        ...v,
        schoolId: UserService.getUser().school_id
      }));
      setFormEnabled(true);
    }
  }, []);

  const handleFormSubmit = async (data) => {
    executePost({
      params: {
        ...data,
        ...selectData,
        streetAddress1: data.streetAddress1.replaceAll(':', ''),
        streetAddress2: data.streetAddress2.replaceAll(':', ''),
        phone,
        dateOfBirth: formatDateToAPI(date),
        studentId
      }
    });
  };

  const handleSelectChange = (e) => {
    setSelectData({
      ...selectData,
      [e.currentTarget.name]: e.currentTarget.value
    });
    if (e.currentTarget.name === 'studentId') {
      setStudentId('');
    }
  };

  const handlePhoneChange = (e) => {
    setPhone(e.value.trim());
  };

  const handleDateChange = (val) => {
    setDate(val.value);
  };

  const handleStudentIdChange = (val) => {
    setStudentId(val.value);
    if (studentIdError) {
      setStudentIdError(false);
    }
  };

  const studentIdHasMask = useMemo(() => {
    if (data && selectData.schoolId) {
      const school = data.find((option) => option.id === selectData.schoolId);
      if (
        school.student_id_format_mask &&
        school.student_id_format_mask.length &&
        school.student_id_format_mask.length > 0
      ) {
        return true;
      }
    }
    return false;
  }, [data, selectData]);

  useEffect(() => {
    if (data && studentIdHasMask) {
      let format = '';
      const mask = [];
      const school = data.find((option) => option.id === selectData.schoolId);
      for (
        let index = 0;
        index < school.student_id_format_mask.length;
        index++
      ) {
        format = format + '#';
        mask.push('X');
      }
      setMask({
        format,
        mask
      });
    } else {
      setMask({
        format: '############',
        mask: ['X', 'X', 'X', 'X', 'X', 'X', 'X', 'X', 'X', 'X', 'X', 'X']
      });
    }
  }, [data, studentIdHasMask, selectData]);

  const isFormValid = useMemo(
    () =>
      formState.isValid &&
      validGender &&
      //validStudentLevel &&
      //validStudentType &&
      date.length === 8 &&
      studentId.length === mask.mask.length,
    [
      formState,
      validGender,
      //validStudentLevel,
      //validStudentType,
      date,
      studentId,
      mask
    ]
  );

  return (
    <Modal
      targetId="add-student-modal"
      width="w-600px"
      display={display}
      setDisplay={setDisplay}
    >
      <CardHeader
        title={`${t('CREATE')} ${t('STUDENT')}`}
        rightComponent={
          <button
            onClick={() => setDisplay(false)}
            className="btn border-0 p-0"
          >
            <i className="fa fa-close"></i>
          </button>
        }
      />
      <div className="card-body">
        {loading && <Preloader />}
        <form
          onSubmit={handleSubmit(handleFormSubmit)}
          className="form-type-material row"
          css={css`
            label.require::after,
            label.required::after {
              //content: '*';
              color: #f96868;
              font-weight: 800;
              margin-left: 8px;
              font-size: 18px;
            }
          `}
        >
          <div className="col-md-6">
            <div className="form-group do-float">
              <select
                type="text"
                name="schoolId"
                id="schoolId"
                className="form-control"
                data-provide="selectpicker"
                defaultValue={
                  UserService.isAdmin() ? '' : UserService.getUser().school_id
                }
                onChange={(e) => {
                  handleSelectChange(e);
                  setFormEnabled(true);
                }}
                disabled={!UserService.isAdmin()}
              >
                <option disabled value="">
                  {t('SELECT')}
                </option>
                {selectOptions.map((o) => (
                  <option key={o.value} value={o.value}>
                    {t(o.label)}
                  </option>
                ))}
              </select>
              <label htmlFor="schoolId" className="label-floated">
                {t('SCHOOL')}
              </label>
            </div>
            <div
              style={
                !formEnabled ? { opacity: 0.5, pointerEvents: 'none' } : {}
              }
            >
              <div className="form-group">
                <input
                  type="text"
                  name="firstName"
                  id="firstName"
                  className="form-control"
                  ref={register({
                    required: 'First name field is required'
                  })}
                />
                <label htmlFor="firstName" className="require">
                  {t('FIRST_NAME')}
                </label>
              </div>
              <div className="form-group">
                <input
                  type="text"
                  name="lastName"
                  id="lastName"
                  className="form-control"
                  ref={register({
                    required: 'Last name field is required'
                  })}
                />
                <label htmlFor="lastName" className="require">
                  {t('LAST_NAME')}
                </label>
              </div>
              <div className="form-group">
                <CurrencyFormat
                  id="studentID"
                  name="studentID"
                  value={studentId}
                  onValueChange={(val) => handleStudentIdChange(val)}
                  className={`form-control ${
                    studentIdError ? 'is-invalid' : ''
                  }`}
                  format={mask.format}
                  mask={mask.mask}
                />
                <label htmlFor="studentId" className="do-float require">
                  {t('STUDENT_ID')}
                </label>
                {studentIdError && (
                  <div className="invalid-feedback">
                    {t('STUDENT_ID_EXISTS')}
                  </div>
                )}
              </div>
              <div className="form-group">
                <input
                  type="text"
                  name="alternateId"
                  id="alternateId"
                  className="form-control"
                  ref={register()}
                />
                <label htmlFor="alternateId">{t('ALTERNATE_ID')}</label>
              </div>
              <div className="form-group">
                <input
                  type="email"
                  name="emailAddress"
                  id="emailAddress"
                  className={`form-control ${emailError ? 'is-invalid' : ''}`}
                  ref={register({
                    required: 'Email Address field is required'
                  })}
                  onChange={() => emailError && setEmailError(false)}
                />
                <label htmlFor="emailAddress" className="require">
                  {t('EMAIL_ADDRESS')}
                </label>
                {emailError && (
                  <div className="invalid-feedback">
                    {t('STUDENT_EMAIL_EXISTS')}
                  </div>
                )}
              </div>
              <div className="form-group">
                <label htmlFor="phone">{t('PHONE')}</label>
                <CurrencyFormat
                  id="phone"
                  name="phone"
                  value={phone}
                  onValueChange={(val) => handlePhoneChange(val)}
                  className="form-control"
                  format="+1 ###-###-####"
                  // placeholder="+1 000-000-0000"
                />
              </div>
            </div>
          </div>
          <div
            className="col-md-6"
            style={!formEnabled ? { opacity: 0.5, pointerEvents: 'none' } : {}}
          >
            <div className="form-group">
              <input
                type="text"
                name="streetAddress1"
                id="streetAddress1"
                className="form-control"
                ref={register()}
              />
              <label htmlFor="streetAddress1">{t('STREET_ADDRESS')} 1</label>
            </div>
            <div className="form-group">
              <input
                type="text"
                name="streetAddress2"
                id="streetAddress2"
                className="form-control"
                ref={register()}
              />
              <label htmlFor="streetAddress2">{t('STREET_ADDRESS')} 2</label>
            </div>
            <div className="form-group">
              <input
                type="text"
                name="city"
                id="city"
                className="form-control"
                ref={register()}
              />
              <label htmlFor="city">{t('CITY')}</label>
            </div>

            <div className="row">
              <div className="col-md-6">
                <div className="form-group do-float">
                  <select
                    name="state"
                    id="state"
                    className="form-control"
                    data-provide="selectpicker"
                    data-live-search="true"
                    defaultValue={''}
                    ref={register()}
                  >
                    <option disabled value="">
                      {t('SELECT')}
                    </option>
                    {Object.keys(states).map((key) => (
                      <option key={key} value={key}>
                        {states[key]}
                      </option>
                    ))}
                  </select>
                  <label htmlFor="state" className="label-floated">
                    {t('STATE')}
                  </label>
                </div>
                <div className="form-group do-float">
                  <select
                    type="text"
                    name="gender"
                    id="gender"
                    className="form-control"
                    data-provide="selectpicker"
                    defaultValue={''}
                    onChange={(e) => {
                      setValidGender(true);
                      handleSelectChange(e);
                    }}
                  >
                    <option disabled value="">
                      {t('SELECT')}
                    </option>
                    <option value="M">{t('MALE')}</option>
                    <option value="F">{t('FEMALE')}</option>
                    <option value="U">{t('NEUTRAL')}</option>
                  </select>
                  <label htmlFor="gender" className="label-floated require">
                    {t('GENDER')}
                  </label>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group do-flat">
                  <input
                    type="text"
                    name="postalCode"
                    id="postalCode"
                    className="form-control"
                    ref={register()}
                  />
                  <label htmlFor="postalCode">{t('ZIP_CODE')}</label>
                </div>
                <div className="form-group do-float">
                  <CurrencyFormat
                    id="dateOfBirth"
                    name="dateOfBirth"
                    value={date}
                    onValueChange={(val) => handleDateChange(val)}
                    className="form-control"
                    format="##/##/####"
                    placeholder="MM/DD/YYYY"
                    mask={['M', 'M', 'D', 'D', 'Y', 'Y', 'Y', 'Y']}
                  />
                  <label
                    className="label-floated require"
                    htmlFor="dateOfBirth"
                  >
                    {t('DATE_OF_BIRTH')}
                  </label>
                </div>
              </div>
            </div>
            <div className="form-group do-float">
              <input
                type="text"
                className="form-control"
                name="classification"
                id="classification"
                ref={register()}
              />
              <label className="label-floated" htmlFor="classification">
                {t('CLASSIFICATION')}
              </label>
            </div>
          </div>
          <div className="d-flex justify-content-between mt-30 mb-10 w-100">
            <div className="d-flex ml-15">
              <label
                css={css`
                  color: #f96868;
                  font-weight: 800;
                  margin-left: 8px;
                  font-size: 18px;
                `}
              >
                *
              </label>
              <label className="ml-1">{t('INDICATES_REQUIRED_FIELD')}</label>
            </div>
            <button
              disabled={!isFormValid || loading}
              className={`btn btn-bold d-block ml-auto mr-30 btn-primary btn-label ${
                !isFormValid || loading ? 'disabled' : ''
              }`}
              type="submit"
            >
              <span>
                <i className="ti-check"></i>
              </span>
              {t('ADD')}
            </button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default AddStudentModal;
