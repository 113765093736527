/* eslint-disable no-console */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/interactive-supports-focus */
import React, { useContext, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { SchoolStateContext } from '../../context/SchoolContext';
import { useAuthorizedAxios } from '../../hooks/use-authorizedaxios';
import ExportsTab from './ExportsTab';
import ImportsTab from './ImportsTab';
import ImportModal from './ImportModal';
import PeriodsSelect from './PeriodsSelect';
import ImportService from './ImportService';
import ExportService from './ExportService';
import PreviewCard from './PreviewCard';
import Spinner from '../Spinner';

const ImportExportTab = ({ tabOption }) => {
  const { t } = useTranslation();
  const [schoolId] = useContext(SchoolStateContext);
  const [selectedPeriod, setSelectedPeriod] = useState({
    label: '',
    value: ''
  });
  // State for import/export data
  const [selectedImportData, setSelectedImportData] = useState(null);
  const [selectedExportData, setSelectedExportData] = useState(null);

  // Modal and status management
  const [importModal, setImportModal] = useState(false);

  const [exportStatuses, setExportStatuses] = useState({
    service: false,
    preview: false
  });

  const [{ data: importsPeriodsData }, getImportPeriods] = useAuthorizedAxios({
    manual: true,
    url: `${process.env.REACT_APP_BASE_URL}api/v2/schools/imports/${schoolId}`,
    method: 'GET',
    params: {
      segment_id: selectedPeriod.value,
      processed: 0
    }
  });

  const [{ data: exportPeriodsData }, getExportPeriods] = useAuthorizedAxios({
    manual: true,
    url: `${process.env.REACT_APP_BASE_URL}api/v2/schools/exports/${schoolId}`,
    method: 'GET',
    params: {
      segment_id: selectedPeriod.value,
      processed: false
    }
  });

  useEffect(() => {
    getImportPeriods();
    getExportPeriods();
  }, []);

  useEffect(() => {
    if (exportPeriodsData) {
      setExportStatuses({
        service: exportPeriodsData.export_active,
        preview: exportPeriodsData.preview_mode
      });
    }
  }, [exportPeriodsData]);

  return (
    <div className="container">
      <div className="row">
        {selectedImportData?.processed && (
          <ImportModal
            setDisplay={setImportModal}
            onPageChange={getImportPeriods}
            display={importModal}
            modalData={{
              status: selectedImportData?.processed,
              schoolId,
              period: selectedPeriod
            }}
          />
        )}

        <div className="col-9">
          <div className="card card-shadowed">
            <div className="card-title">
              <div className="row">
                <div className="col-md-2">
                  <h3>{t(tabOption === 'import' ? 'IMPORTS' : 'EXPORTS')}:</h3>
                </div>
                <div className="col-md-3">
                  <PeriodsSelect
                    setSelectedPeriod={setSelectedPeriod}
                    selectedPeriod={selectedPeriod}
                    periodsData={
                      tabOption == 'import'
                        ? importsPeriodsData
                        : exportPeriodsData
                    }
                  />
                </div>
                <div
                  className="col-md-7"
                  style={{
                    display: 'flex',
                    justifyContent: 'end',
                    height: 'fit-content'
                  }}
                >
                  {selectedImportData || selectedExportData ? (
                    <button
                      className={`btn btn-bold ${
                        (tabOption === 'import' &&
                          selectedImportData?.processed) ||
                        (tabOption === 'export' &&
                          selectedExportData?.submitted)
                          ? 'btn-success'
                          : 'btn-warning'
                      }`}
                    >
                      {tabOption === 'import'
                        ? selectedImportData?.processed
                          ? t('PROCESSED')
                          : t('PENDING')
                        : selectedExportData?.submitted
                        ? t('SENT')
                        : t('PREVIEW')}
                    </button>
                  ) : null}
                </div>
              </div>
            </div>

            <div className="card-body">
              {tabOption == 'import' && (
                <ImportsTab
                  schoolId={schoolId}
                  selectedPeriod={selectedPeriod}
                  selectedImportData={selectedImportData}
                  setSelectedImportData={setSelectedImportData}
                />
              )}
              {tabOption === 'export' && (
                <ExportsTab
                  schoolId={schoolId}
                  selectedPeriod={selectedPeriod}
                  selectedExportData={selectedExportData}
                  setSelectedExportData={setSelectedExportData}
                />
              )}
            </div>
          </div>
        </div>

        <div className="col-3">
          {tabOption == 'import' && (
            <>
              {importsPeriodsData && schoolId ? (
                <ImportService
                  isImportActive={importsPeriodsData?.import_active}
                  schoolId={schoolId}
                />
              ) : (
                <Spinner />
              )}
            </>
          )}

          {tabOption == 'export' && (
            <>
              {exportPeriodsData && schoolId ? (
                <ExportService
                  schoolId={schoolId}
                  exportStatuses={exportStatuses}
                  setExportStatuses={setExportStatuses}
                />
              ) : (
                <Spinner />
              )}
            </>
          )}

          {tabOption === 'export' && (
            <PreviewCard
              schoolId={schoolId}
              exportStatuses={exportStatuses}
              setExportStatuses={setExportStatuses}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default ImportExportTab;
