import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { css } from '@emotion/core';
import 'react-datepicker/dist/react-datepicker.css';
import Preloader from '../Preloader/index';
import { useAuthorizedAxios } from '../../hooks/use-authorizedaxios';

const ImportsTab = ({
  schoolId,
  selectedPeriod,
  selectedImportData,
  setSelectedImportData
}) => {
  const { t } = useTranslation();
  const [selectedDate, setSelectedDate] = useState();
  const [highlightedDates, setHighlightedDates] = useState([]);

  const [{ data: importsDataByPeriod, loading }, getDataByPeriod] =
    useAuthorizedAxios({
      manual: true,
      url: `${process.env.REACT_APP_BASE_URL}api/v2/schools/imports/${schoolId}/${selectedPeriod.value}/files`,
      method: 'GET'
    });

  const [{ loading: downloadLoading }, download] = useAuthorizedAxios({
    manual: true,
    url: `${process.env.REACT_APP_BASE_URL}api/v1/schools/${schoolId}/imports/${selectedImportData?.id}`,
    method: 'GET'
  });

  const handleSelectDate = (date) => {
    const formattedDate = moment(date).format('YYYY-MM-DD');

    const selectedSegment = importsDataByPeriod.find((item) =>
      item.created_at.includes(formattedDate)
    );

    if (!selectedSegment) {
      console.warn(
        'No matching segment found for selectedPeriod:',
        selectedPeriod.value
      );
      setSelectedDate(date);
      setHighlightedDates([]);
      return;
    } else {
      setSelectedDate(date);
      setSelectedImportData(selectedSegment);
    }
  };

  const ColorCard = useCallback(
    ({ className, amount, name }) => (
      <div
        css={css`
          height: 127px;
          width: 140px;
        `}
        className={`text-center pt-4 ${className}`}
      >
        <p className="text-white fw-700 fs-35 mb-10">{amount}</p>
        <p className="text-white fw-700 fs-13">{name}</p>
      </div>
    ),
    []
  );

  const handleDownload = async () => {
    await download().then((response) => window.open(response.data));
  };

  const handleView = async () => {
    await download().then((response) =>
      window.open(`${response.data}&download=1`)
    );
  };

  useEffect(() => {
    if (importsDataByPeriod && selectedPeriod?.value) {
      const selectedPeriodId = selectedPeriod.value;

      if (importsDataByPeriod.length > 0) {
        setSelectedDate(new Date(importsDataByPeriod[0].created_at));

        setHighlightedDates(
          importsDataByPeriod.map(
            (importItem) => new Date(importItem.created_at)
          )
        );

        setSelectedImportData(importsDataByPeriod[0]);
      } else {
        console.warn(
          'No matching segment found for selectedPeriod:',
          selectedPeriodId
        );
        setSelectedImportData(undefined);
      }
    }
  }, [importsDataByPeriod, selectedPeriod]);

  useEffect(() => {
    setSelectedDate(undefined);
    setHighlightedDates([]);
  }, [selectedPeriod]);

  useEffect(() => {
    if (selectedPeriod.value) {
      getDataByPeriod();
    }
  }, [selectedPeriod.value]);

  return (
    <div
      css={css`
        border: solid 1px rgba(151, 151, 151, 0.25);
      `}
      className="card-body"
    >
      {(downloadLoading || loading) && (
        <Preloader
          customStyle={{
            backgroundColor: 'rgba(128, 128, 128, 0.5)'
          }}
        />
      )}

      <div className="d-flex justify-content-between form-type-material">
        <div
          className="col-4 text-left"
          css={css`
            .react-datepicker-popper {
              z-index: 995;
            }
          `}
        >
          <label htmlFor="datepicker-imp" className="m-0 d-block l-datepicker">
            {t('DATE')}
          </label>

          <DatePicker
            selected={selectedDate}
            onChange={handleSelectDate}
            includeDates={highlightedDates}
            className="form-control"
            placeholderText="Pick a Date"
            id="datepicker-imp"
          />
        </div>

        {selectedImportData && selectedImportData?.id != -1 && (
          <div className="col-8 text-right">
            <button
              type="button"
              onClick={handleView}
              className="ml-10 btn btn-bold d-inline-block btn-primary btn-label"
            >
              <span>
                <i className="fa fa-eye" />
              </span>
              {t('VIEW')}
            </button>
            <button
              type="button"
              onClick={handleDownload}
              className="ml-10 btn btn-bold d-inline-block btn-primary btn-label"
            >
              <span>
                <i className="fa fa-download" />
              </span>
              {t('DOWNLOAD')}
            </button>
          </div>
        )}
      </div>

      <div
        css={css`
          .bg-dark-purple {
            background-color: #926bde;
          }
          .bg-dark-grey {
            background-color: #939eaf;
          }
        `}
        className="d-flex my-3"
      >
        <ColorCard
          className="mr-1 bg-success"
          name={t('NEW_STUDENTS')}
          amount={selectedImportData?.process_report?.NEW_STUDENTS || 0}
        />
        <ColorCard
          className="mr-1 bg-dark-purple"
          name={t('ELIGIBILITY_REQUIRED')}
          amount={selectedImportData?.process_report?.ELIGIBILITY_REQUIRED || 0}
        />
        <ColorCard
          className="mr-1 bg-info"
          name={t('ELIGIBILITY_NOT_REQUIRED')}
          amount={
            selectedImportData?.process_report?.ELIGIBILITY_NOT_REQUIRED || 0
          }
        />
        <ColorCard
          className="mr-1 bg-warning"
          name={t('PROFILE_UPDATED')}
          amount={selectedImportData?.process_report?.PROFILE_UPDATED || 0}
        />
        <ColorCard
          className="mr-1 bg-pink"
          name={t('ELIGIBILITY_LOCKS')}
          amount={selectedImportData?.process_report?.ELIGIBILITY_LOCKS || 0}
        />
        <ColorCard
          className="bg-dark-grey"
          name={t('UNCHANGED')}
          amount={selectedImportData?.process_report?.UNCHANGED || 0}
        />
      </div>
    </div>
  );
};

export default ImportsTab;
