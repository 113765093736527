import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/core';
import EnrollmentPeriodsTab from '../EnrollmentPeriodsTab/EnrollmentPeriodsTab';
import { TabsContainer } from '../../styles/common/tabs';
import UsersTab from '../UsersTab';
import ContactsTab from '../ContactsTab';
import ProfileTab from '../ProfileTab';
import { SearchStateContext } from '../../context/SearchContext';
import ImportExportTab from '../ImportExportTab/index';

const SchoolContent = ({ school, refetch }) => {
  const { t } = useTranslation();
  const { setSearchKeyword, setWithSearch } = useContext(SearchStateContext);
  const [activeTab, setActiveTab] = useState('#profile');

  const handleActiveTab = (e) => {
    setSearchKeyword('');

    setWithSearch(e.target.dataset && e.target.dataset.search === '1');

    Array.from(e.currentTarget.children).forEach((li) => {
      const navLink = li.firstChild;
      if (navLink !== e.target && navLink.classList.contains('active')) {
        navLink.classList.remove('active');
      }
    });
  };

  const handleTab = (e) => {
    setActiveTab(e.target.getAttribute('href'));
  };

  return (
    <TabsContainer>
      <div
        css={css`
          box-shadow: none;
          z-index: 600;
        `}
        className="topbar header-inverse mt-150"
      >
        <div className="container">
          <div className="header-action">
            <nav className="nav" onClick={handleActiveTab}>
              <li className="nav-item">
                <button
                  className="nav-link btn active"
                  data-toggle="tab"
                  href="#profile"
                  data-search="0"
                  onClick={handleTab}
                >
                  {t('PROFILE')}
                </button>
              </li>
              <li className="nav-item">
                <button
                  className="nav-link btn"
                  data-toggle="tab"
                  href="#periods"
                  data-search="1"
                  onClick={handleTab}
                >
                  {t('ENROLLMENT_PERIODS')}
                </button>
              </li>
              <li className="nav-item">
                <button
                  className="nav-link btn"
                  data-toggle="tab"
                  href="#contacts"
                  data-search="1"
                  onClick={handleTab}
                >
                  {t('CONTACTS')}
                </button>
              </li>
              <li className="nav-item">
                <button
                  className="nav-link btn"
                  data-toggle="tab"
                  href="#users"
                  data-search="1"
                  onClick={handleTab}
                >
                  {t('USERS')}
                </button>
              </li>

              <li className="nav-item">
                <button
                  className="nav-link btn"
                  data-toggle="tab"
                  href="#import"
                  data-search="1"
                  onClick={handleTab}
                >
                  {t('IMPORT')}
                </button>
              </li>

              <li className="nav-item">
                <button
                  className="nav-link btn"
                  data-toggle="tab"
                  href="#export"
                  data-search="1"
                  onClick={handleTab}
                >
                  {t('EXPORT')}
                </button>
              </li>
            </nav>
          </div>
        </div>
      </div>
      <div className="tab-content">
        <div className="tab-pane fade active show" id="profile">
          {activeTab === '#profile' && (
            <ProfileTab school={school} refetch={refetch} />
          )}
        </div>
        <div className="tab-pane fade" id="periods">
          {activeTab === '#periods' && (
            <EnrollmentPeriodsTab schoolId={school.id} />
          )}
        </div>
        <div className="tab-pane fade" id="contacts">
          {activeTab === '#contacts' && <ContactsTab schoolId={school.id} />}
        </div>
        <div className="tab-pane fade" id="users">
          {activeTab === '#users' && <UsersTab schoolId={school.id} />}
        </div>

        <div className="tab-pane fade" id="import">
          {activeTab === '#import' && <ImportExportTab tabOption="import" />}
        </div>

        <div className="tab-pane fade" id="export">
          {activeTab === '#export' && <ImportExportTab tabOption="export" />}
        </div>
      </div>
    </TabsContainer>
  );
};

export default SchoolContent;
