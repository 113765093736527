import React, { useState, useEffect } from 'react';
import {
  EditorState,
  ContentState,
  convertFromHTML,
  convertToRaw
} from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import htmlToDraft from 'html-to-draftjs';
import draftToHtmlPuri from 'draftjs-to-html';
import CardHeader from '../CardHeader';

import '../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

function PortalMessage({ message, title, name }) {
  //Function to convert a string into editor state
  function setPrevEditorState(previousString) {
    let html = htmlToDraft(previousString);
    return setEditorState(
      EditorState.createWithContent(ContentState.createFromBlockArray(html))
    );
  }
  //textAreaValue
  const [convertedContent, setConvertedContent] = useState(message);
  // valueCounter - used to show the number of characters of the textarea value
  const [valueCounter, setValueCounter] = useState(message);
  //EditorValue
  const [editorState, setEditorState] = useState(() => {
    // Initialize editor state with content.
    let contentState = ContentState.createFromBlockArray(
      convertFromHTML(`<div>${message}.</div>`)
    );
    return (contentState = EditorState.createWithContent(contentState));
  });

  useEffect((e) => {
    //Get live content of the editor state and copy in the textarea value.
    let html = draftToHtmlPuri(convertToRaw(editorState.getCurrentContent()));
    //Get the plain text to count the characters used.
    let valCounter = editorState.getCurrentContent().getPlainText('\u0001');
    //Set the previous value in the textarea (no visual change).
    if (message === '' || html === '<p>.</p>\n') {
      setConvertedContent(message);
      if (message !== '') {
        setPrevEditorState(message);
      }
    } else {
      setConvertedContent(html);
      setValueCounter(valCounter.length);
    }
  });

  //Use a hidden textarea because draftjs doesn't use the simple textarea
  return (
    <div className="card">
      <CardHeader title={title} />
      <div className="card-body">
        <form className="form-type-material" id={name}>
          <textarea
            disabled={true}
            style={{ display: 'none' }}
            name={`text_${name}`}
            id={`text_${name}`}
            key={`text_${name}`}
            data-min-height="150"
            value={convertedContent}
          />
        </form>
        {convertedContent && (
          <div>
            <div className="rt-container">
              <Editor
                editorState={editorState}
                onEditorStateChange={setEditorState}
                wrapperClassName={`w_${name}`}
                editorClassName={`e_${name}`}
                toolbarClassName={`t_${name}`}
                toolbar={{
                  options: [
                    'inline',
                    'blockType',
                    'fontSize',
                    'fontFamily',
                    'list',
                    'textAlign',
                    'colorPicker',
                    'link',
                    'image',
                    'remove',
                    'history'
                  ],
                  blockType: {
                    inDropdown: true,
                    options: [
                      'Normal',
                      'H1',
                      'H2',
                      'H3',
                      'H4',
                      'H5',
                      'H6',
                      'Blockquote'
                    ]
                  },
                  link: {
                    showOpenOptionOnHover: true,
                    defaultTargetOption: '_blank'
                  }
                }}
              />
            </div>
            {/* 4000 characters is a aprox max value estimated */}
            <div className="rt-counter">
              <p>{valueCounter} / 4000 characters.</p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default PortalMessage;
