import React, {
  useContext,
  useCallback,
  useState,
  useEffect,
  useMemo
} from 'react';
import { useTranslation } from 'react-i18next';
import CurrencyFormat from 'react-currency-format';
import useAxios from 'axios-hooks';
import moment from 'moment';
import { css } from '@emotion/core';

import Modal from '../Modal/Modal';
import CardHeader from '../CardHeader/index';
import { StudentStateContext } from '../../context/StudentContext';
import { formatDateToAPI } from '../../utils/common';
import Preloader from '../Preloader/index';
import { useForm, Controller } from 'react-hook-form';

// Services
// eslint-disable-next-line import/named
import { AlertService } from '../../services/AlertService';
// eslint-disable-next-line import/named
import { UserService } from '../../services/UserService';

const shipItems = [
  {
    value: 'OI',
    label: 'ENROLLMENTCODES_STATUS_CODES_OI_NAME'
  },
  {
    value: 'DF',
    label: 'ENROLLMENTCODES_STATUS_CODES_DF_NAME'
  }
];

const AddShipModal = ({ display, setDisplay, segment, onCreated }) => {
  const { t } = useTranslation();
  const [student] = useContext(StudentStateContext);

  const [manualBilling, setManualBilling] = useState(false);
  const [sendEmail, setSendEmail] = useState(true);

  const [{ response, error, loading }, executePost] = useAxios(
    {
      url: `${process.env.REACT_APP_BASE_URL}api/v1/submissions/enrollments`,
      method: 'POST',
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('jwt')}`
      }
    },
    {
      manual: true
    }
  );

  const { register, handleSubmit, errors, control } = useForm({
    defaultValues: {
      startDate: moment(segment.start_date).format('MMDDYYYY'),
      endDate: moment(segment.end_date).format('MMDDYYYY'),
      status: 'OI',
      manualBilling: false,
      email: true
    }
  });

  const onSubmit = (data) => {
    const params = {
      studentId: student.id,
      segmentId: segment.id,
      coverageStartDate: formatDateToAPI(data.startDate.replaceAll('/', '')),
      coverageEndDate: formatDateToAPI(data.endDate.replaceAll('/', '')),
      manualBilling: data.manualBilling ? 1 : 0,
      sendEmail: sendEmail ? 1 : 0,
      note: data.note,
      status: data.status
    };

    executePost({ params: params });
  };

  useEffect(() => {
    if (response && !error) {
      onCreated();
    }
    if (error) {
      AlertService.showError(
        Array.isArray(error.response.data.message)
          ? error.response.data.message[0]
          : error.response.data.message
      );
    }
  }, [response, error, onCreated]);

  const handleEmailSend = () => {
    setSendEmail((v) => !v);
  };

  return (
    <Modal
      width="w-450px"
      targetId="add-ship-modal"
      display={display}
      setDisplay={setDisplay}
    >
      <CardHeader
        title={t('ADD_SHIP_APPLICATION')}
        rightComponent={
          // eslint-disable-next-line react/button-has-type
          <button
            className="btn border-0 p-0"
            onClick={() => setDisplay(false)}
          >
            <i className="fa fa-close" />
          </button>
        }
      />
      <div className="card-body">
        {loading && <Preloader />}
        <p className="fw-700 text-right mb-0">{segment.label}</p>
        <p className="text-right">
          {moment(segment.start_date).format('MM/DD/YYYY')} {t('TO')}{' '}
          {moment(segment.end_date).format('MM/DD/YYYY')}
        </p>
        <form
          onSubmit={handleSubmit(onSubmit)}
          role="form"
          css={css`
            label.require::after,
            label.required::after {
              //content: '*';
              color: #f96868;
              font-weight: 800;
              margin-left: 8px;
              font-size: 18px;
            }
          `}
        >
          <div className="row">
            <div className="col-5">
              <p className="fw-700 mb-0">{t('COVERAGE_DATES')}</p>
              <label className="fs-13 fw-500 mb-0 require">
                {t('START_DATE')}
              </label>
              <div className="form-group input-group mb-2">
                <Controller
                  rules={{ required: true }}
                  as={
                    <CurrencyFormat
                      value={moment(segment.start_date).format('MMDDYYYY')}
                      className="form-control"
                      format="##/##/####"
                      placeholder="MM/DD/YYYY"
                      mask={['M', 'M', 'D', 'D', 'Y', 'Y', 'Y', 'Y']}
                      pattern="^(0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])[- /.](19|20)\d\d$"
                    />
                  }
                  name="startDate"
                  control={control}
                />
                <div className="input-group-append">
                  <span className="input-group-text" id="basic-addon2">
                    <i className="ti-calendar" />
                  </span>
                </div>
                {errors.startDate && (
                  <div style={{ color: 'red' }}>
                    Fill out the startDate field.
                  </div>
                )}
              </div>
              <label className="fs-13 fw-500 mb-0 require">
                {t('END_DATE')}
              </label>
              <div className="form-group input-group mb-2">
                <Controller
                  rules={{ required: true }}
                  as={
                    <CurrencyFormat
                      value={moment(segment.end_date).format('MMDDYYYY')}
                      className="form-control"
                      format="##/##/####"
                      placeholder="MM/DD/YYYY"
                      mask={['M', 'M', 'D', 'D', 'Y', 'Y', 'Y', 'Y']}
                      pattern="^(0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])[- /.](19|20)\d\d$"
                    />
                  }
                  name="endDate"
                  control={control}
                />
                <div className="input-group-append">
                  <span className="input-group-text" id="basic-addon2">
                    <i className="ti-calendar" />
                  </span>
                </div>
                {errors.endDate && (
                  <div style={{ color: 'red' }}>
                    Fill out the startDate field.
                  </div>
                )}
              </div>
              {UserService.hasPermission(
                'students:submissions:addShipEnrollment'
              ) && (
                <div className="form-type-material">
                  <label className="fs-13 fw-500 mb-0 require">
                    {t('STATUS')}
                  </label>
                  <select
                    name="status"
                    id="status"
                    data-provide="selectpicker"
                    className="form-control"
                    ref={register({ required: true })}
                  >
                    <option disabled value="">
                      {t('SELECT')}
                    </option>
                    {shipItems.map((item) => (
                      <option value={item.value} key={item.value}>
                        {t(item.label)}
                      </option>
                    ))}
                  </select>
                </div>
              )}
            </div>
            <div className="col-7 form-type-material">
              <div className="mb-2">
                <label className="switch">
                  <input
                    name="manualBilling"
                    type="checkbox"
                    checked={manualBilling}
                    onChange={() => setManualBilling((v) => !v)}
                    ref={register}
                  />
                  <span className="switch-indicator" />
                  <span className="switch-description">
                    {t('MANUAL_BILLING')}
                  </span>
                </label>
              </div>
              <div className="mb-2">
                <label className="switch">
                  <input
                    name="email"
                    type="checkbox"
                    checked={sendEmail}
                    onChange={() => handleEmailSend()}
                    ref={register}
                  />
                  <span className="switch-indicator" />
                  <span className="switch-description">{t('EMAIL')}</span>
                </label>
              </div>
              <div
                className="form-group do-float flex-grow-1"
                css={css`
                  .text-area-max {
                    height: 167px;
                  }
                  .label-floated {
                    font-weight: 500 !important;
                    font-size: 13px !important;
                  }
                `}
              >
                <label className="fs-13 fw-500 label-floated require">
                  {t('NOTES')}
                </label>
                <textarea
                  name="note"
                  id="note"
                  className={`form-control ${
                    UserService.isAdmin() ? 'text-area-max' : 'h-100'
                  }`}
                  ref={register({ required: true })}
                />
                {errors.note && (
                  <div style={{ color: 'red' }}>Fill out the notes field.</div>
                )}
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-between">
            <div className="d-flex">
              <label
                css={css`
                  color: #f96868;
                  font-weight: 800;
                  margin-left: 8px;
                  font-size: 18px;
                `}
              >
                *
              </label>
              <label className="ml-1">{t('INDICATES_REQUIRED_FIELD')}</label>
            </div>

            <button
              type="submit"
              className="btn btn-bold d-block btn-primary btn-label"
            >
              <span>
                <i className="ti-check" />
              </span>
              {t('CREATE')}
            </button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default AddShipModal;
