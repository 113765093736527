import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { useLocation, useHistory, Link } from 'react-router-dom';
import useAxios from 'axios-hooks';

// Components
import PreAuth from '../components/PreAuth';
import Preloader from '../components/Preloader';

// Utils
import { isEmail } from '../utils/validations';

const Login = () => {
  const queryParams = new URLSearchParams(useLocation().search);
  const [load, setLoad] = useState(false);
  const [displayLoginError, setDisplayLoginError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const { t } = useTranslation();
  const history = useHistory();
  const { register, handleSubmit, formState } = useForm({ mode: 'onChange' });

  const [{ data, loading, error, response }, executePost] = useAxios(
    {
      url: `${process.env.REACT_APP_BASE_URL}api/v1/auth/login`,
      method: 'POST'
    },
    {
      manual: true
    }
  );

  useEffect(() => {
    if (loading) {
      setLoad(loading);
    }
  }, [loading]);

  useEffect(() => {
    const checkResponse = async () => {
      if (
        error &&
        error.response &&
        (error.response.status === 401 || error.response.status === 403)
      ) {
        setErrorMessage(t('ERROR_LOGIN'));
        setDisplayLoginError(true);
        setLoad(false);
      } else if (error) {
        setErrorMessage(t('ERROR_SOMETHING_WENT_WRONG'));
        setDisplayLoginError(true);
        setLoad(false);
      }

      if (!loading && !error && response) {
        const jwt = response.headers['x-sis-token'];
        axios.defaults.headers.Authorization = `Bearer ${jwt}`;
        const me = await axios.get(
          `${process.env.REACT_APP_BASE_URL}api/v1/auth/me`
        );
        window.localStorage.setItem('jwt', jwt);
        window.localStorage.setItem('me', JSON.stringify(me.data));
        window.localStorage.setItem('loggedIn', '1');
        if (queryParams.get('from')) {
          history.push(queryParams.get('from'));
        } else {
          history.push('/');
        }
      }
    };
    checkResponse();
  }, [loading, error, response, history, queryParams, data, t]);

  const handleLogin = (data) => {
    if (isEmail(data.username)) {
      const emailAddress = data.username;
      const password = data.password;
      executePost({
        params: {
          emailAddress,
          password
        }
      });
    } else {
      setErrorMessage(t('ERROR_LOGIN'));
      setDisplayLoginError(true);
      setLoad(false);
    }
  };

  return (
    <PreAuth>
      {displayLoginError && (
        <div
          className="callout callout-danger mb-4 w-350px mx-auto"
          role="alert"
        >
          <p>{errorMessage}</p>
        </div>
      )}
      {load && <Preloader />}
      <div
        className="card card-shadowed w-350px mx-auto"
        style={{ maxWidth: '100%' }}
      >
        <div className="card-header">
          <h5 className="card-title fw-500">{t('ACCOUNT_LOGIN')}</h5>
        </div>
        <div className="card-body">
          <form
            onSubmit={handleSubmit(handleLogin)}
            className="form-type-material"
          >
            <div className="form-group do-float">
              <input
                type="text"
                className="form-control"
                id="username"
                name="username"
                ref={register({ required: 'Username is required' })}
              />
              <label className="label-floated" htmlFor="username">
                {t('USER_NAME')}
              </label>
            </div>

            <div className="form-group do-float">
              <input
                type="password"
                className="form-control"
                id="password"
                name="password"
                autoComplete="off"
                ref={register({
                  required: 'Password is required',
                  minLength: 8
                })}
              />
              <label className="label-floated" htmlFor="password">
                {t('PASSWORD')}
              </label>
            </div>

            <div className="row">
              <div className="col-6 d-flex align-items-end">
                <Link
                  className="text-muted hover-primary fs-11"
                  to="/reset-password"
                >
                  <u>{t('FORGOT_PASSWORD')}</u>
                </Link>
              </div>
              <div className="col-6 text-right">
                <button
                  disabled={!formState.isValid}
                  className={`btn btn-bold btn-label btn-primary`}
                  type="submit"
                >
                  <span>
                    <i className="ti-check"></i>
                  </span>
                  {t('SUBMIT')}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </PreAuth>
  );
};

export default Login;
