import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/core';
import 'react-datepicker/dist/react-datepicker.css';
import { AlertService } from '../../services/AlertService';

// Components
import CardHeader from '../CardHeader';
import { useAuthorizedAxios } from '../../hooks/use-authorizedaxios';

const PreviewCard = ({ schoolId, exportStatuses, setExportStatuses }) => {
  const { t } = useTranslation();

  const [
    { response: updateData, error, loading: updateLoading },
    updateStatus
  ] = useAuthorizedAxios({
    url: `${process.env.REACT_APP_BASE_URL}api/v2/schools/exports/${schoolId}/status`,
    method: 'POST',
    manual: true
  });

  const changePreview = (e) => {
    e.preventDefault();

    updateStatus({
      data: {
        export_active: exportStatuses.service,
        preview_mode: exportStatuses.preview
      }
    });
  };

  useEffect(() => {
    if (updateData && !error && !updateLoading) {
      AlertService.showSuccess(t('SAVE_SUCCESS'));
    } else if (updateData && !updateLoading) {
      AlertService.showError(t('ERROR_SOMETHING_WENT_WRONG'));
    }
  }, [updateData, error, updateLoading]);

  return (
    <div className="card card-shadowed mx-auto" style={{ maxWidth: '100%' }}>
      <CardHeader title={t('PREVIEW_MODE')} />
      <div
        css={css`
          border: solid 1px rgba(151, 151, 151, 0.25);
        `}
        className="card-body"
      >
        <div
          css={css`
            .bg-dark-purple {
              background-color: #926bde;
            }
            .bg-dark-grey {
              background-color: #939eaf;
            }
          `}
          className="d-block my-3"
        >
          <div className="row">
            <div
              className="col"
              css={css`
                display: flex;
                flex-direction: row;
                justify-content: center;
              `}
            >
              <label className="switch">
                <input
                  type="checkbox"
                  checked={exportStatuses.preview}
                  name="enforceUserSessionTimeout"
                  id="enforceUserSessionTimeout"
                  onChange={() =>
                    setExportStatuses((prev) => ({
                      ...prev,
                      preview: !prev.preview
                    }))
                  }
                />
                <span className="switch-indicator" />
              </label>
              <span className="ml-10">
                {exportStatuses.preview ? t('ENABLED') : t('DISABLED')}
              </span>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <button
                onClick={changePreview}
                className="mt-20 btn btn-bold d-block ml-auto btn-primary btn-label"
              >
                <span>
                  <i className="ti-check" />
                </span>
                {t('UPDATE')}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PreviewCard;
