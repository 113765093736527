export const getStudentsQueryParams = (
  selectedSchoolId,
  selectEnrollmentId,
  studentId
) => {
  // Ensure the basic requirements are met
  if (
    !selectEnrollmentId ||
    !selectedSchoolId ||
    (studentId && studentId.length < 4)
  )
    return;

  // Build the base parameters
  const params = {
    schoolId: selectedSchoolId,
    segmentId: selectEnrollmentId
  };

  // If studentId is valid (i.e., exists and is at least 4 characters), include it
  if (studentId && studentId.length >= 4) {
    params.studentId = studentId;
  }

  return { params };
};
