import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/core';
import { AlertService } from '../../services/AlertService';
import CardHeader from '../CardHeader';
import { useAuthorizedAxios } from '../../hooks/use-authorizedaxios';

const ExportService = ({ schoolId, exportStatuses, setExportStatuses }) => {
  const { t } = useTranslation();

  const [{ response, error, loading }, updateStatus] = useAuthorizedAxios({
    url: `${process.env.REACT_APP_BASE_URL}api/v2/schools/exports/${schoolId}/status`,
    method: 'POST',
    manual: true
  });

  const submitDataExchange = (e) => {
    e.preventDefault();

    updateStatus({
      data: {
        export_active: exportStatuses.service,
        preview_mode: exportStatuses.preview
      }
    });
  };

  useEffect(() => {
    if (response) {
      AlertService.showSuccess(t('SAVE_SUCCESS'));
    }

    if (error) {
      AlertService.showError(t('ERROR_SOMETHING_WENT_WRONG'));
    }
  }, [response, error]);

  const handleDataChange = () => {
    setExportStatuses((prev) => ({
      ...prev,
      service: !prev.service
    }));
  };

  return (
    <div className="card card-shadowed mx-auto" style={{ maxWidth: '100%' }}>
      <CardHeader title={t('EXPORT_SERVICES')} />

      <div
        css={css`
          border: solid 1px rgba(151, 151, 151, 0.25);
        `}
        className="card-body"
      >
        <div
          css={css`
            .bg-dark-purple {
              background-color: #926bde;
            }
            .bg-dark-grey {
              background-color: #939eaf;
            }
          `}
          className="d-flex my-3"
        >
          <form
            onSubmit={submitDataExchange}
            className="form-type-material"
            css={css`
              width: 100%;
            `}
            disabled={loading}
          >
            <div className="row">
              <div
                className="col"
                css={css`
                  display: flex;
                  flex-direction: row;
                  justify-content: center;
                `}
              >
                <label className="switch">
                  <input
                    type="checkbox"
                    checked={exportStatuses.service}
                    name="enforceUserSessionTimeout"
                    id="enforceUserSessionTimeout"
                    onChange={handleDataChange}
                  />
                  <span className="switch-indicator" />
                </label>

                <span className="ml-10">
                  {exportStatuses.service ? t('ENABLED') : t('DISABLED')}
                </span>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <button
                  disabled={false}
                  className="mt-20 btn btn-bold d-block ml-auto btn-primary btn-label"
                  type="submit"
                >
                  <span>
                    <i className="ti-check" />
                  </span>
                  {t('UPDATE')}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ExportService;
