import { gql } from '@apollo/client';

// Queries
export const suggested_data = gql`
  query suggested_data($where: students_submissions_bool_exp) {
    students_submissions(where: $where, order_by: { student_id: asc }) {
      provider_name
      student {
        student_id
        first_name
        last_name
      }
    }
  }
`;
