import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuthorizedAxios } from '../../hooks/use-authorizedaxios';
// Components
import Modal from '../Modal/Modal';

// Services
import { UserService } from '../../services/UserService';
import SchoolFilter from './SchoolFilter';

import AcademicYearFilter from './Filters/AcademicYearFilter';
import EnrollmentPeriodFilter from './Filters/EnrollmentPeriodFilter';
import TypeFilter from './Filters/TypeFilter';
import { AlertService } from '../../services/AlertService';

const DataDumpReportContent = () => {
  const { t } = useTranslation();
  const [openNotificationModal, setOpenNotificationModal] = useState(false);
  const [selectedSchoolId, setSelectedSchoolId] = useState(
    UserService.getUser()?.school_id || ''
  );
  const [selectedYear, setSelectedYear] = useState('');
  const [selectedType, setSelectedType] = useState('');
  const [selectEnrollment, setSelectEnrollment] = useState('');

  const [{ response, error }, executeExportPost] = useAuthorizedAxios({
    manual: true,
    url: `${process.env.REACT_APP_BASE_URL}api/v1/reports/students-data-dump/exports`,
    method: 'POST'
  });

  const handleExport = async () => {
    executeExportPost({
      data: {
        filters: [
          {
            schoolId: selectedSchoolId,
            segments: [selectEnrollment],
            types: [selectedType.toUpperCase()]
          }
        ]
      }
    });
  };

  useEffect(() => {
    if (response) {
      setOpenNotificationModal(true);
    }

    if (error) {
      AlertService.showError(t('ERROR_SOMETHING_WENT_WRONG'));
    }
  }, [response, error]);

  return (
    <div className="container">
      <Modal
        targetId="delete-modal"
        display={openNotificationModal}
        setDisplay={setOpenNotificationModal}
      >
        <header className="card-header">
          <h5 className="card-title fw-500">Request Processing</h5>
          <button
            className="btn border-0"
            onClick={() => setOpenNotificationModal(!openNotificationModal)}
          >
            <i className="fa fa-close"></i>
          </button>
        </header>
        <div className="card-body">
          <p className="text-center">
            The report may take several minutes to be generated, we will send it
            to the registered email when it’s ready.
          </p>
          <div className="d-flex">
            <button
              onClick={() => setOpenNotificationModal(!openNotificationModal)}
              className="btn btn-bold d-block ml-auto btn-danger btn-label"
            >
              <span>
                <i className="ti-close"></i>
              </span>
              {t('CLOSE')}
            </button>
          </div>
        </div>
      </Modal>

      <div className="row form-type-material">
        {!UserService.getUser().school_id && (
          <div className="col-3">
            <SchoolFilter
              selectedSchoolId={selectedSchoolId}
              setSelectedSchoolId={setSelectedSchoolId}
            />
          </div>
        )}

        <div className="col-2">
          <AcademicYearFilter
            selectedYear={selectedYear}
            setSelectedYear={setSelectedYear}
            selectedSchoolId={selectedSchoolId}
          />
        </div>

        <div className="col-2">
          <TypeFilter setValue={setSelectedType} value={selectedType} />
        </div>

        <div className="col-3">
          <EnrollmentPeriodFilter
            selectedSchoolId={selectedSchoolId}
            selectedYear={selectedYear}
            selectEnrollment={selectEnrollment}
            setSelectEnrollment={setSelectEnrollment}
          />
        </div>

        <div className="col-2">
          <button
            disabled={
              selectedType == '' ||
              selectedYear == '' ||
              selectedSchoolId == '' ||
              selectEnrollment == ''
            }
            onClick={handleExport}
            className="mt-10 btn btn-bold d-block ml-auto btn-primary btn-label"
            type="submit"
            style={{ fontSize: '10px' }}
          >
            <span>
              <i className="ti-download"></i>
            </span>
            {t('SEND_REPORT')}
          </button>
        </div>
      </div>
    </div>
  );
};

export default DataDumpReportContent;
