/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';
import { css } from '@emotion/core';
import { useAuthorizedAxios } from '../../../hooks/use-authorizedaxios';

const EnrollmentPeriodFilter = ({
  selectedYear,
  selectedSchoolId,
  selectEnrollment,
  setSelectEnrollment,
  enableEmptyState
}) => {
  const [selectOptions, setSelectOptions] = useState([]);
  const [count, setCount] = useState(0);

  const [{ data: periodsData, loading: loadingPeriods }, getSchoolPeriods] =
    useAuthorizedAxios({
      manual: true,
      url: `${process.env.REACT_APP_BASE_URL}api/v2/schools/${selectedSchoolId}/segments`,
      method: 'GET'
    });

  useEffect(() => {
    if (selectedSchoolId) {
      if (selectedYear) {
        getSchoolPeriods({
          params: {
            'filter[enrollment_period_id]': selectedYear
          }
        });
      } else {
        getSchoolPeriods();
      }
    }
  }, [selectedSchoolId, selectedYear]);

  useEffect(() => {
    if (periodsData) {
      const periodOptions = [];

      periodsData.forEach((period) =>
        periodOptions.push({ value: period.id, label: period.label })
      );
      setSelectOptions(periodOptions);

      setCount((c) => c + 1);
    }
  }, [periodsData]);

  return (
    <div
      className="form-group do-float"
      key={count}
      css={css`
        .form-type-material .bootstrap-select .dropdown-toggle:focus {
          background-color: transparent !important;
        }
      `}
    >
      <select
        type="text"
        name="periodId"
        id="perioId"
        className="form-control bg-transparent"
        data-provide="selectpicker"
        onChange={(e) => setSelectEnrollment(e.target.value)}
        value={selectEnrollment}
        disabled={loadingPeriods}
      >
        <option value="" disabled={enableEmptyState ? false : true}>
          Nothing selected
        </option>

        {selectOptions.map((period) => (
          <option
            key={period.value}
            value={period.value}
            className={period.value ? '' : 'd-none'}
          >
            {period.label}
          </option>
        ))}
      </select>
      <label htmlFor="periodId" className="label-floated">
        Enrollment Period
      </label>
    </div>
  );
};

export default EnrollmentPeriodFilter;
