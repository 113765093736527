import { AUTH_ONLY } from '../types';
import axios from 'axios';
import userHasAccess from './userHasAccess';
import { UserService } from '../../services/UserService';

const requireLogin = (to, from, next) => {
  axios
    .get(`${process.env.REACT_APP_BASE_URL}api/v1/auth/me`, {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('jwt')}`
      }
    })
    .then((res) => {
      const loggedIn = localStorage.getItem('loggedIn') === '1';
      window.localStorage.setItem('me', JSON.stringify(res.data));

      UserService.setUser(res.data);

      const canAccess = userHasAccess(res.data, to.match.path);

      if (!to.meta[AUTH_ONLY]) {
        next();
      } else {
        if (!canAccess) {
          next.redirect(`/`);
        } else if (to.meta[AUTH_ONLY] && !loggedIn) {
          next.redirect(`/login?from=${to.match.path}`);
        }
        next();
      }
    })
    .catch((err) => {
      if (to.meta[AUTH_ONLY]) {
        next.redirect(`/login?from=${to.match.path}`);
      } else {
        next();
      }
    });
  return null;
};

export default requireLogin;
