import React, { useState, useEffect, useContext, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from '../Modal/Modal';
import useAxios from 'axios-hooks';
import { css } from '@emotion/core';
import { StudentStateContext } from '../../context/StudentContext';
import Preloader from '../Preloader/index';
import Checkbox from '../Checkbox/index';

/**
 * !NOTE: The themes checkboxes do something on the onChange event that makes it not trigger
 * !      But react will complain if we don't use the onChange method, so we have onChange and onInput on
 * !      each checkbox.
 * !NOTE: The fields is needed to be handled in one state by each field, remember work it in the load useEffect and in the submit
 * */

const LockFieldsModal = ({
  display,
  setDisplay,
  studentId,
  fields,
  onClose
}) => {
  const { t } = useTranslation();
  const [formDirty, setFormDirty] = useState(false);
  const [firstName, setFirstName] = useState(false);
  const [lastName, setLastName] = useState(false);
  const [alternateId, setAlternateId] = useState(false);
  //const [studentLevel, setStudentLevel] = useState(false);
  const [classification, setClassification] = useState(false);
  const [emailAddress, setEmailAddress] = useState(false);
  const [phone, setPhone] = useState(false);
  const [gender, setGender] = useState(false);
  const [dateOfBirth, setDateOfBirth] = useState(false);
  //const [studentType, setStudentType] = useState(false);
  const [streetAddress1, setStreetAddress1] = useState(false);
  const [streetAddress2, setStreetAddress2] = useState(false);
  const [city, setCity] = useState(false);
  const [state, setState] = useState(false);
  const [postalCode, setPostalCode] = useState(false);

  const [student, setStudent] = useContext(StudentStateContext);
  const [{ loading, error }, executePost] = useAxios(
    {
      url: `${process.env.REACT_APP_BASE_URL}api/v1/students/${studentId}/locks`,
      method: 'POST',
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('jwt')}`
      }
    },
    {
      manual: true
    }
  );

  useEffect(() => {
    if (fields) {
      if (fields.includes('firstName')) {
        setFirstName(true);
      }
      if (fields.includes('lastName')) {
        setLastName(true);
      }
      if (fields.includes('alternateId')) {
        setAlternateId(true);
      }
      /*if (fields.includes('studentLevel')) {
        setStudentLevel(true)
      }*/
      if (fields.includes('classification')) {
        setClassification(true);
      }
      if (fields.includes('emailAddress')) {
        setEmailAddress(true);
      }
      if (fields.includes('phone')) {
        setPhone(true);
      }
      if (fields.includes('gender')) {
        setGender(true);
      }
      if (fields.includes('dateOfBirth')) {
        setDateOfBirth(true);
      }
      /*if (fields.includes('studentType')) {
        setStudentType(true)
      }*/
      if (fields.includes('streetAddress1')) {
        setStreetAddress1(true);
      }
      if (fields.includes('streetAddress2')) {
        setStreetAddress2(true);
      }
      if (fields.includes('city')) {
        setCity(true);
      }
      if (fields.includes('state')) {
        setState(true);
      }
      if (fields.includes('postalCode')) {
        setPostalCode(true);
      }
    }
  }, [fields]);

  const handleCheckbox = useCallback(
    (name) => {
      if (!formDirty) {
        setFormDirty(true);
      }
      setTimeout(() => {
        if (name === 'lockFirstName') {
          setFirstName((f) => !f);
        }
        if (name === 'lockLastName') {
          setLastName((l) => !l);
        }
        if (name === 'lockAlternateId') {
          setAlternateId((a) => !a);
        }
        /*if (name === 'lockStudentLevel') {
        setStudentLevel(s => !s)
      }*/
        if (name === 'lockClassification') {
          setClassification((c) => !c);
        }
        if (name === 'lockEmailAddress') {
          setEmailAddress((e) => !e);
        }
        if (name === 'lockPhone') {
          setPhone((p) => !p);
        }
        if (name === 'lockGender') {
          setGender((g) => !g);
        }
        if (name === 'lockDateOfBirth') {
          setDateOfBirth((d) => !d);
        }
        /*if (name === 'lockStudentType') {
        setStudentType(s => !s)
      }*/
        if (name === 'lockStreetAddress1') {
          setStreetAddress1((s) => !s);
        }
        if (name === 'lockStreetAddress2') {
          setStreetAddress2((s) => !s);
        }
        if (name === 'lockCity') {
          setCity((c) => !c);
        }
        if (name === 'lockState') {
          setState((s) => !s);
        }
        if (name === 'lockPostalCode') {
          setPostalCode((p) => !p);
        }
      }, 100);
    },
    [formDirty]
  );

  const handleLockSubmit = async (e) => {
    e.preventDefault();
    const locks = [];
    if (firstName) {
      locks.push('firstName');
    }
    if (lastName) {
      locks.push('lastName');
    }
    if (alternateId) {
      locks.push('alternateId');
    }
    /*if (studentLevel) {
      locks.push('studentLevel')
    }*/
    if (classification) {
      locks.push('classification');
    }
    if (emailAddress) {
      locks.push('emailAddress');
    }
    if (phone) {
      locks.push('phone');
    }
    if (gender) {
      locks.push('gender');
    }
    if (dateOfBirth) {
      locks.push('dateOfBirth');
    }
    /*if (studentType) {
      locks.push('studentType')
    }*/
    if (streetAddress1) {
      locks.push('streetAddress1');
    }
    if (streetAddress2) {
      locks.push('streetAddress2');
    }
    if (city) {
      locks.push('city');
    }
    if (state) {
      locks.push('state');
    }
    if (postalCode) {
      locks.push('postalCode');
    }

    const response = await executePost({
      params: {
        locks: JSON.stringify(locks)
      }
    });

    if (!loading && !error) {
      setStudent({ ...student, ...response.data });
      onClose(locks);
      setDisplay(false);
    }
  };

  return (
    <Modal
      inputCSS={css`
        .w-500px {
          width: 500px;
        }
      `}
      width="w-500px"
      targetId="lock-fields-modal"
      display={display}
      setDisplay={setDisplay}
    >
      <div className="card-header py-1">
        <h4 className="card-title">
          <strong>{t('LOCK_FIELDS').split(' ')[0]}</strong>{' '}
          {t('LOCK_FIELDS').split(' ')[1]}
        </h4>
        <i
          role="button"
          onClick={() => setDisplay(false)}
          className="btn border-0 fa fa-close"
        ></i>
      </div>
      {loading && <Preloader />}
      <div className="card-body">
        <form className="row" onSubmit={handleLockSubmit}>
          <div className="col-md-4">
            <div className="custom-controls-stacked">
              <Checkbox
                checked={firstName}
                onCheck={() => handleCheckbox('lockFirstName')}
                label={t('FIRST_NAME')}
                labelStyle="fw-300"
              />

              <Checkbox
                checked={lastName}
                onCheck={() => handleCheckbox('lockLastName')}
                label={t('LAST_NAME')}
                labelStyle="fw-300"
              />

              <Checkbox
                checked={alternateId}
                onCheck={() => handleCheckbox('lockAlternateId')}
                label={t('ALTERNATE_ID')}
                labelStyle="fw-300"
              />

              {/*<Checkbox
                checked={studentLevel}
                onCheck={() => handleCheckbox('lockStudentLevel')}
                label={t('LEVEL')}
                labelStyle="fw-300"
                />*/}

              <Checkbox
                checked={classification}
                onCheck={() => handleCheckbox('lockClassification')}
                label={t('CLASSIFICATION')}
                labelStyle="fw-300"
              />
            </div>
          </div>

          <div className="col-md-4">
            <div className="custom-controls-stacked">
              <Checkbox
                checked={emailAddress}
                onCheck={() => handleCheckbox('lockEmailAddress')}
                label={t('EMAIL_ADDRESS')}
                labelStyle="fw-300"
              />

              <Checkbox
                checked={phone}
                onCheck={() => handleCheckbox('lockPhone')}
                label={t('PHONE')}
                labelStyle="fw-300"
              />

              <Checkbox
                checked={gender}
                onCheck={() => handleCheckbox('lockGender')}
                label={t('GENDER')}
                labelStyle="fw-300"
              />

              <Checkbox
                checked={dateOfBirth}
                onCheck={() => handleCheckbox('lockDateOfBirth')}
                label={t('DATE_OF_BIRTH')}
                labelStyle="fw-300"
              />

              {/*<Checkbox
                checked={studentType}
                onCheck={() => handleCheckbox('lockStudentType')}
                label={t('TYPE')}
                labelStyle="fw-300"
              />*/}
            </div>
          </div>
          <div className="col-md-4">
            <div className="custom-controls-stacked">
              <Checkbox
                checked={streetAddress1}
                onCheck={() => handleCheckbox('lockStreetAddress1')}
                label={`${t('STREET_ADDRESS')} 1`}
                labelStyle="fw-300"
              />
              <Checkbox
                checked={streetAddress2}
                onCheck={() => handleCheckbox('lockStreetAddress2')}
                label={`${t('STREET_ADDRESS')} 2`}
                labelStyle="fw-300"
              />

              <Checkbox
                checked={city}
                onCheck={() => handleCheckbox('lockCity')}
                label={t('CITY')}
                labelStyle="fw-300"
              />

              <Checkbox
                checked={state}
                onCheck={() => handleCheckbox('lockState')}
                label={t('STATE')}
                labelStyle="fw-300"
              />

              <Checkbox
                checked={postalCode}
                onCheck={() => handleCheckbox('lockPostalCode')}
                label={t('ZIP_CODE')}
                labelStyle="fw-300"
              />
            </div>
          </div>

          <button
            disabled={!formDirty}
            className="btn btn-bold d-block ml-auto btn-primary mt-20 mr-20 btn-label"
            type="submit"
          >
            <span>
              <i className="ti-check"></i>
            </span>
            {t('UPDATE')}
          </button>
        </form>
      </div>
    </Modal>
  );
};

export default LockFieldsModal;
