import React, { useState, useEffect } from 'react';
import {
  EditorState,
  ContentState,
  convertFromHTML,
  convertToRaw
} from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import htmlToDraft from 'html-to-draftjs';
import draftToHtmlPuri from 'draftjs-to-html';

import '../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

function SupplementalMessage({
  defaultValue,
  convertedContent,
  setConvertedContent
}) {
  //Function to convert a string into editor state
  function setPrevEditorState(previousString) {
    let html = htmlToDraft(previousString);
    return setEditorState(
      EditorState.createWithContent(ContentState.createFromBlockArray(html))
    );
  }

  const [valueCounter, setValueCounter] = useState(defaultValue?.length || 0);

  const [editorState, setEditorState] = useState(() => {
    if (defaultValue?.trim() === '<p></p>' || !defaultValue) {
      return EditorState.createEmpty(); // Handles empty cases cleanly
    }

    const contentBlocks = convertFromHTML(defaultValue);
    const contentState = ContentState.createFromBlockArray(contentBlocks);
    return EditorState.createWithContent(contentState);
  });

  useEffect((e) => {
    //Get live content of the editor state and copy in the textarea value.
    let html = draftToHtmlPuri(convertToRaw(editorState.getCurrentContent()));
    //Get the plain text to count the characters used.
    let valCounter = editorState.getCurrentContent().getPlainText('\u0001');
    //Set the previous value in the textarea (no visual change).
    if (defaultValue === '' || html === '') {
      setConvertedContent(defaultValue);
      if (defaultValue !== '') {
        setPrevEditorState(defaultValue);
      }
    } else {
      setConvertedContent(html);
      setValueCounter(valCounter.length);
    }
  });

  //Use a hidden textarea because draftjs doesn't use the simple textarea
  return (
    <>
      <textarea
        disabled={true}
        style={{ display: 'none' }}
        name={`text_supplemental-desc`}
        id={`text_supplemental-desc`}
        key={`text_supplemental-desc`}
        data-min-height="150"
        value={convertedContent}
      />

      <div className="rt-container">
        <Editor
          editorState={editorState}
          onEditorStateChange={setEditorState}
          wrapperClassName={`w_supplemental-desc`}
          editorClassName={`e_supplemental-desc`}
          toolbarClassName={`t_supplemental-desc`}
          toolbar={{
            options: [
              'inline',
              'blockType',
              'fontSize',
              'fontFamily',
              'list',
              'textAlign',
              'colorPicker',
              'link',
              'image',
              'remove',
              'history'
            ],
            blockType: {
              inDropdown: true,
              options: [
                'Normal',
                'H1',
                'H2',
                'H3',
                'H4',
                'H5',
                'H6',
                'Blockquote'
              ]
            },
            link: {
              showOpenOptionOnHover: true,
              defaultTargetOption: '_blank'
            }
          }}
        />
        {/* 4000 characters is a aprox max value estimated */}
        <div className="rt-counter">
          <p>{valueCounter} / 4000 characters.</p>
        </div>
      </div>
    </>
  );
}

export default SupplementalMessage;
