import React, { useContext, useState } from 'react';
import useAxios from 'axios-hooks';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/core';
import { StyledMain } from '../../styles/common/main';
import { SearchStateContext } from '../../context/SearchContext';

// Components
import Pagination from '../Pagination';
import AddUserCard from './AddUserCard';
import Spinner from '../Spinner';
import PermissionModal from './Permissions/PermissionModal.js';
import ManageGroupsModal from './Permissions/ManageGroupsModal';

const UsersTab = ({ schoolId: id }) => {
  const { t } = useTranslation();
  const { debouncedSearchKeyword } = useContext(SearchStateContext);
  const [selectedUser, setSelectedUser] = useState(null);
  const [showPermissionModal, setShowPermissionModal] = useState(false);
  const [manageGroupsModal, setManageGroupsModal] = useState(false);

  const [{ data, loading }, executeGet] = useAxios({
    url: `${process.env.REACT_APP_BASE_URL}api/v1/schools/${id}/users`,
    method: 'GET',
    headers: {
      Authorization: `Bearer ${window.localStorage.getItem('jwt')}`
    },
    params: {
      'filter[query]': debouncedSearchKeyword
    }
  });

  const onPageChange = (selectedPage) => {
    executeGet({
      params: {
        page: selectedPage,
        'filter[query]': debouncedSearchKeyword
      }
    });
  };

  const onAdd = () => {
    setSelectedUser(null);
    executeGet();
  };

  const handleClosePermissionModal = () => {
    setShowPermissionModal(false);
    setSelectedUser(null);
  };

  const resetUser = () => {
    setSelectedUser(null);
  };

  return (
    <StyledMain className="container row">
      <button
        css={css`
          margin-top: -12px;
        `}
        onClick={resetUser}
        className="btn btn-primary btn-float"
        disabled={selectedUser ? false : true}
        style={selectedUser ? {} : { pointerEvents: 'none' }}
      >
        <i className="ti-plus"></i>
      </button>
      <div className="col-md-8">
        <div className="card card-shadowed">
          {loading ? (
            <Spinner />
          ) : (
            <table className="table table-striped table-lg table-hover">
              <tbody>
                {data &&
                  data.data.map((user) => {
                    return (
                      <tr key={user.id} onClick={() => setSelectedUser(user)}>
                        <td>
                          <span className="fs-20">
                            {user.first_name} {user.last_name}
                          </span>
                          <div>
                            {user.group_tags.map((tag) => (
                              <span
                                className="badge badge-sm badge-primary mr-1 mb-1"
                                key={tag}
                              >
                                {tag}
                              </span>
                            ))}
                          </div>
                        </td>
                        <td>
                          <div className="media align-items-center">
                            <div className="media-body text-right">
                              <p>{user.email_address}</p>
                              <small
                                className={`ml-auto ${
                                  user.is_active
                                    ? 'text-success'
                                    : 'text-danger'
                                }`}
                              >
                                {!user.is_active && 'Not'} Verified
                              </small>
                            </div>
                            <button
                              css={css`
                                background-color: transparent;
                              `}
                              onClick={() => setShowPermissionModal(true)}
                              className="text-fadest border-0 p-0"
                            >
                              <i className="fa fa-user-plus"></i>
                            </button>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          )}
        </div>
        {data && (
          <Pagination
            pages={data.last_page}
            onPageChange={onPageChange}
            from={data.from}
            to={data.to}
            total={data.total}
          />
        )}
      </div>
      <div className="col-md-4">
        <AddUserCard
          onAdd={onAdd}
          executeGet={executeGet}
          user={selectedUser}
          schoolId={id}
        />
        <div className="d-flex">
          <button
            css={css`
              grid-column: 2/3;
            `}
            className="btn btn-bold d-block ml-auto btn-warning btn-label"
            onClick={() => setManageGroupsModal(true)}
          >
            <span>
              <i className="fa fa-users"></i>
            </span>
            {t('MANAGE_ROLES')}
          </button>
        </div>
      </div>
      {selectedUser && (
        <PermissionModal
          display={showPermissionModal}
          setDisplay={handleClosePermissionModal}
          schoolId={id}
          user={selectedUser}
          reFetch={executeGet}
        />
      )}
      <ManageGroupsModal
        display={manageGroupsModal}
        setDisplay={setManageGroupsModal}
        schoolId={id}
      />
    </StyledMain>
  );
};

export default UsersTab;
