import React, { useContext, useState, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import useAxios from 'axios-hooks';

// Context
import { SchoolStateContext } from '../../context/SchoolContext';

// Components
import Preloader from '../Preloader/index';
import PortalMessage from './PortalMessage';
import Spinner from '../Spinner/index';

// Services
// eslint-disable-next-line import/named
import { AlertService } from '../../services/AlertService';

const MessagingTab = () => {
  const { t } = useTranslation();
  const [schoolId] = useContext(SchoolStateContext);
  const [loginMessage, setLoginMessage] = useState('');
  const [closedMessage, setClosedMessage] = useState('');

  const [{ data, loading }, executeGet] = useAxios(
    {
      url: `${process.env.REACT_APP_BASE_URL}api/v1/schools/${schoolId}/settings/messaging`,
      method: 'GET',
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('jwt')}`
      }
    },
    { manual: true }
  );

  const [{ loading: postLoading, response, error: errorData }, postMessage] =
    useAxios(
      {
        url: `${process.env.REACT_APP_BASE_URL}api/v1/schools/${schoolId}/settings/messaging`,
        method: 'POST',
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem('jwt')}`
        }
      },
      { manual: true }
    );

  useEffect(() => {
    executeGet();
  }, []);

  useEffect(() => {
    if (response && !errorData && !loading) {
      AlertService.showSuccess(t('SAVE_SUCCESS'));
    } else if (errorData && !loading) {
      AlertService.showError(t('ERROR_SOMETHING_WENT_WRONG'));
    }
  }, [response, errorData, loading]);

  let textareaInitialized = false;
  useEffect(() => {
    if (!textareaInitialized && data) {
      setTimeout(() => {
        //get textarea for push the actual value.
        const login = document.getElementById('text_login-message');
        const closed = document.getElementById('text_closed-message');

        if (login && data) {
          login.innerHTML =
            data && data['LOGIN:MESSAGE'] ? data['LOGIN:MESSAGE'] : '';
          // eslint-disable-next-line react-hooks/exhaustive-deps
          setLoginMessage(data['LOGIN:MESSAGE'] ? data['LOGIN:MESSAGE'] : '');
        }
        if (closed && data) {
          closed.innerHTML =
            data && data['CLOSE:MESSAGE'] ? data['CLOSE:MESSAGE'] : '';
          // eslint-disable-next-line react-hooks/exhaustive-deps
          setClosedMessage(data['CLOSE:MESSAGE'] ? data['CLOSE:MESSAGE'] : '');
        }
        if (login && closed) {
          // eslint-disable-next-line react-hooks/exhaustive-deps
          textareaInitialized = true;
        }
      }, 1000);
    }
  }, [data]);

  const handleSubmit = useCallback(() => {
    //get text values for save with handleSubmit
    let clo = document.querySelector('#text_closed-message').textContent;
    let log = document.querySelector('#text_login-message').textContent;
    const payload = {
      closedMessage: btoa(clo),
      loginMessage: btoa(log)
    };
    postMessage({ params: payload });
  }, [loginMessage, closedMessage]);

  if (loading) {
    return <Spinner />;
  }

  return (
    <>
      {postLoading && <Preloader />}
      <PortalMessage
        message={closedMessage}
        title={t('PORTAL_CLOSED_MESSAGE')}
        name="closed-message"
      />
      <PortalMessage
        message={loginMessage}
        title={t('PORTAL_LOGIN_MESSAGE')}
        name="login-message"
      />
      <div className="d-flex justify-content-end mb-5">
        <button
          type="button"
          className="btn text-uppercase btn-block w-auto ml-auto btn-bold btn-label btn-primary"
          onClick={handleSubmit}
        >
          <span>
            <i className="ti-check" />
          </span>
          {t('UPDATE')}
        </button>
      </div>
    </>
  );
};

export default MessagingTab;
