/* eslint-disable no-restricted-syntax */
/* eslint-disable no-console */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/core';
import useAxios from 'axios-hooks';

// Components
import StatusCard from '../StatusCard';
import SummaryDetailModal from '../SummaryDetailModal/index';
// import LineChart from '../LineChart';
import Preloader from '../Preloader/index';

// Icons
// import arrowUp from '../../img/arrow-up.svg';
// import arrowDown from '../../img/arrow-down.svg';

const SchoolActivitySummary = ({ summary }) => {
  const { t } = useTranslation();
  const [option, setOption] = useState('ACTIVE');
  const [showModal, setShowModal] = useState(false);
  // const [showMore, setShowMore] = useState(false);
  // eslint-disable-next-line no-unused-vars
  //const [showGraphs, setShowGraphs] = useState(false);
  const [selected, setSelected] = useState(null);
  const [periodSelected, setPeriodSelected] = useState(null);
  // const [years, setYears] = useState(null);

  const [selectedPeriod, setSelectedPeriod] = useState(summary[0]);

  const handleSelect = (item, id) => {
    setSelected(item);
    setPeriodSelected(id);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setSelected(null);
    setPeriodSelected(null);
    setShowModal(false);
  };

  const [{ data: segmentData, loading: segmentDataLoading }, getsegmentData] =
    useAxios(
      {
        url: `${process.env.REACT_APP_BASE_URL}api/v1/dashboard/summary/${selectedPeriod.id}`,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem('jwt')}`
        }
      },
      {
        manual: true
      }
    );

  useEffect(() => {
    if (selectedPeriod?.id) {
      getsegmentData();
    }
  }, [selectedPeriod]);

  return (
    <>
      {segmentDataLoading && <Preloader />}

      {selected && (
        <SummaryDetailModal
          display={showModal}
          setDisplay={handleCloseModal}
          id={periodSelected}
          code={selected}
          active={
            !!(
              option === 'ACTIVE' ||
              option === 'ROLLOVER' ||
              option === 'TOTAL_ACTIVE'
            )
          }
          rollover={
            option === 'TOTAL_ACTIVE'
              ? null
              : !!(option === 'ROLLOVER' || option === 'ROLLOVER_INACTIVE')
          }
          option={option}
        />
      )}

      <div
        style={{
          backgroundColor: '#FFF',
          height: '3rem',
          marginLeft: 'auto'
        }}
        className="card border border-light"
      >
        <ul
          style={{
            marginTop: 'auto',
            marginBottom: 'auto',
            display: 'flex',
            justifyContent: 'center'
          }}
        >
          <span
            className="border-0 p-0 fw-500 text-black mr-2"
            css={css`
              font-family: Roboto;
            `}
          >
            Academic year:
          </span>
          <li className="dropdown d-block">
            <span
              className="btn border-0 p-0 fw-500 text-black bg-transparent"
              data-toggle="dropdown"
              css={css`
                font-family: Roboto;
              `}
            >
              <span>{t(selectedPeriod.academic_year)}</span>{' '}
              <i className="fa fa-chevron-down" />
            </span>
            <div
              className="dropdown-menu open-top-right dropdown-menu-right"
              x-placement="bottom-end"
              style={{
                position: 'absolute',
                top: '65px',
                left: '-120px',
                willChange: 'top, left'
              }}
              css={css`
                font-family: Roboto;
              `}
            >
              {summary.map((period, index) => {
                return (
                  <span
                    key={index + 'period'}
                    role="button"
                    tabIndex="0"
                    className="dropdown-item btn border-0 text-center"
                    onClick={() => setSelectedPeriod(period)}
                  >
                    {period.academic_year}
                  </span>
                );
              })}
            </div>
          </li>
        </ul>
      </div>

      <div className="card border border-light">
        <div className="card-header py-2">
          <div className="row w-100 justify-content-between">
            <div
              className="d-flex"
              css={css`
                height: fit-content;
              `}
            >
              <label
                css={css`
                  color: #38404e;
                  font-weight: 400;
                  font-family: Roboto;
                  font-size: 16px;
                  line-height: 32px;
                  margin: auto;
                `}
              >
                {
                  //summary.SCHOOL_NAME
                  summary[0].school.school_name
                }
              </label>
              <label
                css={css`
                  color: #38404e;
                  font-weight: 300;
                  font-family: Roboto;
                  font-size: 16px;
                  line-height: 32px;
                  margin: auto;
                  padding-left: 10px;
                `}
              >
                {t('ENROLLMENT_SUMMARY')}
              </label>
            </div>
            {/* <span
              role="button"
              tabIndex="0"
              className="dropdown-item btn border-0"
              css={css`
                color: #4d5259;
                font-weight: 300;
                font-family: Roboto;
                font-size: 12px;
                margin: auto 0px;
              `}
              onClick={() => {
                setShowGraphs(!showGraphs);
              }}
            >
              {t('VIEW_ACTIVITY_GRAPH')}
            </span> */}
            <ul className="m-0">
              <li className="dropdown d-block">
                <span
                  className="btn border-0 p-0 fw-400 text-black bg-transparent"
                  data-toggle="dropdown"
                  css={css`
                    font-family: Roboto;
                  `}
                >
                  <span>{t(option)}</span> <i className="fa fa-chevron-down" />
                </span>
                <div
                  className="dropdown-menu open-top-right dropdown-menu-right"
                  x-placement="bottom-end"
                  style={{
                    position: 'absolute',
                    top: '65px',
                    left: '-120px',
                    willChange: 'top, left'
                  }}
                  css={css`
                    font-family: Roboto;
                  `}
                >
                  <span
                    role="button"
                    tabIndex="0"
                    className="dropdown-item btn border-0"
                    onClick={() => setOption('ACTIVE')}
                  >
                    {t('ACTIVE')}
                  </span>
                  <span
                    role="button"
                    tabIndex="0"
                    className="dropdown-item btn border-0"
                    onClick={() => setOption('INACTIVE')}
                  >
                    {t('INACTIVE')}
                  </span>
                  <span
                    role="button"
                    tabIndex="0"
                    className="dropdown-item btn border-0"
                    onClick={() => setOption('ROLLOVER')}
                  >
                    {t('ROLLOVER')}
                  </span>
                  <span
                    role="button"
                    tabIndex="0"
                    className="dropdown-item btn border-0"
                    onClick={() => setOption('ROLLOVER_INACTIVE')}
                  >
                    {t('ROLLOVER_INACTIVE')}
                  </span>
                  <span
                    role="button"
                    tabIndex="0"
                    className="dropdown-item btn border-0"
                    onClick={() => setOption('TOTAL_ACTIVE')}
                  >
                    {t('TOTAL_ACTIVE')}
                  </span>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div
          className="card-body"
          css={css`
            padding: 0px 15px;
          `}
        >
          {segmentData &&
            segmentData.length > 0 &&
            segmentData.map((segment, i) => (
              <div key={i + 'segment'}>
                <div className="row">
                  <div className="col">
                    {
                      <div
                        className="row"
                        css={css`
                          padding-top: 15px;
                        `}
                      >
                        <label
                          css={css`
                            color: #38404e;
                            font-weight: 700;
                            font-family: Roboto;
                            font-size: 16px;
                            width: fit-content;
                            margin: auto;
                          `}
                        >
                          {segment.SEGMENT_TITLE}
                        </label>
                      </div>
                    }
                    <div
                      className="row"
                      css={css`
                        ${'padding-bottom: 15px'};
                      `}
                    >
                      {/* NO ACTION */}
                      <div className="col-md-2">
                        <label
                          css={css`
                            color: #4d5259;
                            font-weight: 500;
                            font-family: Roboto;
                            font-size: 13px;
                          `}
                        >
                          {t('NO_ACTION')}
                        </label>
                        <div className="row no-gutters">
                          <div className="mr-1 mb-1">
                            <StatusCard
                              status="NO_ACTION"
                              title={
                                segment[option] &&
                                segment[option] !== false &&
                                segment[option].NO_ACTION
                                  ? segment[option].NO_ACTION
                                  : 0
                              }
                              description={t('SUMMARY_CODE_NO_ACTION')}
                              titleStyle="fs-18 mb-0"
                              onClick={() => handleSelect('NR', segment.ID)}
                              fontFamily="Roboto"
                            />
                          </div>
                          {segment[option] &&
                            segment[option] !== false &&
                            segment[option].VOLUNTARY !== false && (
                              <div className="mr-1 mb-1">
                                <StatusCard
                                  status="VOLUNTARY"
                                  title={segment[option].VOLUNTARY || 0}
                                  description={t('SUMMARY_CODE_VOLUNTARY')}
                                  titleStyle="fs-18 mb-0"
                                  fontFamily="Roboto"
                                />
                              </div>
                            )}
                        </div>
                      </div>
                      {/* WAIVER SUMMARY */}
                      <div className="col-md-6 p-md-0">
                        <label
                          css={css`
                            color: #4d5259;
                            font-weight: 500;
                            font-family: Roboto;
                            font-size: 13px;
                          `}
                        >
                          {t('WAIVER_SUMMARY')}
                        </label>
                        <div className="row no-gutters">
                          {segment[option] && segment[option] !== false ? (
                            Object.keys(
                              segment[option]['Waiver Application']
                            ).map((key) => {
                              if (
                                segment[option]['Waiver Application'][key] !==
                                false
                              ) {
                                let code = key;
                                if (key === 'SUPPLEMENTS') {
                                  code = 'SP';
                                }
                                return (
                                  <div
                                    className="mr-1 mb-1"
                                    key={`${segment.ACADEMIC_YEAR} ${segment.SEGMENT_TITLE} WA ${key}`}
                                  >
                                    <StatusCard
                                      status={key}
                                      title={
                                        segment[option]['Waiver Application'][
                                          key
                                        ]
                                      }
                                      description={t(`SUMMARY_CODE_${key}`)}
                                      titleStyle="fs-18 mb-0"
                                      fontFamily="Roboto"
                                      onClick={
                                        code !== 'TOTAL'
                                          ? () => handleSelect(code, segment.ID)
                                          : () => handleSelect('TW', segment.ID)
                                      }
                                    />
                                  </div>
                                );
                              }
                              return null;
                            })
                          ) : (
                            <>
                              <div
                                className="mr-1 mb-1"
                                key={`${segment.ACADEMIC_YEAR} ${segment.SEGMENT_TITLE} WA PR`}
                              >
                                <StatusCard
                                  status="PR"
                                  title="0"
                                  description={t(`SUMMARY_CODE_PR`)}
                                  titleStyle="fs-18 mb-0"
                                  fontFamily="Roboto"
                                />
                              </div>
                              <div
                                className="mr-1 mb-1"
                                key={`${segment.ACADEMIC_YEAR} ${segment.SEGMENT_TITLE} WA PA`}
                              >
                                <StatusCard
                                  status="PA"
                                  title="0"
                                  description={t(`SUMMARY_CODE_PA`)}
                                  titleStyle="fs-18 mb-0"
                                  fontFamily="Roboto"
                                />
                              </div>
                              <div
                                className="mr-1 mb-1"
                                key={`${segment.ACADEMIC_YEAR} ${segment.SEGMENT_TITLE} WA PE`}
                              >
                                <StatusCard
                                  status="PE"
                                  title="0"
                                  description={t(`SUMMARY_CODE_PE`)}
                                  titleStyle="fs-18 mb-0"
                                  fontFamily="Roboto"
                                />
                              </div>
                              <div
                                className="mr-1 mb-1"
                                key={`${segment.ACADEMIC_YEAR} ${segment.SEGMENT_TITLE} WA AP`}
                              >
                                <StatusCard
                                  status="AP"
                                  title="0"
                                  description={t(`SUMMARY_CODE_AP`)}
                                  titleStyle="fs-18 mb-0"
                                  fontFamily="Roboto"
                                />
                              </div>
                              <div
                                className="mr-1 mb-1"
                                key={`${segment.ACADEMIC_YEAR} ${segment.SEGMENT_TITLE} WA DE`}
                              >
                                <StatusCard
                                  status="DE"
                                  title="0"
                                  description={t(`SUMMARY_CODE_DE`)}
                                  titleStyle="fs-18 mb-0"
                                  fontFamily="Roboto"
                                />
                              </div>
                              <div
                                className="mr-1 mb-1"
                                key={`${segment.ACADEMIC_YEAR} ${segment.SEGMENT_TITLE} WA CW`}
                              >
                                <StatusCard
                                  status="CW"
                                  title="0"
                                  description={t(`SUMMARY_CODE_CW`)}
                                  titleStyle="fs-18 mb-0"
                                  fontFamily="Roboto"
                                />
                              </div>
                              <div
                                className="mr-1 mb-1"
                                key={`${segment.ACADEMIC_YEAR} ${segment.SEGMENT_TITLE} WA TOTAL`}
                              >
                                <StatusCard
                                  status="TOTAL"
                                  title="0"
                                  description={t(`SUMMARY_CODE_TOTAL`)}
                                  titleStyle="fs-18 mb-0"
                                  fontFamily="Roboto"
                                />
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                      {/* SHIP SUMMARY */}
                      <div className="col-md-4">
                        <label
                          css={css`
                            color: #4d5259;
                            font-weight: 500;
                            font-family: Roboto;
                            font-size: 13px;
                          `}
                        >
                          {t('SHIP_SUMMARY')}
                        </label>
                        <div className="row no-gutters">
                          {segment[option] && segment[option] !== false ? (
                            Object.keys(segment[option]['SHIP Enrollment']).map(
                              (key, index) => (
                                <div
                                  className="mr-1 mb-1"
                                  key={`${segment.ACADEMIC_YEAR} ${segment.SEGMENT_TITLE} SE ${key} ${index}`}
                                >
                                  <StatusCard
                                    status={key}
                                    title={
                                      segment[option]['SHIP Enrollment'][key]
                                    }
                                    description={t(`SUMMARY_CODE_${key}`)}
                                    titleStyle="fs-18 mb-0"
                                    fontFamily="Roboto"
                                    onClick={
                                      key !== 'TOTAL'
                                        ? () =>
                                            handleSelect(
                                              key,
                                              //period.content.ID
                                              segment.ID
                                            )
                                        : () =>
                                            handleSelect(
                                              'TE',
                                              //period.content.ID
                                              segment.ID
                                            )
                                    }
                                  />
                                </div>
                              )
                            )
                          ) : (
                            <>
                              <div
                                className="mr-1 mb-1"
                                key={`${segment.ACADEMIC_YEAR} ${segment.SEGMENT_TITLE} SE OI`}
                              >
                                <StatusCard
                                  status="OI"
                                  title="0"
                                  description={t(`SUMMARY_CODE_OI`)}
                                  titleStyle="fs-18 mb-0"
                                  fontFamily="Roboto"
                                />
                              </div>
                              <div
                                className="mr-1 mb-1"
                                key={`${segment.ACADEMIC_YEAR} ${segment.SEGMENT_TITLE} SE DF`}
                              >
                                <StatusCard
                                  status="DF"
                                  title="0"
                                  description={t(`SUMMARY_CODE_DF`)}
                                  titleStyle="fs-18 mb-0"
                                  fontFamily="Roboto"
                                />
                              </div>
                              <div
                                className="mr-1 mb-1"
                                key={`${segment.ACADEMIC_YEAR} ${segment.SEGMENT_TITLE} SE CE`}
                              >
                                <StatusCard
                                  status="CE"
                                  title="0"
                                  description={t(`SUMMARY_CODE_CE`)}
                                  titleStyle="fs-18 mb-0"
                                  fontFamily="Roboto"
                                />
                              </div>
                              <div
                                className="mr-1 mb-1"
                                key={`${segment.ACADEMIC_YEAR} ${segment.SEGMENT_TITLE} SE TOTAL`}
                              >
                                <StatusCard
                                  status="TOTAL"
                                  title="0"
                                  description={t(`SUMMARY_CODE_TOTAL`)}
                                  titleStyle="fs-18 mb-0"
                                  fontFamily="Roboto"
                                />
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          {/*showGraphs && (
            <div className="row">
              <div className="col-md-6">
                <LineChart
                  year={summaryData.ACADEMIC_YEAR}
                  segment={summaryData.SEGMENT_TITLE}
                  data={[summaryData.WAIVERS, summaryData.SUPPLEMENTS]}
                  labels={['WAIVERS_SUBMITTED', 'SUPPLEMENT_PLANS']}
                  colors={[
                    {
                      border: 'rgba(51,202,185,1)',
                      background: 'rgba(51,202,185,0.5)'
                    },
                    {
                      border: 'rgba(151, 151, 151,1)',
                      background: 'rgba(151, 151, 151,0.5)'
                    }
                  ]}
                />
              </div>
              <div className="col-md-6">
                <LineChart
                  year={summaryData.ACADEMIC_YEAR}
                  segment={summaryData.SEGMENT_TITLE}
                  data={[summaryData.SHIP]}
                  labels={['ENROLLMENTS_SUBMITTED']}
                  colors={[
                    {
                      border: 'rgba(146, 107, 222,1)',
                      background: 'rgba(146, 107, 222,0.5)'
                    }
                  ]}
                />
              </div>
            </div>
          )*/}
        </div>
      </div>
    </>
  );
};

export default SchoolActivitySummary;
