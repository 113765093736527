import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/core';
import Preloader from '../Preloader/index';
import prettyBytes from 'pretty-bytes';
import Pagination from '../Pagination/index';
import { AlertService } from '../../services/AlertService';
import Modal from '../Modal/Modal';
import { useAuthorizedAxios } from '../../hooks/use-authorizedaxios';

/**
 * !NOTE: The themes checkboxes do something on the onChange event that makes it not trigger
 * !      But react will complain if we don't use the onChange method, so we have onChange and onInput on
 * !      each checkbox.
 * !NOTE: The fields is needed to be handled in one state by each field, remember work it in the load useEffect and in the submit
 * */
const ImportModal = ({ display, setDisplay, modalData, onPageChange }) => {
  const { t } = useTranslation();

  //delete single file
  const [
    { response: delResponse, error: delError, loading: delLoading },
    deleteFile
  ] = useAuthorizedAxios({
    manual: true,
    method: 'DELETE'
  });

  //delete page
  const handleDeletePage = (list) => {
    let orderList = [];
    list.forEach(function (list) {
      orderList.push(list.id);
    });
    handleDelete(orderList);
  };

  //delete request
  const handleDelete = (id) => {
    let URL =
      id.length > 1
        ? `${modalData.schoolId}/imports-files`
        : `${modalData.schoolId}/imports-files/${id[0]}`;
    deleteFile({
      url: `${process.env.REACT_APP_BASE_URL}api/v1/schools/${URL}`,
      data: { importsIds: id.length > 1 ? id : '' }
    });
    setDisplay(false);
  };

  useEffect(() => {
    if (delResponse && !delError && !delLoading) {
      AlertService.showSuccess(t('SAVE_SUCCESS'));
    } else if (delResponse && !delLoading) {
      AlertService.showError(t('ERROR_SOMETHING_WENT_WRONG'));
    }
  }, [delResponse, delError, delLoading]);

  return (
    <Modal
      inputCSS={css`
        .w-700px {
          width: 700px;
        }
      `}
      width="w-700px"
      targetId="lock-fields-modal"
      display={display}
      setDisplay={setDisplay}
    >
      <div className="card-header py-1">
        <h4 className="card-title">{modalData.period.label}</h4>
        <i
          role="button"
          onClick={() => setDisplay(false)}
          className="btn border-0 fa fa-close"
        ></i>
      </div>

      {delLoading && <Preloader />}

      <div className="card-body">
        <table
          id="dtBasicExample"
          className="table table-striped table-bordered table-sm"
          cellspacing="0"
          width="100%"
        >
          <thead>
            <tr>
              <th>{t('NAME')}</th>
              <th>{t('SIZE')}</th>
              <th>{t('DATE')}</th>
              <th>
                {modalData.status && (
                  <button
                    className="btn btn-danger btn-r-all"
                    onClick={() => handleDeletePage(modalData.status.data)}
                  >
                    <i
                      role="button"
                      className="btn border-0 fa fa-trash remove-icon"
                    ></i>
                    Remove page
                  </button>
                )}
              </th>
            </tr>
          </thead>
          <tbody>
            {modalData.status.data
              ? modalData.status.data.map((eList, index) => (
                  <tr key={index} id={`modal-${eList.id}`}>
                    <th>{eList.name}</th>
                    <th>{prettyBytes(eList.size)}</th>
                    <th>{eList.updated_at}</th>
                    <th>
                      <button
                        className="btn btn-danger btn-r-all"
                        onClick={() => handleDelete([eList.id])}
                      >
                        <i
                          role="button"
                          className="btn border-0 fa fa-trash remove-icon"
                        ></i>
                        Remove
                      </button>
                    </th>
                  </tr>
                ))
              : ''}
          </tbody>
        </table>
        {modalData.status.data && (
          <div className="mb-0">
            <Pagination
              pages={modalData.status.last_page}
              onPageChange={onPageChange}
              from={modalData.status.from}
              to={modalData.status.to}
              total={modalData.status.total}
            />
          </div>
        )}
      </div>
    </Modal>
  );
};

export default ImportModal;
